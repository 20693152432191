import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, firstValueFrom, lastValueFrom, Observable, of, switchMap } from 'rxjs';
import { map, shareReplay, startWith, take } from 'rxjs/operators';
import {
  CarrierTouchpointType,
  CompanyAdmins,
  CoverageStrategyStop,
  FileUploadType,
  LoadChargeDetails,
  LoadChargePendingUpdate,
  LoadHistoryMap,
  LoadingType,
  LohiUploadedFile,
  ReloadLoadOrLohiLoad,
  RouteGuideLaneCarrier,
  ShipperLoadStatus,
  SlimAdmin,
  SlimDriver,
  StopType,
  XYPoint,
} from '../../../shared/global-types';
import { SimpleFinn } from '../../../shared/utilities/simpleFinn';
import { RouterStateService } from '../../../shared/services/router-state.service';
import { environment } from '../../../../environments/environment';
import { CheckCallStatus } from '../shipper-load/awarded-loads/awarded-loads.service';
import { LOAD_FILE_ID, LOAD_ID } from './loads-consts';
import { ReloadableSubject } from '../../../shared/utilities/reloadableSubject';
import { RouterFinn } from '../../../shared/utilities/routerFinn';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { shareReplayComponentConfig } from '../../../shared/constants';
import { FacilityInPool } from '../dispatch-pool/dispatch-pool.service';
import { sort } from 'remeda';
import { BolExtract } from './load-details/load-details-files/load-details-files-file/load-details-files-file-bol/load-details-files-file-bol.component';
import { AccountingLoad } from '../accounting/accounting-loads.service';

export interface EditLoadStop {
  id: number;
  sequence: number;
  lng: number;
  lat: number;
  type: 'pickup' | 'dropoff';
  loadingType: LoadingType;
  title: string;
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  phone?: string;
  arrivalWindowStartsAt: string;
  arrivalWindowMinutes: number;
  arrivalWindowExtremaStartsAt?: string;
  arrivalWindowExtremaEndsAt?: string;
  originalArrivalWindowStartsAt: string;
  details?: string;
  directions?: string;
  commodity: string;
  quantity: number;
  unit: number;
  detentionFreeMinutes: number;
  referenceNumber: string;
  appointmentTrackingNumber: string;
  facilityId: string;
  expectedAt?: string;
  isCPG: boolean;
  isDrayage: boolean;
  attachEmptyTrailerPickup: boolean;
  productId: string | null;
  emptyTrailerPickupID: number | null;
}

export interface AddStopAtSequence {
  sequence: number;
  lng: number;
  lat: number;
  type: 'pickup' | 'dropoff';
  title: string;
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  phone?: string;
  arrivalWindowStartsAt: string;
  arrivalWindowMinutes: number;
  details?: string;
  directions?: string;
  commodity: string;
  quantity: number;
  unit: number;
  detentionFreeMinutes: number;
  referenceNumber: string;
  appointmentTrackingNumber: string;
  facilityId: string;
  holdingYard: string;
}

export interface EditLoadRequest {
  customerLoadNumber: string;
  shipper: string;
  temperature?: number;
  weightPounds?: number;
  rateCents: number;
  furtherDetails?: string;
  shippingComments?: string;
  cargoValueCents?: number;
  brokerEmailId: number;
  referenceNumber?: string;
  detentionRateCents: number;
  brokerNotificationMinutes: number;
  possibleTrailerTypesIds: string[];
  containerNumber?: string;
  containerNumber2?: string;
  sealNumber?: string;
  vesselNumber?: string;
  bookingNumber?: string;
  bookingNumber2?: string;
  shippingLine?: string;
  shippingLine2?: string;
  railBillingConfirmationNumber?: string;
  reservationConfirmationNumber?: string;
  useAlmChassis: boolean;
  chassisNumber?: string;
  earliestPickupAt?: string;
  lastFreePickupAt?: string;
  earliestReceiptTime?: string;
  documentationCutoffTime?: string;
  portCutoffTime?: string;
  rampCutoffTime?: string;
  commodity?: string;
  quantity?: number;
  unitId?: number;
  containerPickupLocation?: string;
  containerAppointmentStart?: string;
  containerAppointmentEnd?: string;
  pickupLocation?: string;
  pickupAppointmentStart?: string;
  pickupAppointmentEnd?: string;
  earliestPickupTime?: string;
  latestPickupTime?: string;
  dropoffLocation?: string;
  dropoffAppointmentStart?: string;
  dropoffAppointmentEnd?: string;
  isEmptyTrailerMove: boolean;
  customerSpecificDetails: Record<string, any>;
}

export interface LoadBookingHold {
  carrierName?: string;
  carrierEmail?: string;
  carrierPhone?: string;
  dotNumber?: string;
  createdAt?: string;
  createdBy?: string;
  endsAt?: string;
  reason?: string;
}

export interface PostingGuidelines {
  referenceId: string;
  trailerType?: string;
  startLocation?: PostingGuidelinesLocation;
  endLocation?: PostingGuidelinesLocation;
  weightPounds?: number;
  rateCents?: number;
  comments?: string[];
}

export interface PostingGuidelinesLocation {
  city: string;
  state: string;
}

export interface LoadHistory {
  loadHistory: LoadHistoryItem[];
}

export interface AutodispatchPool {
  name: string;
  id: number;
}

export type HistoryItemType =
  | 'hold'
  | 'load_cancellation'
  | 'load_update'
  | 'misc'
  | 'rate_con'
  | 'requested_rate'
  | 'broker_charge'
  | 'load_creation'
  | 'load_completion'
  | 'carrier_unassigned'
  | 'assigned_to_driver'
  | 'driver_reassigned'
  | 'unassigned_from_driver'
  | 'bill_driver_approval'
  | 'bill_carrier_approval'
  | 'bill_qa_approval'
  | 'brokerage_approval'
  | 'stop_completion'
  | 'qa_reject'
  | 'load_pickup_ready'
  | 'fund_disbursed'
  | 'load_disapproval'
  | 'carrier_requested_charge'
  | 'carrier_requested_charge_decision'
  | 'carrier_requested_charge_update'
  | 'carrier_requested_charge_update_decision'
  | 'stops_changed'
  | 'broker_price_approval'
  | 'carrier_price_qa'
  | 'customer_price_qa'
  | 'stop_removed'
  | 'load_ready'
  | '';

export interface LoadHistoryItem {
  historyItemType: HistoryItemType;
  rateConItem: RateConHistoryItem;
  holdItem: HoldHistoryItem;
  miscItem: MiscHistoryItem;
  loadUpdateItem: LoadUpdateHistoryItem;
  loadCancellationItem: LoadCancellationHistoryItem;
  requestedRateItem?: RequestedRateHistoryItem;
  brokerChargeUpdateItem: BrokerChargeUpdateHistoryItem;
  loadCreationCompletionStatusItem: LoadCreationCompletionStatusHistoryItem;
  loadBillingStatusItem: LoadBillingStatus;
  driverAssignStatusItem: DriverAssignStatus;
  loadStopStatusItem: LoadStopStatus;
  carrierUnassignItem: CarrierUnassignStatus;
  qaRejectStatusItem: QARejectStatus;
  loadPickUpReadinessItem: LoadPickupReadyStatus;
  loadDisapprovalItem: LoadDisapprovalHistoryItem;
  carrierRequestedChargeItem: CarrierRequestedChargeItem;
  stopsChanged: StopsChangedHistoryItem;
  priceQA: PriceQAHistoryItem;
  stopRemovedItem: StopRemovedItem;
  loadReadyEvent: LoadReadyEvent;
  sortDate: string;
}

export interface StopRemovedItem {
  type: StopType;
  sequence: number;
  archivedAt: string;
  archivedReason: string;
  updatedByName: string;
  facilityId: string;
  facilityName: string;
  facilityExternalId: string;
}

export interface LoadReadyEvent {
  createdAt: string;
  isReady: boolean;
  changedBy: string;
  changedByName: string;
  trailerAction: string;
  trailerId: number;
  trailerName: string;
  note: string;
  customerNotes: string;
}

export interface StopsChangedHistoryItem {
  actorUserName: string;
  actions: string[];
}

export interface PriceQAHistoryItem {
  actorUserName: string;
  decision: string;
  notes: string;
}

export interface LoadDisapprovalHistoryItem {
  disapprovedAt: string;
  performedBy: string;
  justification: string;
}

export interface LoadPickupReadyStatus {
  loadReadinessAt: string;
  loadReadinessMarkedBy: string;
}

export interface QARejectStatus {
  qaRejectedAt: string;
  qaRejectedBy: string;
  qaRejectedReason: string;
  documentCategory: string;
  carrierId: string;
  carrierName: string;
}

export interface CarrierUnassignStatus {
  carrierId: string;
  carrierName: string;
  unassignedAt: string;
  unassignedBy: string;
}

export interface LoadStopStatus {
  stopType: string;
  facilityName: string;
  completedAt: Date;
  completedById: string;
  completedByName: string;
  driverId: string;
  driverName: string;
}

export interface CarrierRequestedChargeItem {
  carrierChargeRequestCreatedAt: string;
  chargeType: string;
  chargePerUnitCents: number;
  requesterName: string;
  decisionMadeAt: string;
  decisionMakerName: string;
  chargeApproveStatus: string;
  decisionSupportingText: string;
  internalNotes: string;
}

export interface DriverAssignStatus {
  reason: string;
  eventType: string;
  createdAt: Date;
  driverID: string;
  driverName: string;
  actorUserID: string;
  actorName: string;
}

export interface LoadBillingStatus {
  driverApprovedAt?: string;
  driverApprovedBy?: string;
  carrierApprovedAt?: string;
  carrierApprovedBy?: string;
  qaApprovedAt?: string;
  qaApprovedBy?: string;
  brokerageApprovedAt?: string;
  brokerageApprovedBy?: string;
  fundDisbursedAt?: string;
}

export interface LoadCreationCompletionStatusHistoryItem {
  createdAt: string;
  createdBy: string;
  completedAt: string;
  completedBy: string;
  manualOrAutomaticCompletion: string;
  reason: string;
}

export interface BrokerChargeUpdateHistoryItem {
  timezone: string;
  chargeType: string;
  changeAction: 'i' | 'u' | 'd';
  updaterID: string;
  updatedAt: Date;
  updaterName: string;
  decisionMakerName: string;
  changedFields: BrokerChargeChangedField[];
}

export interface BrokerChargeChangedField {
  fieldName: string;
  oldFieldValue: number;
  newFieldValue: number;
  isCents: boolean;
  isNumber: boolean;
  wasDeleted: boolean;
  hideByDefault: boolean;
}

export interface RateConHistoryItem {
  rateConId: number;
  carrierId: string;
  carrierName: string;
  totalCarrierCents: number;
  tenderedAt: string;
  signedAt: string;
  sentByName: string;
  sentToUsers: RateConRecipient[];
  isInvalidated: boolean;
}

export interface HoldHistoryItem {
  carrierName: string;
  heldByName: string;
  holdCreatedAt: string;
  holdEndsAt: string;
  holdReason: string;
}

export interface MiscHistoryItem {
  miscTitle: string;
  miscTimestamp: string;
}

export interface LoadCancellationHistoryItem {
  cancelledByName: string;
  cancelledCause: string;
  cancelledReason: string;
}

export interface LoadUpdateHistoryItem {
  userId: string;
  userName: string;
  changedFields: LoadUpdateChangedField[];
  notificationRecipients: LoadUpdateNotificationRecipient[];
}

export interface RequestedRateHistoryItem {
  carrierID: string;
  carrierName: string;
  dotNumber: string;
  requestedAt: string;
  requestedRateCents: number;
  sentByName: string;
}

export interface LoadUpdateNotificationRecipient {
  userId: string;
  userName: string;
  userPhone: string;
  userEmail: string;
  sentAt: string;
  confirmedAt: string;
}

export interface LoadUpdateChangedField {
  fieldName: string;
  oldFieldValue: string;
  newFieldValue: string;
  isDate: boolean;
  isCents: boolean;
  isNumber: boolean;
  isAddition: boolean;
  wasDeleted: boolean;
  timezone: { name: string; abbreviation: string };
}

export interface RateConRecipient {
  sentToName: string;
  sentToEmail: string;
  sentToPhone: string;
}

export interface LaneHistory {
  laneLoadEvents: LaneHistoryItem[];
}

export interface LaneHistoryItem {
  loadId: string;
  originAddress: string;
  destinationAddress: string;
  completedLoad: boolean;
  eventTime: string;
  carrierCompanyID: string;
  carrierCompanyName: string;
  carrierDOTNumber: string;
  carrierMcNumber: string;
  rateCents: number;
  customerId: string;
  customerName: string;
}

export interface LoadOwner {
  userId: string;
  name: string;
  eligible: boolean;
  assignedLoadCount: number;
}

export interface LoadOwners {
  users: LoadOwner[];
}

export interface TourCustomerFacilityForLoad {
  id: string;
  name: string;
  lnglat: { longitude: number; latitude: number };
  addressLine1?: string;
  addressLine2?: string;
  addressCity?: string;
  addressState?: string;
  addressZipCode?: string;
  timezone: string;
}

export interface TourCustomerYardForLoad {
  id: string;
  name: string;
  lnglat: { longitude: number; latitude: number };
  addressLine1?: string;
  addressLine2?: string;
  addressCity?: string;
  addressState?: string;
  addressStateCode?: string;
  addressZipCode?: string;
  externalId?: string;
  timezone: string;
  ownerAccountId: string;
  createdBy: string;
}

export interface LoadRecommendedCarrier {
  id: string;
  name: string;
  mcNumber: string;
  dotNumber: string;
  contactName: string;
  contactPhone: string;
  lastLoadInLaneRateCents: number;
  lastLoadInLaneCompletedAt: string;
  driverCountTours: number;
  driverCountSingleLoad: number;
  driverCountTotal: number;
  status: string;
  pastLoadsTotalCount: number;
  pastLoadsTotalOnTimeFraction: number;
  pastLoadsInLaneCount: number;
  pastLoadsOnTimeFraction: number;
  pastLoadsInLoadStateCount: number;
  carrierScore: number;
  carrierScoreOnTimeDelivery: number;
  carrierScoreTrackAndTrace: number;
  carrierScoreCheckCallResponsiveness: number;
  carrierScoreOnTimePOD: number;
  pricingCents: number;
  mostRecentRfqPricingCents: number;
  mostRecentRfqDate: string;
  avgCompletedLoadPricingCents: number;
  avgCompletedLoadCount: number;
  laneOfInterestPricingCents: number;
  laneOfInterestDate: string;
  datMedianPricingCents: number;
  contacts: {
    name: string;
    email: string;
    phone: string;
    contactType: string;
  }[];
  admins: SlimAdmin[];
  phoneAdmins: SlimAdmin[];
  emailAdmins: SlimAdmin[];
  lastTouchpointType: CarrierTouchpointType;
  lastTouchpointLoadID: string;
  lastTouchpointNotes: string;
  lastTouchpointCreatedAt: string;
  lastTouchpointCreatedBy: string;
  matchingLanesOfInterestCount: number;
}

export interface RecommendedCarriers {
  recommendedCarriers: LoadRecommendedCarrier[];
}

export interface InternalAllowedChargeType {
  chargeType: string;
  displayName: string;
  customChargeTypeId: number;
  customChargeType: string;
}

export interface LoadCheckCall {
  checkCallStatus: CheckCall;
}

export interface CheckCall {
  checkStatus: CheckCallStatus;
  checkId: number;
  checkSentAt?: string;
  checkOnTime?: boolean;
  checkResolvedAt?: string;
}

export interface PostedLoadboards {
  postedLoadboards: PostedLoadboard[];
}

export interface PostedLoadboard {
  loadboardName: string;
  createdAt: string;
  createdByUserName: string;
  updatedAt: string;
  updatedByUserName: string;
  deletedAt: string;
  deletedByUserName: string;
  postedRate: number;
  loadboardLoadId: string;
}

export interface CancelledReasons {
  causes: CancelledReason[];
}

export interface CancelledReason {
  id: string;
  displayName: string;
}

export type BrokerCheckCallType =
  | 'one_day_before_pickup'
  | 'pre_pickup_recurring'
  | 'pickup_window_start'
  | 'en_route'
  | 'delivery';

export interface BrokerCheckCall {
  id: number;
  loadId: string;
  callType: BrokerCheckCallType;
  answeredCall: boolean;
  createdAt: string;
  createdBy: string;
  comments: string;
  prePickupBrokerCheckCallDetails: {
    id: number;
    callId: number;
    loaded: boolean;
    emptyLocationCity: string;
    emptyLocationState: string;
    currentLocationCity: string;
    currentLocationState: string;
    currentLocationLnglat: XYPoint;
    onTrackForPickup: boolean;
    pickupEta: string;
  };
  enRouteBrokerCheckCallDetails: {
    id: number;
    callId: number;
    pickedUp: boolean;
    currentLocationCity: string;
    currentLocationState: string;
    currentLocationLnglat: XYPoint;
    nextStopETA: string;
  };
  deliveryBrokerCheckCallDetails: {
    id: number;
    callId: number;
    stopId: number;
    droppedOff: boolean;
    currentLocationCity: string;
    currentLocationState: string;
    currentLocationLnglat: XYPoint;
    nextStopETA: string;
    unloadTimeMinutes: number;
  };
}

export interface NextBrokerCheckCall {
  nextBrokerCheckCallType: string;
  nextCallAt: string;
  prevBrokerCheckCallType: BrokerCheckCallType;
  prevCallAt: string;
  prevCallBy: string;
}

export interface OutboundCarrierCall {
  id: number;
  loadId: string;
  carrierId: string;
  contactName: string;
  contactPhone: string;
  answeredCall: boolean;
  interested: boolean;
  notInterestedReasonId: number;
  notInterestedReasonText: string;
  bestRateCents: number;
  createdAt: string;
  createdBy: string;
}

export interface OutboundCarrierCallNotInterestedReason {
  id: number;
  reason: string;
}

export interface LoadWaitlist {
  loadId: string;
  candidates: LoadWaitlistCandidateEntry[];
  rejected: LoadWaitlistRemovedFromConsiderationEntry[];
}

export interface LoadWaitlistCandidateEntry {
  companyId: string;
  companyName: string;
  companyOwnerName: string;
  companyOwnerPhone: string;
  addedToWaitlistAt: string;
  currentPositionInList: number;
  rateConExpiresAt: string;
}

export interface LoadWaitlistRemovedFromConsiderationEntry {
  companyId: string;
  companyName: string;
  addedToWaitlistAt: string;
  removedFromConsiderationAt: string;
  removalReason: string;
}

export interface ChargeForDispatcher {
  id: number;
  loadID: string;
  chargeType: string;
  name: string;
  totalCents: number;
  chargeTypeNameId: number;
  chargeTypeNameIdName: string;
  requiresUpload: boolean;
  pendingUpdate: LoadChargePendingUpdate;
  uploads: ChargeUpload[];
  shipperEyeOnlyUploads: ChargeUpload[];
}

export interface ChargeUpload {
  id: number;
  createdAt: string;
  fileName: string;
  url: string;
}

export interface TrailersForLoad {
  availableTrailersOnSite: TrailerForLoad[];
  unavailableTrailersOnSite: TrailerForLoad[];
  otherTrailers: TrailerForLoad[];
}

export interface TrailerForLoad {
  id: number;
  name: string;
  companyId: string;
  companyName: string;
  reservingUser: {
    id: string;
    name: string;
  };
  assignedToOtherLoad: boolean;
  otherLoadIds: string[];
  externalId: string;
}

export interface ReadyForDispatch {
  loadTrailerId: number;
  stops: ReadyForDispatchStop[];
}

export interface ReadyForDispatchStop {
  id: number;
  facilityId: string;
  facilityName: string;
  stopType: StopType;
  facilityTargetStatus: FacilityTargetStatus;
  stopEmptyTrailerActions: StopEmptyTrailerAction;
}

export interface FacilityTargetStatus {
  target: {
    count: number;
    loadedStatus: string;
  };
  emptyCount: number | null;
  loadedCount: number | null;
  unknownCount: number | null;
}

export type EmptyDropoffType = 'must_drop_empty_trailer' | 'must_not_drop_empty_trailer';

export interface StopEmptyTrailerAction {
  stopId: number;
  pickupEmptyTrailerRequirement: {
    pickupEmpty: boolean;
    lamAssetId?: string | null;
  };
  dropoffEmptyTrailerRequirement: {
    type: EmptyDropoffType;
    facilityToGetOrDropEmpty: {
      id: string;
      name: string;
    };
  };
}

export interface FileWithExtract<T> extends LohiUploadedFile {
  extract?: T;
}

export interface RGPickupDetails {
  laneId: number;
  pickupStartsAt: string;
  pickupTimezone: string;
  willEnterRouteGuideAt: string;
  willGoUFGAt: string;
  enteredUFGAt: string | null;
  willLeaveUFGAt: string;
  leftUFGAt: string | null;
}

export interface RGHistoricalExclusiveOffer {
  id: number;
  offerCompanyId: string;
  offerCompany: string;
  startedAt: string;
  expiresAt: string;
  respondedAt: string | null;
  responseType: string | null;
  rejectReason: string | null;
  responseBy: string | null;
  isActive: boolean;
}

export interface RGLoadDetail {
  details: RGPickupDetails;
  exclusiveOffers: RGHistoricalExclusiveOffer[];
  ufg: RouteGuideLaneCarrier[];
  hasPricing: boolean;
}

export interface CheckInImageColtExtraDetailsEntity {
  id: number;
  key: string;
  rawValue: string;
  value: string;
  confidence: number;
  found: boolean;
  isTime: boolean;
}

export interface CheckInImageColtExtraDetails {
  analysis: {
    id: 11;
    hadExceptions: false;
    errorMessage: null;
    uploadRejected: false;
    rejectionBypassedReason: null;
    trailerPickupBypassedAt: string;
    trailerPickupBypassedReason: string;
    trailerPickupBypasser: string;
    entities: CheckInImageColtExtraDetailsEntity[];
  };
}

export interface CheckinDetails {
  task: string;
  isCompleted: boolean;
  hadIssues: boolean;
  description: string;
  images: {
    url: string;
    name: string;
    archivedAt: string | null;
    coltExtraDetails: CheckInImageColtExtraDetails;
  }[];
}

export interface StopPickupValidation {
  validationId: number;
  driverId: string;
  stopId: number;
  validationType: string;
  createdAt: string;
  description: string;
  validationImages: {
    validationImageId: number;
    imageUrl: string;
    documentType: string;
    assetId: number;
    assetName: string;
    createdAt: string;
    overrideClassification: string | null;
    overrideBy: string | null;
    overrideNotes: string | null;
    overrideAt: string | null;
    htvClassification: string | null;
    htvConfidence: number | null;
    actionSuggested: string | null;
    description: string | null;
  }[];
}

export interface StopAppointmentDetails {
  shouldShow: boolean;
  stopId: number;
  sentToEmail: string;
  createdAt: string;
  requestedTime: string;
}

export interface EapSummary {
  requiresTrailer: boolean;
  hasDriver: boolean;
  bestFacility: PotentialFacility | null;
  potentialFacilities: PotentialFacility[];
}

export interface PotentialFacility {
  id: string;
  facilityName: string;
  targetType: string;
  targetCount: number;
  maxCount: number;
  totalTrailerCount: number;
  emptyTrailerCount: number;
  isOpen: boolean;
  isBanned: boolean;
  probResults: PotentialFacilityProbResults;
  totalEmptiesAvailable: number;
  readyEmptyCount: number;
  activeEADCount: number;
  activeEAPCount: number;
  emptiesNeededForCollectionsCount: number;
}

export interface PotentialFacilityProbResults {
  missingDriverFacilityTravelData: boolean;
  missingFacilityToPickupTravel: boolean;
  detourMiles: number;
  maxDetourMiles: number;
  estimatedDurationMinutes?: number;
  driverNotAllowed: boolean;
  recentFailedEAP: boolean;
}

export interface BOLMetadata {
  fileId: number;
  fileName: string;
  autoApproved: boolean;
  refNumExtracted: string;
  refNumCurrent: string;
  pickupExtracted: string;
  pickupCurrent: string;
  dropoffExtracted: string;
  dropoffCurrent: string;
}

export interface LoadPriceCheckDetail {
  loadId: string;
  brokerApprovalChargeNames: string[];
  priceReviewPermittedActions: PermittedPriceReviewAction[];
  latestBrokerPriceCheckDecision: string;
  latestBrokerPriceCheckNotes: string;
  latestBrokerPriceCheckUserName: string;
  latestCarrierPriceQADecision: string;
  latestCarrierPriceQANotes: string;
  latestCarrierPriceQAUserName: string;
  latestCustomerPriceQADecision: string;
  latestCustomerPriceQANotes: string;
  latestCustomerPriceQAUserName: string;
}

export type PermittedPriceReviewAction =
  | 'submit_broker_charge_review'
  | 'submit_broker_manager_review'
  | 'approve_carrier_price'
  | 'deny_carrier_price'
  | 'approve_customer_price'
  | 'remove_customer_price_review'
  | 'billing_reject_carrier_price';

@Injectable({
  providedIn: 'root',
})
export class LoadService {
  private loadIdInURL$ = combineLatest([
    this.routerState.listenForParamChange$(LOAD_ID).pipe(startWith(null)),
    this.routerState.listenForQueryChange$(LOAD_ID).pipe(startWith(null)),
  ]).pipe(
    map(([loadIdInURL, loadIdInQuery]) => {
      return loadIdInURL || loadIdInQuery;
    }),
    shareReplay(1),
  );
  private load$$ = new ReloadableSubject<string, ReloadLoadOrLohiLoad>(
    null,
    (x) => this.loadLoad$(x),
    this.loadIdInURL$,
  );
  public load$: Observable<ReloadLoadOrLohiLoad> = this.load$$.asObservable();

  private loadReassignableDrivers$$ = new ReloadableSubject<string, SlimDriver[]>(
    [],
    (x) => this.loadReassignableDrivers(x),
    this.loadIdInURL$,
  );
  public loadReassignableDrivers$: Observable<SlimDriver[]> = this.loadReassignableDrivers$$.asObservable();

  public shipperLoadStatus$: Observable<ShipperLoadStatus> = this.load$.pipe(map((l) => l?.shipperLoadStatus));

  private lohiLoadCharges$$ = new ReloadableSubject<string, LoadChargeDetails>(
    null,
    (str) => this.getLohiLoadCharges(str),
    this.loadIdInURL$,
  );
  public lohiLoadCharges$: Observable<LoadChargeDetails> = this.lohiLoadCharges$$.asObservable();

  private allowedLoadChargeTypes$$ = new ReloadableSubject<null, InternalAllowedChargeType[]>(
    null,
    (x) => this.getAllowedLoadChargeTypes(),
    of(null),
  );
  public allowedLoadChargeTypes$: Observable<InternalAllowedChargeType[]> =
    this.allowedLoadChargeTypes$$.asObservable();

  private loadPriceCheckDetail$$ = new ReloadableSubject<string, LoadPriceCheckDetail>(
    null,
    (str) => this.getPriceCheckDetail(str),
    this.loadIdInURL$,
  );
  public loadPriceCheckDetail$: Observable<LoadPriceCheckDetail> = this.loadPriceCheckDetail$$.asObservable();

  private loadAccountingInformation$$ = new ReloadableSubject<string, AccountingLoad>(
    null,
    (str) => this.getAccountingLoad(str),
    this.loadIdInURL$,
  );
  public loadAccountingInformation$: Observable<AccountingLoad> = this.loadAccountingInformation$$.asObservable();

  private lohiLoadOnHold$$ = new ReloadableSubject<string, LoadBookingHold>(
    null,
    (x) => this.getLohiLoadHold(x),
    this.loadIdInURL$,
  );
  public lohiLoadOnHold$: Observable<LoadBookingHold> = this.lohiLoadOnHold$$.asObservable();

  private lohiExportCoverageStrategies$$ = new ReloadableSubject<ReloadLoadOrLohiLoad, CoverageStrategyStop[]>(
    null,
    (x) => this.getLoadCoverageStrategies(x),
    this.load$,
  );
  public lohiExportCoverageStrategies$: Observable<CoverageStrategyStop[]> =
    this.lohiExportCoverageStrategies$$.asObservable();

  private lohiLoadPostingGuidelines$$ = new ReloadableSubject<string, PostingGuidelines>(
    null,
    (x) => this.getLohiLoadGuidelines(x),
    this.loadIdInURL$,
  );
  public lohiLoadPostingGuidelines$: Observable<PostingGuidelines> = this.lohiLoadPostingGuidelines$$.asObservable();

  private loadChargeHistory$$ = new ReloadableSubject<string, LoadHistory>(
    null,
    (x) => this.getLoadChargeHistory(x),
    this.loadIdInURL$,
  );
  public loadChargeHistory$: Observable<LoadHistory> = this.loadChargeHistory$$.asObservable();

  private laneHistory$$ = new BehaviorSubject<LaneHistory>(null);
  public laneHistory$: Observable<LaneHistory> = this.laneHistory$$.asObservable();

  private loadCompanyAdmins$$ = new ReloadableSubject<ReloadLoadOrLohiLoad, CompanyAdmins>(
    null,
    (x) => this.getLoadCompanyAdmins(x),
    this.load$,
  );
  public loadCompanyAdmins$: Observable<CompanyAdmins> = this.loadCompanyAdmins$$.asObservable();

  private loadRecommendedCarriers$$ = new ReloadableSubject<string, RecommendedCarriers>(
    null,
    (x) => this.getLoadRecommendedCarriers(x),
    this.loadIdInURL$,
  );
  public loadRecommendedCarriers$: Observable<RecommendedCarriers> = this.loadRecommendedCarriers$$.asObservable();

  private loadCheckCallStatus$$ = new ReloadableSubject<string, LoadCheckCall>(
    null,
    (x) => this.getLoadCheckCall(x),
    this.loadIdInURL$,
  );
  public loadCheckCallStatus$: Observable<LoadCheckCall> = this.loadCheckCallStatus$$.asObservable();

  private nextBrokerCheckCall$$ = new ReloadableSubject<string, NextBrokerCheckCall>(
    null,
    (x) => this.getNextBrokerCheckCall(x),
    this.loadIdInURL$,
  );
  public nextBrokerCheckCall$: Observable<NextBrokerCheckCall> = this.nextBrokerCheckCall$$.asObservable();

  private brokerCheckCalls$$ = new ReloadableSubject<string, BrokerCheckCall[]>(
    [],
    (x) => this.getBrokerCheckCalls(x),
    this.loadIdInURL$,
  );
  public brokerCheckCalls$: Observable<BrokerCheckCall[]> = this.brokerCheckCalls$$.asObservable();

  private carrierWaitlist$$ = new ReloadableSubject<string, LoadWaitlist>(
    null,
    (x) => this.getCarrierWaitlist(x),
    this.loadIdInURL$,
  );
  public carrierWaitlist$: Observable<LoadWaitlist> = this.carrierWaitlist$$.asObservable();

  private loadOwners: SimpleFinn<LoadOwner[]>;

  public get loadOwners$(): Observable<LoadOwner[]> {
    return this.loadOwners.get$();
  }

  private cancelledReasons: SimpleFinn<CancelledReason[]>;

  public get cancelledReasons$(): Observable<CancelledReason[]> {
    return this.cancelledReasons.get$();
  }

  private facilitiesForLoad$$ = new ReloadableSubject<string, TourCustomerFacilityForLoad[]>(
    [],
    (x) => this.findFacilitiesForLoad$(x),
    this.loadIdInURL$,
  );
  public facilitiesForLoad$ = this.facilitiesForLoad$$.asObservable();

  private yardsForLoad$$ = new ReloadableSubject<string, TourCustomerYardForLoad[]>(
    [],
    (x) => this.findYardsForLoad$(x),
    this.loadIdInURL$,
  );
  public yardsForLoad$ = this.yardsForLoad$$.asObservable();

  private postedLoadboards$$ = new ReloadableSubject<string, PostedLoadboards>(
    null,
    (x) => this.getPostedLoadboardsForLoadId(x),
    this.loadIdInURL$,
  );
  public postedLoadboards$: Observable<PostedLoadboards> = this.postedLoadboards$$.asObservable();

  private outboundCarrierCallNotInterestedReasons: SimpleFinn<OutboundCarrierCallNotInterestedReason[]>;

  public get outboundCarrierCallNotInterestedReasons$(): Observable<OutboundCarrierCallNotInterestedReason[]> {
    return this.outboundCarrierCallNotInterestedReasons.get$();
  }

  private charges$$ = new RouterFinn([], this.loadIdInURL$, (x) => this.loadCharges(x), true);

  public get charges$(): Observable<ChargeForDispatcher[]> {
    return this.charges$$.get$();
  }

  private loadHistoryMap$$: RouterFinn<LoadHistoryMap>;

  public get loadHistoryMap$(): Observable<LoadHistoryMap> {
    return this.loadHistoryMap$$.get$();
  }

  private lohiUploadedFileInfo$$ = new RouterFinn<FileWithExtract<any>>(
    null,
    this.routerState.listenForParamChange$(LOAD_FILE_ID),
    (fileID) => this.getLohiUploadedFileInfo(fileID),
  );

  public get lohiUploadedFileInfo$(): Observable<FileWithExtract<any>> {
    return this.lohiUploadedFileInfo$$.get$();
  }

  private loadRGDetails$$ = new RouterFinn<RGLoadDetail>(
    null,
    this.routerState.listenForParamChange$(LOAD_ID),
    (loadID) => this.getLoadRGDetails(loadID),
    false,
  );

  public get loadRGDetails$(): Observable<RGLoadDetail> {
    return this.loadRGDetails$$.get$();
  }

  private loadCheckinDetails = new RouterFinn<Record<number, CheckinDetails[]>>(
    {},
    this.routerState.listenForParamChange$(LOAD_ID),
    (loadID) => this.getLoadCheckinDetails(loadID),
    false,
  );

  public get loadCheckinDetails$(): Observable<Record<number, CheckinDetails[]>> {
    return this.loadCheckinDetails.get$();
  }

  private loadAppointmentDetails$$ = new RouterFinn<Record<number, StopAppointmentDetails>>(
    {},
    this.routerState.listenForParamChange$(LOAD_ID),
    (loadID) => this.getLoadAppointmentDetails(loadID),
    false,
  );

  public get loadAppointmentDetails$(): Observable<Record<number, StopAppointmentDetails>> {
    return this.loadAppointmentDetails$$.get$();
  }

  private stopPickupValidation$$ = new RouterFinn<Record<number, StopPickupValidation>>(
    {},
    this.routerState.listenForParamChange$(LOAD_ID),
    (loadID) => this.getLoadStopPickupValidations(loadID),
    false,
  );

  public get stopPickupValidation$(): Observable<Record<number, StopPickupValidation>> {
    return this.stopPickupValidation$$.get$();
  }

  private eapSummary = new RouterFinn<EapSummary>(
    null,
    this.routerState.listenForParamChange$(LOAD_ID),
    (loadID) => this.getEapSummary(loadID),
    false,
  );

  public get eapSummary$(): Observable<EapSummary> {
    return this.eapSummary.get$();
  }

  public eapSummaryLoading$ = this.eapSummary.networkActive$;

  private canAddEAP = new RouterFinn<boolean>(
    false,
    this.routerState.listenForParamChange$(LOAD_ID),
    (loadID) => this.getCanAddEAP(loadID),
    false,
  );

  public get canAddEAP$(): Observable<boolean> {
    return this.canAddEAP.get$();
  }

  public canAddEAPLoading$ = this.canAddEAP.networkActive$;

  private bolAutomationData = new RouterFinn<Record<number, BOLMetadata>>(
    null,
    this.loadIdInURL$,
    (loadID) => this.getBolAutomationData(loadID),
    false,
  );

  public get bolAutomationLoading$(): Observable<boolean> {
    return this.bolAutomationData.networkActive$;
  }

  public get bolAutomationData$(): Observable<Record<number, BOLMetadata>> {
    return this.bolAutomationData.get$();
  }

  constructor(
    private http: HttpClient,
    private routerState: RouterStateService,
    private httpBackend: HttpBackend,
    private afAuth: AngularFireAuth,
  ) {
    this.listenForLoad();
    this.loadOwners = new SimpleFinn<LoadOwner[]>([], this.loadOwnerUsers);
    this.cancelledReasons = new SimpleFinn<CancelledReason[]>([], this.loadCancelledReasons);
    this.outboundCarrierCallNotInterestedReasons = new SimpleFinn<OutboundCarrierCallNotInterestedReason[]>(
      [],
      this.loadOutboundCarrierCallNotInterestedReason,
    );
    this.loadHistoryMap$$ = new RouterFinn<LoadHistoryMap>(null, this.loadIdInURL$, this.getLoadHistoryMap);
  }

  private loadLoad$(loadId: string): Observable<ReloadLoadOrLohiLoad> {
    if (!loadId) {
      return of(null);
    }
    return this.http.get<ReloadLoadOrLohiLoad>(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}`);
  }

  private listenForLoad() {
    this.loadIdInURL$
      .pipe(
        switchMap((loadId) => {
          this.laneHistory$$.next(null);
          if (!loadId) {
            return of(null as ReloadLoadOrLohiLoad);
          }
          return this.loadLoad$(loadId);
        }),
      )
      .subscribe((value) => {
        if (!value) {
          this.load$$.next(null);
          return;
        }
        this.load$$.next(value);
      });
  }

  public async removeFile(loadId: string, fileID: number) {
    try {
      await lastValueFrom(
        this.http.delete(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/remove_file`, {
          body: {
            fileID,
          },
        }),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async qaApprovePOD(loadId: string, completedAt: Date) {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/qa_approve_pod`, { completedAt }),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async qaRejectPOD(loadId: string, fileCategories: string[], reason: string): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/qa_reject_pod`, {
          fileCategories: fileCategories,
          reason: reason,
        }),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async approveSingleFile(loadId: string, fileId: number): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/approve_single_upload`, {
          fileId,
        }),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async rejectSingleFile(loadId: string, fileId: number, reason: string, shouldFine: boolean): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/reject_single_upload`, {
          fileId,
          reason,
          shouldFine,
        }),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async canFineForLoad(loadId: string): Promise<{ canFine: boolean; reason: string }> {
    try {
      const response = await lastValueFrom(
        this.http.get<{ canFine: boolean; reason: string }>(
          `${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/can_fine_carrier_for_bad_upload`,
        ),
      );
      return response;
    } catch (e) {
      return { canFine: false, reason: 'failed to check if we can fine driver' };
    }
  }

  public async uploadFiles(loadId: string, files: File[], category: string) {
    try {
      for (const file of files) {
        const formData = new FormData();
        formData.set('file', file);
        formData.set('category', category);
        await lastValueFrom(
          this.http.post(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/upload`, formData),
        );
      }
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async updateFileCategory(fileID: number, category: FileUploadType): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.patch(`${environment.api}/v2/max_payloop/dispatcher/loopi/files/${fileID}/category`, { category }),
      );
      await this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async refreshLoad() {
    this.load$$.refresh();
    this.lohiLoadCharges$$.refresh();
    this.lohiLoadOnHold$$.refresh();
    this.lohiLoadPostingGuidelines$$.refresh();
    this.loadChargeHistory$$.refresh();
    this.facilitiesForLoad$$.refresh();
    this.loadRecommendedCarriers$$.refresh();
    this.loadCheckCallStatus$$.refresh();
    this.refreshBrokerCheckCalls();
    this.postedLoadboards$$.refresh();
    this.allowedLoadChargeTypes$$.refresh();
    this.carrierWaitlist$$.refresh();
    this.loadCompanyAdmins$$.refresh();
    this.lohiExportCoverageStrategies$$.refresh();
    this.loadPriceCheckDetail$$.refresh();
  }

  public async refreshAccountingInformation() {
    this.loadPriceCheckDetail$$.refresh();
    this.loadAccountingInformation$$.refresh();
  }

  public async editLoadDetails(loadId: string, formValue: EditLoadRequest): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/edit_details`, formValue),
      );
      await this.refreshLoad();
      return true;
    } catch (error) {
      return false;
    }
  }

  public async editStopDetails(loadId: string, formValue: EditLoadStop): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.put(
          `${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/stop/${formValue.id}/edit`,
          formValue,
        ),
      );
      await this.refreshLoad();
      return true;
    } catch (error) {
      return false;
    }
  }

  public async deleteStopFromLoad(loadId: string, stopId: number): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.delete(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/stop/${stopId}`),
      );
      this.refreshLoad();
      return true;
    } catch (error) {
      return false;
    }
  }

  public async addStopAtSequence(loadId: string, formValue: EditLoadStop): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/stop/stop_at_sequence`, formValue),
      );
      await this.refreshLoad();
      return true;
    } catch (error) {
      return false;
    }
  }

  public async unassignDriver(
    loadId: string,
    reason: string,
    markNotReady?: boolean,
    customerNote?: string,
    treatUnassignAsDecline?: boolean,
  ): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/unassign_driver`, {
          reason,
          markNotReady: markNotReady,
          customerNote,
          treatAsDecline: treatUnassignAsDecline,
        }),
      );
      this.refreshLoad();
      return true;
    } catch (error) {
      return false;
    }
  }

  public async assignDriver(
    loadId: string,
    driverId: string,
    salesTrailerType: string,
    charges: any[],
  ): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/assign_driver`, {
          driverUserID: driverId,
          carrierRatesForExistingCharges: charges,
          salesTrailerType,
        }),
      );
      await this.refreshLoad();
      return true;
    } catch (error) {
      return false;
    }
  }

  public async reAssignDriver(loadId: string, driverId: string, reason: string): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/reassign`, {
          driverUserID: driverId,
          reason,
        }),
      );
      this.refreshLoad();
      return true;
    } catch (error) {
      return false;
    }
  }

  public async assignLoadViaDOT(
    brokerageMismatch: boolean,
    loadId: string,
    dotNumber: string,
    carrierName: string,
    companyID: string,
    email: string | null,
    phone: string | null,
    endsAt: string,
    salesTrailerType: string,
    charges: any[],
  ): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/assign_driver`, {
          brokerageMismatch,
          dotNumber,
          email,
          companyID,
          phone,
          carrierName,
          endsAt,
          salesTrailerType,
          carrierRatesForExistingCharges: charges,
        }),
      );
      await this.refreshLoad();
      return true;
    } catch (error) {
      return false;
    }
  }

  public async reAssignOwner(loadId: string, userId: string): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/v2/max_payloop/dispatcher/load_coverage/assign_user_to_load`, {
          userId,
          loadId,
        }),
      );
      await this.refreshLoad();
      return true;
    } catch (error) {
      return false;
    }
  }

  private loadOwnerUsers = (): Promise<LoadOwner[]> => {
    return lastValueFrom(
      this.http
        .get<LoadOwners>(`${environment.api}/v2/max_payloop/dispatcher/load_coverage/users`)
        .pipe(map((v) => v?.users)),
    );
  };

  private loadCancelledReasons = (): Promise<CancelledReason[]> => {
    return lastValueFrom(
      this.http
        .get<CancelledReasons>(`${environment.api}/v2/max_payloop/dispatcher/cancel_load_causes`)
        .pipe(map((v) => v?.causes || [])),
    );
  };

  private loadOutboundCarrierCallNotInterestedReason = (): Promise<OutboundCarrierCallNotInterestedReason[]> => {
    return lastValueFrom(
      this.http
        .get<{ reasons: OutboundCarrierCallNotInterestedReason[] }>(
          `${environment.api}/v2/max_payloop/dispatcher/loopi/outbound_carrier_calls_not_interested_reasons`,
        )
        .pipe(map((v) => v?.reasons)),
    );
  };

  public async markAsAcknowledged(loadId: string): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/acknowledge`, {}),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  private getLohiLoadCharges = (loadId: string): Observable<LoadChargeDetails> => {
    if (!loadId) {
      return of(null);
    }
    return this.http.get<LoadChargeDetails>(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/charges`);
  };

  private getLoadChargeHistory = (loadId: string): Observable<LoadHistory> => {
    if (!loadId) {
      return of(null);
    }
    return this.http.get<LoadHistory>(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/load_history`);
  };

  private getAllowedLoadChargeTypes = (): Observable<InternalAllowedChargeType[]> => {
    return this.http
      .get<{ chargeTypes: InternalAllowedChargeType[] }>(
        `${environment.api}/v2/max_payloop/dispatcher/loopi/charge_types`,
      )
      .pipe(map((response) => response.chargeTypes || []));
  };

  public async loadLaneHistory() {
    const load = this.load$$.value;
    if (!load?.lohi?.id) {
      return;
    }
    try {
      const history = await lastValueFrom(
        this.http.get<LaneHistory>(
          `${environment.api}/v2/max_payloop/dispatcher/loopi/${load.lohi.id}/lane_carrier_history`,
        ),
      );
      this.laneHistory$$.next(history);
    } finally {
    }
  }

  private getLoadCheckCall = (loadId: string): Observable<LoadCheckCall> => {
    if (!loadId) {
      return of(null);
    }
    return this.http.get<LoadCheckCall>(`${environment.api}/v2/max_payloop/dispatcher/check_call/current`, {
      params: {
        loadId,
      },
    });
  };

  private getNextBrokerCheckCall = (loadId: string): Observable<NextBrokerCheckCall> => {
    if (!loadId) {
      return of(null);
    }
    return this.http.get<NextBrokerCheckCall>(
      `${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/broker_check_calls/next`,
    );
  };

  private getBrokerCheckCalls = (loadId: string): Observable<BrokerCheckCall[]> => {
    if (!loadId) {
      return of([]);
    }
    return this.http
      .get<{ brokerCheckCalls: BrokerCheckCall[] }>(
        `${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/broker_check_calls`,
      )
      .pipe(map((response) => response.brokerCheckCalls || []));
  };

  private async refreshBrokerCheckCalls() {
    this.brokerCheckCalls$$.refresh();
    this.nextBrokerCheckCall$$.refresh();
  }

  public async saveBrokerCheckCall(call: BrokerCheckCall): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/v2/max_payloop/dispatcher/loopi/${call.loadId}/broker_check_calls`, call),
      );
      await this.refreshBrokerCheckCalls();
      return true;
    } catch (error) {
      return false;
    }
  }

  public async saveOutboundCarrierCall(call: OutboundCarrierCall): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.post(
          `${environment.api}/v2/max_payloop/dispatcher/loopi/${call.loadId}/outbound_carrier_calls`,
          call,
        ),
      );
      await this.refreshBrokerCheckCalls();
      return true;
    } catch (error) {
      return false;
    }
  }

  private getLoadCompanyAdmins = (load: ReloadLoadOrLohiLoad): Observable<CompanyAdmins> => {
    if (!load?.lohi?.companyId) {
      return of(null);
    }
    return this.http.get<CompanyAdmins>(`${environment.api}/v2/max_payloop/dispatcher/company_admins`, {
      params: { companyId: load.lohi.companyId },
    });
  };

  private getLoadRecommendedCarriers = (loadId: string): Observable<RecommendedCarriers> => {
    if (!loadId) {
      return of(null);
    }
    return this.http.get<RecommendedCarriers>(
      `${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/recommended_carriers`,
    );
  };

  private getLohiLoadHold = (loadId: string): Observable<LoadBookingHold> => {
    if (!loadId) {
      return of(null);
    }
    return this.http
      .get<{ bookingHold: LoadBookingHold }>(
        `${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/booking_hold`,
      )
      .pipe(map((res) => res?.bookingHold));
  };

  public getLoadCoverageStrategies = (load: ReloadLoadOrLohiLoad): Observable<CoverageStrategyStop[]> => {
    if (load?.lohi?.specialHandling !== 'export' || load?.lohi?.status !== 'not_assigned') {
      return of([]);
    }
    return this.http
      .get<{ stopSets: CoverageStrategyStop[] }>(
        `${environment.api}/v2/max_payloop/dispatcher/loopi/${load.lohi.id}/coverage_strategy_stops`,
      )
      .pipe(map((res) => res?.stopSets));
  };

  private getLohiLoadGuidelines = (loadId: string): Observable<PostingGuidelines> => {
    if (!loadId) {
      return of(null);
    }
    return this.http.get<PostingGuidelines>(
      `${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/posting_guidelines`,
    );
  };

  private getAccountingLoad = (loadId: string): Observable<AccountingLoad> => {
    if (!loadId) {
      return of(null);
    }

    return this.http
      .get<{ load: AccountingLoad }>(
        `${environment.api}/v2/max_payloop/dispatcher/loopi/internal_billing_portal/load/${loadId}`,
      )
      .pipe(map((response) => response.load || null));
  };

  private getPriceCheckDetail = (loadId: string): Observable<LoadPriceCheckDetail> => {
    if (!loadId) {
      return of(null);
    }

    return this.http
      .get<{ load: LoadPriceCheckDetail }>(
        `${environment.api}/v2/max_payloop/dispatcher/loopi/internal_billing_portal/price_check_detail/${loadId}`,
      )
      .pipe(map((response) => response.load || null));
  };

  public async removeHold(loadID: string) {
    try {
      await lastValueFrom(
        this.http.delete(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadID}/booking_hold`),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async addHold(loadId: string, reason?: string, endsAt?: string, carrierId?: string) {
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/booking_hold`, {
          loadId,
          reason,
          endsAt,
          carrierId,
        }),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async updateTargetRate(loadId: string, value: any) {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/target_rate`, value),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async updateCoverageStrategyTargetRate(
    loadId: string,
    coverageType: 'street_turn' | 'source_load' | 'transit_load',
    priceCents: number,
  ) {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/coverage_strategy_pricing`, {
          coverageType,
          priceCents,
        }),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async cancelLoad(loadId: string, cancelledCause: string, cancelledReason: string): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/cancel`, {
          cancelledCause,
          cancelledReason,
        }),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async duplicateLoad(loadId: string) {
    try {
      const response = await lastValueFrom(
        this.http.post<{ loadId: string }>(
          `${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/duplicate`,
          {},
        ),
      );
      return response.loadId;
    } catch (e) {
      return false;
    }
  }

  public async resolveCheckCall(id: string): Promise<boolean> {
    try {
      await lastValueFrom(this.http.post(`${environment.api}/v2/max_payloop/dispatcher/check_call/${id}/resolve`, {}));
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  private findFacilitiesForLoad$(loadId: string): Observable<TourCustomerFacilityForLoad[]> {
    if (!loadId) {
      return of([]);
    }
    return this.http
      .get<{ data: TourCustomerFacilityForLoad[] }>(
        `${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/facilities`,
      )
      .pipe(map((r) => r.data));
  }

  private findYardsForLoad$(loadId: string): Observable<TourCustomerYardForLoad[]> {
    if (!loadId) {
      return of([]);
    }
    return this.http
      .get<{ data: TourCustomerYardForLoad[] }>(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/yards`)
      .pipe(map((r) => r.data));
  }

  public async updateLoadCharges(
    loadId: string,
    charges: any[],
    notifyCarrier: boolean,
    requireNewRateConSignature: boolean,
    adminUserID: string,
    additionalEmailsToSendRatecon?: string[],
  ): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/charges_v2`, {
          charges: charges,
          notifyCarrier,
          preserveRateconSignature: !requireNewRateConSignature,
          adminUserID,
          additionalEmailsToSendRatecon,
        }),
      );
      await this.refreshLoad();
      return true;
    } catch (error) {
      return false;
    }
  }

  public async assignCarrier(
    loadId: string,
    companyID: string,
    chargesToSend: any[],
    salesTrailerType: string,
    adminUserID: string,
    additionalEmailsToSendRatecon?: string[],
    sentFromRecommendedCarriersPage = false,
    coverageStrategy = null,
  ) {
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/assign_driver`, {
          companyID,
          salesTrailerType,
          carrierRatesForExistingCharges: chargesToSend,
          adminUserID,
          additionalEmailsToSendRatecon,
          sentFromRecommendedCarriersPage,
          coverageStrategy,
        }),
      );
      await this.refreshLoad();
      return true;
    } catch (error) {
      return false;
    }
  }

  public async resendRatecon(loadId) {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/resend_ratecon`, {}),
      );
      this.refreshLoad();
      return true;
    } catch (error) {
      return false;
    }
  }

  public async getRateconLink(loadId: string) {
    try {
      const url = await lastValueFrom(
        this.http.get<{ signInUrl: string }>(
          `${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/ratecon_sign_in_link`,
        ),
      );
      return url.signInUrl;
    } catch (e) {
      return false;
    }
  }

  private async loadReassignableDrivers(loadID: string) {
    if (!loadID) {
      return [];
    }
    try {
      return await lastValueFrom(
        this.http
          .get<{ drivers: SlimDriver[] }>(
            `${environment.api}/v2/max_payloop/dispatcher/loopi/${loadID}/reassignable_drivers`,
          )
          .pipe(map((r) => r?.drivers || [])),
      );
    } catch (e) {
      return [];
    }
  }

  private getPostedLoadboardsForLoadId = (loadID: string): Observable<PostedLoadboards> => {
    return this.http.get<PostedLoadboards>(
      `${environment.api}/v2/max_payloop/dispatcher/loopi/${loadID}/posted_loadboards`,
    );
  };

  public async postTruckstopLoad(loadId: string, rateCents?: string, comment?: string) {
    if (!loadId) {
      return false;
    }

    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/v2/max_payloop/dispatcher/truckstop/${loadId}`, {
          rateCents,
          comment,
        }),
      );

      await this.refreshLoad();
      return true;
    } catch (err) {
      return false;
    }
  }

  public async removeTruckstopLoad(loadId: string) {
    try {
      await lastValueFrom(this.http.delete(`${environment.api}/v2/max_payloop/dispatcher/truckstop/${loadId}`));
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async completeStop(loadId: string, stopId: number | string, reason: string) {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/stop/${stopId}/complete`, {
          reason,
        }),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async completeStopCheckin(loadId: string, stopId: number | string, reason: string) {
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/stop/${stopId}/complete_checkin`, {
          reason,
        }),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async flagAsHotPriorityLoad(loadId: string, isHotPriorityLoad: boolean) {
    try {
      await lastValueFrom(
        this.http.patch(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/set_hot_priority`, {
          isHotPriorityLoad,
        }),
      );
      this.refreshLoad();
      return true;
    } catch (error) {
      return false;
    }
  }

  public async markAutoDispatchable(loadId: string, isAutoDispatchable: boolean) {
    try {
      await lastValueFrom(
        this.http.patch(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/set_is_auto_dispatchable`, {
          isAutoDispatchable,
        }),
      );
      this.refreshLoad();
      return true;
    } catch (error) {
      return false;
    }
  }

  private getCarrierWaitlist = (loadID: string): Observable<LoadWaitlist> => {
    return this.http.get<LoadWaitlist>(`${environment.api}/v2/load/${loadID}/waitlist`);
  };

  public async removeCarrierFromWaitlist(loadId: string, carrierId: string, reason: string) {
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/v2/load/${loadId}/waitlist/remove`, { carrierId, reason }),
      );
      this.carrierWaitlist$$.refresh();
      return true;
    } catch (error) {
      return false;
    }
  }

  public async saveStorageStopRequired(loadId: string, storageStopRequired: boolean) {
    try {
      await lastValueFrom(
        this.http.patch(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/set_storage_stop_required`, {
          storageStopRequired,
        }),
      );
      this.refreshLoad();
      return true;
    } catch (error) {
      return false;
    }
  }

  public async saveCoverageStrategy(
    loadId: string,
    setOverrides: boolean,
    streetTurn: boolean,
    sourceLoad: boolean,
    transitLoad: boolean,
  ): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/coverage_strategy`, {
          setOverrides,
          streetTurn,
          sourceLoad,
          transitLoad,
        }),
      );
      this.refreshLoad();
      return true;
    } catch (error) {
      return false;
    }
  }

  public async respondSchedulingRequest(id: string, acceptedSuggestionId: number | null): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/v2/max_payloop/dispatcher/vst_appointment/${id}/respond`, {
          acceptedSuggestionId,
        }),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async createSchedulingRequest(stopId: number, startTime: Date): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/v2/max_payloop/dispatcher/vst_appointment`, {
          stopId,
          startTime,
        }),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  private async loadCharges(loadId: string): Promise<ChargeForDispatcher[]> {
    try {
      return await lastValueFrom(
        this.http
          .get<{ charges: ChargeForDispatcher[] }>(
            `${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/charges_for_dispatcher_view`,
          )
          .pipe(map((resp) => resp.charges)),
      );
    } catch (e) {
      return null;
    }
  }

  public async uploadShippersEyesOnlyCharge(
    lohiLoadId: string,
    chargeId: number,
    fileToUpload: File,
  ): Promise<boolean> {
    try {
      const idToken = await lastValueFrom(this.afAuth.idToken.pipe(take(1)));
      const formData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);
      // Use custom one because we need this thing to generate the headers for multi part
      const client = new HttpClient(this.httpBackend);
      const response = await lastValueFrom(
        client.post<{ charges: ChargeForDispatcher[] }>(
          `${environment.api}/v2/max_payloop/dispatcher/loopi/${lohiLoadId}/charges_for_dispatcher_view/${chargeId}/shipper_eyes_only_upload`,
          formData,
          {
            headers: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              Authorization: `Bearer ${idToken}`,
            },
          },
        ),
      );
      this.charges$$.next(response.charges);
      return true;
    } catch (err) {
      return false;
    }
  }

  public async deleteShipperEyesOnlyFile(lohiLoadId: string, chargeId: number, fileId: number): Promise<boolean> {
    try {
      const response = await lastValueFrom(
        this.http.delete<{ charges: ChargeForDispatcher[] }>(
          `${environment.api}/v2/max_payloop/dispatcher/loopi/${lohiLoadId}/charges_for_dispatcher_view/${chargeId}/shipper_eyes_only_upload/${fileId}`,
        ),
      );
      this.charges$$.next(response.charges);
      return true;
    } catch (err) {
      return false;
    }
  }

  public uploadsPdf$(lohiLoadId: string): Observable<Blob> {
    return this.http.get(`${environment.api}/v2/max_payloop/dispatcher/loopi/${lohiLoadId}/uploads_pdf`, {
      responseType: 'blob',
    });
  }

  public async saveReadyForPickupStatus(loadID: string) {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadID}/mark_as_ready_for_pickup`, ''),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async updateLockStatus(loadID: string, lock: boolean) {
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadID}/vpf_autodispatch_lock`, { lock }),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async updatePoolOnLoad(loadID: string, poolID: number): Promise<string> {
    const result = await lastValueFrom(
      this.http
        .post<{
          message: string;
        }>(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadID}/vpf_autodispatch_pool`, {
          pool: poolID,
        })
        .pipe(map((res) => res.message)),
    );
    this.refreshLoad();
    return result;
  }

  public async getPoolSearch$(search: string): Promise<AutodispatchPool[]> {
    return lastValueFrom(
      this.http
        .get<{
          poolList: AutodispatchPool[];
        }>(`${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/search`, {
          params: { searchString: search },
        })
        .pipe(map((res) => res.poolList)),
    );
  }

  public getTrailersForLoad$ = (loadId: string) =>
    this.http
      .get<TrailersForLoad>(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/available_trailers`)
      .pipe(
        map((v) => {
          return {
            availableTrailersOnSite: v?.availableTrailersOnSite ?? [],
            unavailableTrailersOnSite: v?.unavailableTrailersOnSite ?? [],
            otherTrailers: v?.otherTrailers ?? [],
          };
        }),
        shareReplay(shareReplayComponentConfig),
      );

  public async assignTrailer(loadId: string, lamTrailerId?: number | null) {
    try {
      await lastValueFrom(
        this.http.post<void>(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/upsert_lam_trailer`, {
          lamTrailerId,
        }),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  private getLoadHistoryMap = (loadID: string): Promise<LoadHistoryMap> =>
    lastValueFrom(
      this.http.get<LoadHistoryMap>(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadID}/load_history_map`),
    );

  public getTrailersForPickup$ = (loadId: string, stopId: number) => {
    return this.http.get<TrailersForLoad>(
      `${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/stop/${stopId}/available_trailers`,
    );
  };

  public async updateAvailabilityToBrokerage(loadId: string, availableToBrokerage: boolean) {
    try {
      await lastValueFrom(
        this.http.post<void>(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/update_brokerage_status`, {
          availableToBrokerage,
        }),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async updateCompletedAt(loadId: string, adjustedCompletedAt: Date) {
    try {
      await lastValueFrom(
        this.http.post(
          `${environment.api}/v2/max_payloop/dispatcher/loopi/internal_billing_portal/update_adjusted_completed_at`,
          {
            loadId,
            adjustedCompletedAt,
          },
        ),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async updatePickedUpAt(loadId: string, adjustedPickedUpAt: Date) {
    try {
      await lastValueFrom(
        this.http.post(
          `${environment.api}/v2/max_payloop/dispatcher/loopi/internal_billing_portal/update_adjusted_picked_up_at`,
          {
            loadId: loadId,
            adjustedPickedUpAt: adjustedPickedUpAt,
          },
        ),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async completeLoad(loadId: string, completedAt: string, reason: string) {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/complete`, { completedAt, reason }),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async approvePendingCharge(
    loadId: string,
    pendingChargeID: number,
    notesForCarrier: string,
    internalNotes: string,
  ): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.put(
          `${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/pending_broker_charges/${pendingChargeID}/approve_pending_broker_charge`,
          {
            notesForCarrier,
            internalNotes,
          },
        ),
      );
      this.lohiLoadCharges$$.refresh();
      this.charges$$.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async denyPendingCharge(
    loadId: string,
    pendingChargeID: number,
    notesForCarrier: string,
    internalNotes: string,
  ): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.put(
          `${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/pending_broker_charges/${pendingChargeID}/deny_pending_broker_charge`,
          {
            notesForCarrier,
            internalNotes,
          },
        ),
      );
      this.lohiLoadCharges$$.refresh();
      this.charges$$.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async approvePendingChargeUpdate(
    loadId: string,
    pendingUpdateId: number,
    notesForCarrier: string,
    internalNotes: string,
  ): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.put(
          `${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/pending_broker_charge_updates/${pendingUpdateId}/approve_pending_broker_charge_update`,
          {
            notesForCarrier,
            internalNotes,
          },
        ),
      );
      this.lohiLoadCharges$$.refresh();
      this.charges$$.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async denyPendingChargeUpdate(
    loadId: string,
    pendingUpdateId: number,
    notesForCarrier: string,
    internalNotes: string,
  ): Promise<boolean> {
    try {
      await lastValueFrom(
        this.http.put(
          `${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/pending_broker_charge_updates/${pendingUpdateId}/deny_pending_broker_charge_update`,
          {
            notesForCarrier,
            internalNotes,
          },
        ),
      );
      this.lohiLoadCharges$$.refresh();
      this.charges$$.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }

  public getReadyForDispatchDetails$ = (loadId: string, poolId: number) => {
    return this.http.get<ReadyForDispatch>(
      `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolId}/${loadId}/ready_for_dispatch`,
    );
  };

  public async markLoadReadyV2(loadId: string, poolId: number, readyStatusData: any) {
    try {
      await lastValueFrom(
        this.http.post<void>(
          `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolId}/${loadId}/ready_for_dispatch`,
          readyStatusData,
        ),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public getPoolFacilities$ = (poolId: number) => {
    return this.http
      .get<{ facilities: FacilityInPool[] }>(
        `${environment.api}/v2/vpf/internal_or_external_dispatcher/dshrut/pools/${poolId}/facilities`,
      )
      .pipe(map((v) => sort(v?.facilities, (a, b) => a.name.localeCompare(b.name))));
  };

  public routeGuideSkipCarrier(loadId: string) {
    const reason = 'carrier skip current broker to give it to next one';
    return this.http.post<void>(`${environment.api}/v2/load/route_guide/${loadId}/skip_current_carrier`, {
      reason: reason,
    });
  }

  public async pardonLoad(loadId: string, value: { pardonType: string; notes: string }) {
    try {
      await lastValueFrom(
        this.http.post<void>(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/pardon_load`, value),
      );
      return true;
    } catch (e) {
      return false;
    }
  }

  public async uncompleteLoad(
    loadId: string,
    status: 'pending' | 'in_progress' | 'not_assigned',
    stopIdsToLeaveCompleted: number[],
  ) {
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/v2/internal/unfinalize_lll_load`, {
          loadId,
          status,
          stopIdsToLeaveCompleted,
        }),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async vpfExplainDeadhead(loadId: string): Promise<{ deadheadCents: number; explainer: string[] }> {
    try {
      const response = await lastValueFrom(
        this.http.get<{
          deadheadCents: number;
          explainer: string[];
        }>(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/vpf_explain_deadhead`),
      );
      return response;
    } catch {
      return {
        deadheadCents: 0,
        explainer: ['Server Call Failed 😢'],
      };
    }
  }

  public async vpfExplainLinehaul(loadId: string): Promise<{ linehaulCents: number; explainer: string[] }> {
    try {
      const response = await lastValueFrom(
        this.http.get<{
          linehaulCents: number;
          explainer: string[];
        }>(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/vpf_explain_linehaul`),
      );
      return response;
    } catch {
      return {
        linehaulCents: 0,
        explainer: ['Server Call Failed 😢'],
      };
    }
  }

  private async getLohiUploadedFileInfo(fileID: string): Promise<FileWithExtract<any>> {
    try {
      return await lastValueFrom(
        this.http.get<FileWithExtract<any>>(`${environment.api}/v2/max_payloop/dispatcher/loopi/files/${fileID}`),
      );
    } catch (e) {
      return null;
    }
  }

  public async extractBolAutomatically(fileID: number): Promise<BolExtract> {
    try {
      const extract = await lastValueFrom(
        this.http.post<BolExtract>(
          `${environment.api}/v2/max_payloop/dispatcher/loopi/files/${fileID}/extract_bol_automatically`,
          fileID,
        ),
      );
      this.lohiUploadedFileInfo$$?.refresh();
      return extract;
    } catch {
      return null;
    }
  }

  public async extractBolManually(form: {
    fileID?: number;
    bolNumber?: string;
    appointmentTime?: string;
    checkInTime?: string;
    checkOutTime?: string;
  }): Promise<BolExtract> {
    try {
      const extract = await lastValueFrom(
        this.http.post<BolExtract>(
          `${environment.api}/v2/max_payloop/dispatcher/loopi/files/${form.fileID}/extract_bol_manually`,
          form,
        ),
      );
      this.lohiUploadedFileInfo$$?.refresh();
      return extract;
    } catch {
      return null;
    }
  }

  private async getLoadRGDetails(loadID: string) {
    return await lastValueFrom(
      this.http.get<RGLoadDetail>(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadID}/route_guide_details`),
    );
  }

  private async getLoadCheckinDetails(loadID: string) {
    return await lastValueFrom(
      this.http.get<Record<number, CheckinDetails[]>>(
        `${environment.api}/v2/max_payloop/dispatcher/loopi/${loadID}/stop_checkins`,
      ),
    );
  }

  private async getLoadStopPickupValidations(loadID: string) {
    try {
      const response = await lastValueFrom(
        this.http.get<{ validations: Record<number, StopPickupValidation> }>(
          `${environment.api}/v2/max_payloop/dispatcher/loopi/${loadID}/stop_pickup_validations`,
        ),
      );
      return response.validations;
    } catch (e) {
      return null;
    }
  }

  private async getLoadAppointmentDetails(loadID: string) {
    return await lastValueFrom(
      this.http
        .get<{ appointments: Record<number, StopAppointmentDetails> }>(
          `${environment.api}/v2/max_payloop/dispatcher/loopi/${loadID}/stop_appointments`,
        )
        .pipe(map((res) => res.appointments)),
    );
  }

  public async queueLoadsForCancellation(loadIds: string[]) {
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/v2/max_payloop/dispatcher/loopi/queue_for_cancellation`, {
          loadIds,
        }),
      );
      return true;
    } catch (e) {
      return false;
    }
  }

  public async queueForCancellation(loadId: string) {
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/queue_for_cancellation`, {
          queue: true,
        }),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async unqueueCancellation(loadId: string) {
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/queue_for_cancellation`, {
          queue: false,
        }),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async markPriority(loadId: string, isPriority: boolean) {
    try {
      await lastValueFrom(
        this.http.post(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/mark_priority`, {
          isPriority,
        }),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async createTrailerBypass(analysisId: number, reason: string): Promise<string> {
    try {
      const result = await firstValueFrom(
        this.http.post<{ message: string }>(
          `${environment.api}/v2/max_payloop/dispatcher/loopi/colt_bypass/trailer_pickup`,
          {
            analysisId: analysisId,
            reason: reason,
          },
        ),
      );
      this.loadCheckinDetails.refresh();
      return result.message;
    } catch (error) {
      return null;
    }
  }

  public async getEapSummary(loadId: string): Promise<EapSummary> {
    try {
      const response = await lastValueFrom(
        this.http.get<{ eapSummary: EapSummary }>(
          `${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/eap_summary_details`,
        ),
      );
      return response.eapSummary;
    } catch (e) {
      return null;
    }
  }

  public async getCanAddEAP(loadId: string): Promise<boolean> {
    try {
      const response = await lastValueFrom(
        this.http.get<{ canAddEap: boolean }>(
          `${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/can_add_eap_to_load`,
        ),
      );
      return response.canAddEap;
    } catch (e) {
      return null;
    }
  }

  public async getBolAutomationData(loadId: string): Promise<Record<number, BOLMetadata>> {
    try {
      const response = await lastValueFrom(
        this.http.get<Record<number, BOLMetadata>>(
          `${environment.api}/v2/max_payloop/dispatcher/loopi/${loadId}/bol_automation_data`,
        ),
      );
      return response;
    } catch (e) {
      return null;
    }
  }

  public async setBrokerPriceCheck(loadId: string, decision: string, notes: string) {
    try {
      await lastValueFrom(
        this.http.post(
          `${environment.api}/v2/max_payloop/dispatcher/loopi/internal_billing_portal/set_broker_price_check`,
          {
            loadId,
            decision,
            notes,
          },
        ),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async setCarrierPriceQa(loadId: string, decision: string, notes: string) {
    try {
      await lastValueFrom(
        this.http.post(
          `${environment.api}/v2/max_payloop/dispatcher/loopi/internal_billing_portal/set_carrier_price_qa`,
          {
            loadId,
            decision,
            notes,
          },
        ),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async setCustomerPriceQa(loadId: string, decision: string, notes: string) {
    try {
      await lastValueFrom(
        this.http.post(
          `${environment.api}/v2/max_payloop/dispatcher/loopi/internal_billing_portal/set_customer_price_qa`,
          {
            loadId,
            decision,
            notes,
          },
        ),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async removeCustomerPriceQa(loadId: string) {
    try {
      await lastValueFrom(
        this.http.post(
          `${environment.api}/v2/max_payloop/dispatcher/loopi/internal_billing_portal/remove_customer_price_qa`,
          {
            loadId,
          },
        ),
      );
      this.refreshLoad();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async updateNotes(loadID: string, notes: string) {
    try {
      await lastValueFrom(
        this.http.put(`${environment.api}/v2/max_payloop/dispatcher/loopi/${loadID}/notes`, {
          notes,
        }),
      );
      return true;
    } catch (e) {
      return false;
    }
  }
}
