import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { InternalGuard } from './shared/guards/internal.guard';
import { DispatcherOrFranchiseGuard } from './shared/guards/dispatcher-or-franchise.guard';
import { InternalOrExternalDispatcherGuard } from './shared/guards/internal-or-external-dispatcher.guard';

const redirectUnauthorizedToLogin = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  if (state.url) {
    return redirectUnauthorizedTo(`auth?redirectTo=${state.url}`);
  }
  return redirectUnauthorizedTo([`auth`]);
};

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('src/app/modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('src/app/modules/internal/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AngularFireAuthGuard, DispatcherOrFranchiseGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'facility',
    loadChildren: () => import('src/app/modules/internal/facilities/facilities.module').then((m) => m.FacilitiesModule),
    canActivate: [AngularFireAuthGuard, InternalOrExternalDispatcherGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'loads',
    loadChildren: () => import('src/app/modules/internal/loads/loads.module').then((m) => m.LoadsModule),
    canActivate: [AngularFireAuthGuard, InternalOrExternalDispatcherGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'eaps',
    loadChildren: () => import('src/app/modules/internal/eap/eap.module').then((m) => m.EAPModule),
    canActivate: [AngularFireAuthGuard, InternalOrExternalDispatcherGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'settlements',
    loadChildren: () =>
      import('src/app/modules/carrier/settlement-statement/settlement-statement.module').then(
        (m) => m.SettlementStatementModule,
      ),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'carrier_leaderboard',
    loadChildren: () =>
      import('src/app/modules/carrier/carrier-leaderboard/carrier-leaderboard.module').then(
        (m) => m.CarrierLeaderboardModule,
      ),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'ro_settlement_statement',
    loadChildren: () =>
      import('src/app/modules/carrier/ro-settlement-statement/ro-settlement-statement.module').then(
        (m) => m.RoSettlementStatementModule,
      ),
  },
  {
    path: 'shipper_loads',
    loadChildren: () =>
      import('src/app/modules/internal/shipper-load/shipper-load.module').then((m) => m.ShipperLoadModule),
    canActivate: [AngularFireAuthGuard, InternalOrExternalDispatcherGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'shipment_plan',
    loadChildren: () =>
      import('src/app/modules/internal/shipment-plan/shipment-plan.module').then((m) => m.ShipmentPlanModule),
    canActivate: [AngularFireAuthGuard, InternalGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'dispatch_pools',
    loadChildren: () =>
      import('src/app/modules/internal/dispatch-pool/dispatch-pool.module').then((m) => m.DispatchPoolModule),
    canActivate: [AngularFireAuthGuard, InternalOrExternalDispatcherGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'load_disputes',
    loadChildren: () =>
      import('src/app/modules/internal/load-disputes/load-disputes.module').then((m) => m.LoadDisputesModule),
    canActivate: [AngularFireAuthGuard, InternalGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'disputed_events',
    loadChildren: () =>
      import('src/app/modules/internal/disputed-events/disputed-events.module').then((m) => m.DisputedEventsModule),
    canActivate: [AngularFireAuthGuard, InternalGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'drop_trailer',
    loadChildren: () =>
      import('src/app/modules/internal/drop-trailer/drop-trailer.module').then((m) => m.DropTrailerModule),
    canActivate: [AngularFireAuthGuard, InternalOrExternalDispatcherGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'carrier_list',
    loadChildren: () =>
      import('src/app/modules/internal/carrier-list/carrier-list.module').then((m) => m.CarrierListModule),
    canActivate: [AngularFireAuthGuard, InternalGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'route_guide',
    loadChildren: () =>
      import('src/app/modules/internal/route-guide/route-guide.module').then((m) => m.RouteGuideModule),
    canActivate: [AngularFireAuthGuard, InternalGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('src/app/modules/notification-management/notification-management.module').then(
        (m) => m.NotificationManagementModule,
      ),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'ratecon',
    loadChildren: () =>
      import('src/app/modules/carrier/fivef-ratecon-v2/fivef-ratecon-v2.module').then((m) => m.FivefRateconV2Module),
  },
  {
    path: 'readonly_ratecon',
    loadChildren: () =>
      import('src/app/modules/carrier/fivef-readonly-ratecon/fivef-readonly-ratecon.module').then(
        (m) => m.FivefReadonlyRateconModule,
      ),
  },
  {
    path: 'load_update_confirmation',
    loadChildren: () =>
      import('src/app/modules/carrier/load-update-confirmation/load-update-confirmation-routing.module').then(
        (m) => m.LoadUpdateConfirmationRoutingModule,
      ),
  },
  {
    path: 'carrier_users',
    loadChildren: () =>
      import('src/app/modules/carrier/carrier-users/carrier-users.module').then((m) => m.CarrierUsersModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'rates',
    loadChildren: () =>
      import('src/app/modules/carrier/carrier-rates/carrier-rates.module').then((m) => m.CarrierRatesModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'driver',
    loadChildren: () =>
      import('src/app/modules/carrier/carrier-driver-detail/carrier-driver-detail.module').then(
        (m) => m.CarrierDriverDetailModule,
      ),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'carrier_assets',
    loadChildren: () =>
      import('src/app/modules/carrier/carrier-assets/carrier-assets.module').then((m) => m.CarrierAssetsModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'carrier_kpis',
    loadChildren: () =>
      import('src/app/modules/carrier/carrier-kpis/carrier-kpis.module').then((m) => m.CarrierKpisModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'carrier_loads',
    loadChildren: () =>
      import('src/app/modules/carrier/carrier-loads/carrier-loads.module').then((m) => m.CarrierLoadsModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'carrier_pending_loads',
    loadChildren: () =>
      import('src/app/modules/carrier/carrier-pending-loads/carrier-pending-loads.module').then(
        (m) => m.CarrierPendingLoadsModule,
      ),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'carrier_completed_loads',
    loadChildren: () =>
      import('src/app/modules/carrier/carrier-completed-loads/carrier-completed-loads.module').then(
        (m) => m.CarrierCompletedLoadsModule,
      ),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'carrier_import_export',
    loadChildren: () =>
      import('src/app/modules/carrier/import-export/import-export.module').then((m) => m.ImportExportModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'street_turns',
    loadChildren: () =>
      import('src/app/modules/carrier/street-turns/street-turns.module').then((m) => m.StreetTurnsModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'carrier_eld_management',
    loadChildren: () => import('src/app/modules/carrier/eld/eld.module').then((m) => m.EldModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'carrier_hitches',
    loadChildren: () =>
      import('src/app/modules/carrier/carrier-hitch/carrier-hitch.module').then((m) => m.CarrierHitchModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'street_turn_internal_dashboard',
    loadChildren: () =>
      import('src/app/modules/internal/street-turns/street-turns.module').then((m) => m.StreetTurnsModule),
    canActivate: [AngularFireAuthGuard, InternalGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'carrier_settings',
    loadChildren: () =>
      import('src/app/modules/carrier/carrier-settings/carrier-settings.module').then((m) => m.CarrierSettingsModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'accounting',
    loadChildren: () => import('src/app/modules/internal/accounting/accounting.module').then((m) => m.AccountingModule),
    canActivate: [AngularFireAuthGuard, InternalGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'convoy',
    loadChildren: () => import('src/app/modules/internal/convoy/convoy.module').then((m) => m.ConvoyModule),
    canActivate: [AngularFireAuthGuard, InternalGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'carrier_contracts',
    loadChildren: () =>
      import('src/app/modules/internal/carrier-contracts/carrier-contracts.module').then(
        (m) => m.CarrierContractsModule,
      ),
    canActivate: [AngularFireAuthGuard, InternalGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'cpg_ufg',
    loadChildren: () => import('src/app/modules/carrier/cpg-ufg/cpg-ufg.module').then((m) => m.CpgUfgModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'dispatch_pool_invoicing',
    loadChildren: () =>
      import('src/app/modules/internal/dispatch-pool/dispatch-pool-invoicing/dispatch-pool-invoicing.module').then(
        (m) => m.DispatchPoolInvoicingModule,
      ),
    canActivate: [AngularFireAuthGuard, InternalOrExternalDispatcherGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: '**',
    redirectTo: 'auth',
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'auth',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
