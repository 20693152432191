/* eslint-disable rxjs/finnish */
import { ChangeDetectionStrategy, Component, ElementRef, Input, Output, ViewChild } from '@angular/core';
import { MappableComponent, SATELLITE } from '../mappable/mappable.component';
import {
  AbstractControl,
  ControlValueAccessor,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { GeoJSONSource, LngLatBounds } from 'mapbox-gl';
import { Feature, FeatureCollection, Polygon, Position } from 'geojson';
import { XYPoint } from '../../global-types';
import { centroid } from '@turf/centroid';
import { ActionEvent } from './dropdown.component';
import { BehaviorSubject, Observable } from 'rxjs';

interface PPolygon {
  id: string;
  coordinates: { x: number; y: number }[];
}

@Component({
  selector: 'td-polygonal-geofence-facility',
  templateUrl: './polygonal-geofence-facility.component.html',
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PolygonalGeofenceFacilityComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: PolygonalGeofenceFacilityComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolygonalGeofenceFacilityComponent extends MappableComponent implements ControlValueAccessor, Validator {
  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};
  private _layers = { facility: true, docks: true };
  private _polygons: { facility: PPolygon; docks: PPolygon[] } = { facility: null, docks: [] };
  private _type: 'facility' | 'docks' | null;
  private _activeLayer: 'facility' | 'docks' | null = null;
  private _action: 'create' | 'edit' | null = null;
  private draw: MapboxDraw;
  private _selectedPolygonsSubject = new BehaviorSubject<string[]>([]);
  public selectedPolygons = this._selectedPolygonsSubject.asObservable();
  private _selectedPointsSubject = new BehaviorSubject<Position[]>([]);
  private selectedPoints = this._selectedPointsSubject.asObservable();
  private tempSelectedPolygons$: Observable<string[]>;
  private _facilitySubject = new BehaviorSubject<XYPoint[]>(null);
  private _docksSubject = new BehaviorSubject<XYPoint[][]>(null);

  @Output() public facilityChange = this._facilitySubject.asObservable();
  @Output() public docksChange = this._docksSubject.asObservable();

  @ViewChild('map', { static: true }) public mapElement: ElementRef;
  @ViewChild('dropdown', { static: false }) public dropdownElement: ElementRef;
  @ViewChild('dropdownButton', { static: false }) public dropdownBtnElement: ElementRef;

  public changeVisibility(layer: 'facility' | 'docks') {
    this._layers[layer] = !this._layers[layer];
    let l = this.map.getLayoutProperty(`${layer}-layer`, 'visibility');
    this.map.setLayoutProperty(`${layer}-layer`, 'visibility', this._layers[layer] ? 'visible' : 'none');
  }

  public get layers() {
    return this._layers;
  }

  @Input() public form: FormGroup;

  @Input()
  public set facility(p: XYPoint[]) {
    this._polygons.facility = { id: 'facility', coordinates: p };
    this._facilitySubject.next(this._polygons.facility.coordinates);
  }

  @Input()
  public set docks(p: XYPoint[][]) {
    this._polygons.docks = p && p.map((coords, idx) => ({ id: `dock-${idx}`, coordinates: coords }));
    p && this._docksSubject.next(this._polygons.docks.map((d) => d.coordinates));
  }

  public updateDocks(p: PPolygon[]) {
    this._polygons.docks = p;
    this._docksSubject.next(p.map((d) => d.coordinates));
  }

  public updateFacility(p: PPolygon) {
    this._polygons.facility = p;
    this._facilitySubject.next(p.coordinates);
  }

  public get facilityValue() {
    return this._polygons.facility;
  }

  public get docksValue() {
    return this._polygons.docks;
  }

  public get createOptions() {
    return [
      { label: 'Create Dock', value: 'docks' },
      ...(this._polygons.facility ? [] : [{ label: 'Create Facility', value: 'facility' }]),
    ];
  }

  public get editOptions() {
    return [
      ...(this._polygons.docks && this._polygons.docks.length > 0 ? [{ label: 'Edit Dock', value: 'docks' }] : []),
      ...(this._polygons.facility ? [{ label: 'Edit Facility', value: 'facility' }] : []),
    ];
  }

  public get deleteOptions() {
    return [
      ...(this._selectedPointsSubject.value && this._selectedPointsSubject.value.length > 0
        ? [{ label: 'Delete Point', value: 'point' }]
        : []),
      ...(this._selectedPolygonsSubject.value && this._selectedPolygonsSubject.value.length > 0
        ? [{ label: 'Delete Polygon', value: 'polygon' }]
        : []),
    ];
  }

  constructor() {
    super(SATELLITE);
  }

  public get editing() {
    return this._action == 'edit';
  }

  public triggerPolygon({ type, value }: ActionEvent) {
    this._action = value as 'create' | 'edit';
    value === 'create' && this.triggerCreatePolygon(type as 'facility' | 'docks');
    value === 'edit' && this.triggerEditPolygon(type as 'facility' | 'docks');
  }

  public toggleEdit() {
    const newSourceData: FeatureCollection<Polygon> = {
      type: 'FeatureCollection',
      features: [],
    };

    const d = this.map.getSource(`${this.activeLayer}-layer`) as GeoJSONSource;
    newSourceData.features = this.mapPolygons(
      this.activeLayer === 'facility' ? [this._polygons.facility] : this._polygons.docks,
    );
    d.setData(newSourceData);
    this._action = null;
    this._selectedPolygonsSubject.next([]);
    this.draw.deleteAll();
    this._activeLayer = null;
    this._layers = { facility: true, docks: true };

    this.map.setLayoutProperty('facility-layer', 'visibility', 'visible');
    this.map.setLayoutProperty('docks-layer', 'visibility', 'visible');
  }

  public remove(e: ActionEvent) {
    e.type === 'polygon' && this.removePolygon();
    e.type === 'point' && this.removePoint();
  }

  public removePoint() {
    let features = [];
    if (this._activeLayer === 'facility') {
      this._polygons.facility = this.removePointFromPolygon(this._polygons.facility);
      features = this.mapPolygons([this._polygons.facility]);
      this.updateFacility(this._polygons.facility);
    } else {
      this._polygons.docks = this._polygons.docks.map((p) => {
        if (this._selectedPolygonsSubject.value.includes(p.id)) {
          p = this.removePointFromPolygon(p);
        }

        return p;
      });
      this.updateDocks(this._polygons.docks);
      features = this.mapPolygons(this._polygons.docks);
    }

    this.draw.set({ type: 'FeatureCollection', features: features });
  }

  private removePointFromPolygon(p: PPolygon) {
    p.coordinates = p.coordinates.filter(({ x, y }) => {
      let dontRemove = true;
      for (let i = 0; i < this._selectedPointsSubject.value.length; i++) {
        if (this._selectedPointsSubject.value[i][0] === x && this._selectedPointsSubject.value[i][1] === y) {
          dontRemove = false;
          break;
        }
      }
      return dontRemove;
    });
    p.coordinates.push(p.coordinates[0]);
    return {
      ...p,
      coordinates: p.coordinates,
    };
  }

  public removePolygon() {
    this._action = null;
    const sourceData = this.map.getSource(`${this._activeLayer}-layer`) as GeoJSONSource;
    if (this._activeLayer === 'docks') {
      this.updateDocks(this._polygons.docks.filter((p) => !this._selectedPolygonsSubject.value.includes(p.id)));
      sourceData.setData({
        type: 'FeatureCollection',
        features: this._polygons.docks.flatMap((polys, idx) => this.mapPolygonToFeature(polys)),
      });
    } else if (this._activeLayer === 'facility') {
      this.updateFacility(null);
      sourceData.setData({ type: 'FeatureCollection', features: [] });
    }

    this._selectedPolygonsSubject.next([]);
    this.draw.deleteAll();
    this._activeLayer = null;
    this._layers = { facility: true, docks: true };
    this.map.setLayoutProperty('facility-layer', 'visibility', 'visible');
    this.map.setLayoutProperty('docks-layer', 'visibility', 'visible');
  }

  public triggerDeletePolygon(type: 'facility' | 'docks') {}

  public triggerCreatePolygon(type: 'facility' | 'docks') {
    this._action = 'create';
    this.enableLayerEdit(type);

    if (type === 'docks') {
      this.setPolygonColor('#FF0000');
    } else {
      this.setPolygonColor('#0080ff');
    }
    this.draw.changeMode('draw_polygon');
  }

  public triggerEditPolygon(type: 'facility' | 'docks') {
    this.enableLayerEdit(type);

    this.map.setLayoutProperty(`${type}-layer`, 'visibility', 'none');
    const features: Feature<Polygon>[] = this.mapPolygons(
      type === 'facility' ? [this._polygons.facility] : this._polygons.docks,
    );

    this.draw.deleteAll();
    this.draw.add({
      type: 'FeatureCollection',
      features: features,
    });

    this.draw.changeMode('simple_select');
    this.setPolygonColor(type === 'facility' ? '#FF0000' : '#0080ff');
  }

  public get activeLayer() {
    return this._activeLayer;
  }

  private enableLayerEdit(type: 'facility' | 'docks' | null) {
    this._type = type;
    this._activeLayer = type;
    this._layers = { facility: type === 'facility', docks: type === 'docks' };
    this.map.setLayoutProperty('facility-layer', 'visibility', this.layers.facility ? 'visible' : 'none');
    this.map.setLayoutProperty('docks-layer', 'visibility', this.layers.docks ? 'visible' : 'none');
  }

  private setPolygonColor(color: string) {
    if (this.map.getLayer('gl-draw-polygon-fill-inactive')) {
      this.map.setPaintProperty('gl-draw-polygon-fill-inactive', 'fill-color', color);
    }
    if (this.map.getLayer('gl-draw-polygon-fill-active')) {
      this.map.setPaintProperty('gl-draw-polygon-fill-active', 'fill-color', color);
    }
  }

  private addPolygon(type: 'facility' | 'docks', polygon: any) {
    if (type === 'facility' && !this._polygons[type]) {
      this._polygons[type] = null;
    } else if (!this._polygons[type]) {
      (this._polygons[type] as PPolygon[]) = [];
    }

    if (type === 'facility') {
      this.updateFacility(polygon);
    } else {
      this._polygons.docks.push({
        id: `dock-${this._polygons.docks.length}`,
        coordinates: polygon as { x: number; y: number }[],
      });
      this.updateDocks(this._polygons.docks);
    }
  }

  private mapPolygons(polys: PPolygon[]): Feature<Polygon>[] {
    if (!polys || !Array.isArray(polys)) {
      console.error('Invalid polys array:', polys);
      return [];
    }
    return polys.map((p) => {
      return {
        id: p.id,
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [p.coordinates.map(({ x, y }) => [x, y] as Position)],
        },
        properties: {},
      };
    });
  }
  private mapPolygonToFeature(polygon: PPolygon): GeoJSON.Feature<GeoJSON.Polygon> | null {
    if (!polygon || !polygon.coordinates || polygon.coordinates.length === 0) {
      console.error('Invalid polygon:', polygon);
      return null;
    }

    return {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [polygon.coordinates.map(({ x, y }) => [x, y] as Position)],
      },
      properties: {},
    };
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {}

  public writeValue(obj: any): void {}

  public override onStyleLoad() {
    super.onStyleLoad();
    if (!this.draw) {
      this.draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {},
      });

      this.map.addControl(this.draw);
    }

    this.map.addSource('facility-layer', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            id: 'facility',
            type: 'Feature',
            properties: { id: 'facility' },
            geometry: {
              type: 'Polygon',
              coordinates: [this._polygons.facility.coordinates.map(({ x, y }) => [x, y] as Position)],
            },
          },
        ],
      },
      promoteId: 'facility',
    });

    this.map.addLayer({
      id: 'facility-layer',
      type: 'fill',
      layout: {
        visibility: 'visible',
      },
      source: 'facility-layer',
      paint: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'fill-color': '#0080ff',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'fill-opacity': 0.5,
      },
    });

    this.map.addSource('docks-layer', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: this.mapPolygons(this._polygons.docks),
      },
    });

    this.map.addLayer({
      id: 'docks-layer',
      type: 'fill',
      source: 'docks-layer',
      layout: {
        visibility: 'visible',
      },
      paint: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'fill-color': '#FF0000',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'fill-opacity': 0.5,
      },
    });

    this.map.on('draw.selectionchange', this.handlePolygonSelect.bind(this));
    this.map.on('draw.create', this.handlePolygonCreate.bind(this));
    this.map.on('draw.update', this.handlePolygonUpdate.bind(this));

    this.updateBounds();
  }

  public registerOnValidatorChange(fn: () => void): void {
    // do nothing
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value as XYPoint[];
    if (!value) {
      return null;
    }
    if (value.length < 4) {
      return {
        polygon: 'Polygon must have at least 3 points',
      };
    }
    if (value[0].x !== value[value.length - 1].x || value[0].y !== value[value.length - 1].y) {
      return {
        polygon: 'Polygon must be closed',
      };
    }
    return null;
  }

  private updateBounds() {
    if (this._polygons.facility.coordinates.length === 0) {
      return;
    }
    const center = centroid({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [this._polygons.facility.coordinates.map(({ x, y }) => [x, y])],
      },
    });
    this.map.setCenter(center.geometry.coordinates as any);
    const bounds = new LngLatBounds();
    this._polygons.facility.coordinates.forEach(({ x, y }) => {
      bounds.extend([x, y]);
    });
    this.map.fitBounds(bounds, {
      padding: 100,
    });
  }

  private handlePolygonSelect(e) {
    if (this._action === 'edit') {
      const selectedIds = e.features ? e.features.map((f) => f.id as string) : [];
      this._selectedPolygonsSubject.next(selectedIds);
      const selectedPoints = e.points ? e.points.map((f) => f.geometry.coordinates) : [];
      this._selectedPointsSubject.next(selectedPoints);
    }
  }

  private handlePolygonUpdate(e?: any) {
    if (this.activeLayer && e && e.features && e.features.length > 0) {
      const id = e.features[0].id as string;
      const coordinates = e.features[0].geometry.coordinates[0];

      if (this._activeLayer === 'facility') {
        this.updateFacility({ id, coordinates: coordinates.map(([x, y]) => ({ x, y })) });
      } else {
        const dockIdx = this._polygons.docks.findIndex((p) => p.id === id);
        if (dockIdx !== -1) {
          this._polygons.docks[dockIdx] = { id, coordinates: coordinates.map(([x, y]) => ({ x, y })) };
          this.updateDocks(this._polygons.docks);
        }
      }
    }
  }

  private handlePolygonCreate(e?: any) {
    if (this.activeLayer && e && e.features && e.features.length > 0) {
      const coordinates = e.features[0].geometry.coordinates[0];
      const newSourceData: FeatureCollection<Polygon> = {
        type: 'FeatureCollection',
        features: [],
      };

      this.addPolygon(
        this._type,
        coordinates.map(([x, y]) => ({ x, y })),
      );

      newSourceData.features = this.mapPolygons(this._polygons.docks);

      this._activeLayer = null;
      this._layers = { facility: true, docks: true };
      const d = this.map.getSource('docks-layer') as GeoJSONSource;

      d.setData(newSourceData as any);
      this.map.setLayoutProperty('facility-layer', 'visibility', 'visible');
      this.map.setLayoutProperty('docks-layer', 'visibility', 'visible');
      this.draw.deleteAll();
    }
  }
}
