import { FormControl, FormGroup } from '@angular/forms';
import { CompletedLoadsSortables } from '../modules/carrier/carrier-loads/carrier-loads.service';
import { SpecialRequirement } from './services/constants.service';
import { DisputeStatus, TierType } from '../modules/internal/dashboard/interfaces';
import { PaymentLoadingTypePipe } from './pipes/payment-loading-type.pipe';

export type LoadStatus =
  | 'unassigned'
  | 'assigned'
  | 'on_hold'
  | 'awaiting_signature'
  | 'en_route'
  | 'at_stop'
  | 'at_risk'
  | 'on_track'
  | 'completed';

export type FileUploadType = 'rate_con' | 'bol_image' | 'other_image' | 'ticket_image' | 'in_gate' | 'out_gate';

export type DismissReasonType =
  | 'rate'
  | 'comments'
  | 'pickup_appt'
  | 'carrier_broker_relationship'
  | '5f_broker_relationship'
  | 'no_longer_available'
  | 'unable_contact_broker'
  | 'pickup_time'
  | 'other'
  | 'broker_will_not_work_with_carrier';

export interface LongLat {
  longitude: number;
  latitude: number;
}

export type TrailerType =
  | 'reefer'
  | 'dry_van'
  | 'power_only'
  | 'flatbed'
  | 'hopper'
  | 'pneumatic'
  | 'belly_dump'
  | 'end_dump'
  | 'straight_box'
  | 'intermodal_chassis'
  | 'tanker'
  | 'stepdeck'
  | 'low_boy'
  | 'conestoga'
  | '40_foot_high_cube_container'
  | '20_foot'
  | '40_foot'
  | '40_foot_refrigerated_container'
  | '40_foot_refrigerated_high_cube_container'
  | '45_foot_high_cube_container'
  | '40_foot_non_operating_reefer'
  | 'dry_van_48_foot'
  | 'dry_van_53_foot_costco';

export const TrailerTypesChoices: TrailerType[] = [
  'reefer',
  'dry_van',
  'power_only',
  'flatbed',
  'hopper',
  'pneumatic',
  'belly_dump',
  'end_dump',
  'straight_box',
  'intermodal_chassis',
  'tanker',
  'stepdeck',
  'low_boy',
  'conestoga',
  '40_foot_high_cube_container',
  '20_foot',
  '40_foot',
  '40_foot_refrigerated_container',
  '40_foot_refrigerated_high_cube_container',
  '45_foot_high_cube_container',
  '40_foot_non_operating_reefer',
  'dry_van_48_foot',
  'dry_van_53_foot_costco',
];

export interface TourNextData {
  endTime?: string;
  nextString?: string;
  startTime?: string;
  stringBoldIndexRanges: number[][];
}

export type TaskActionV2 =
  | 'pickup'
  | 'dropoff'
  | 'backhaul_pickup'
  | 'backhaul_dropoff'
  | 'unknown'
  | 'boxPickup'
  | 'other'
  | 'assetPickup'
  | 'assetDropoff'
  | 'return';

export interface TaskV2 {
  action: TaskActionV2;
  product: {
    name: string;
    unit: {
      name: string;
      abbreviation: string;
    };
  };
  quantity: number;
  actualQuantity?: number;
  bolNumber?: string;
  poNumber?: string;
}

export interface StopV2 {
  locationName: string;
  ownerName: string;
  radius: number;
  longitude: number;
  latitude: number;
  entranceLongitude: number;
  entranceLatitude: number;
  tasks: TaskV2[];
  directions?: string;
  completed: boolean;
  estimatedMinutesTo?: number;
  estimatedMinutesAt?: number;
  firstTimeAtStop: boolean;
  longestPreviousVisitMinutes?: number;
  hseRequirements?: string;
  pickupNumber?: string;
  trailerNumber?: string;
}

export type Source = 'sandi' | 'hauli' | 'reload' | 'lohi';

export interface ProductLoadUpload {
  id: string;
  loadId: string;
  fileName: string;
  uploadedBy: string;
  uploadedTimestamp: string;
  category: string;
  bucketName: string;
  shareableUrl: string;
  passedQA?: boolean;
  qaRejectedReason?: string;
}

export interface ProductLoad {
  customerName: string;
  id: string;
  uniqueId: string;
  source: Source;
  needsConfirmation: boolean;
  description: string;
  truckName: string;
  trailerName: string;
  canUploadDocuments: boolean;
  canEditOrder: boolean;
  canCompleteManually: boolean;
  hasTonuCharge: boolean;
  stops: StopV2[];
  uploads: ProductLoadUpload[];
  acceptedTimestamp: string;
  completedTimestamp: string;
  driverName: string;
  driverPhone: string;
  estimatedDurationMinutes: number;
  dispatchTimestamp: string;
  waypointLongitude: number;
  waypointLatitude: number;
  waypointTimestamp: string;
  eta: number;
  etaStatus: string;
  endOfShiftInfo?: {
    estHosRemainingAfterLoadCompleteMinutes: number;
    recommendedOvernightLocation: {
      id: string;
      name: string;
      category: string;
      latitude: string;
      longitude: string;
      travelDistanceMiles: string;
      travelDistanceMinutes: string;
      amenities: string[];
    }[];
  };
  estimatedMileage?: number;
  estimatedOrderTotalCents?: number;
  adjustedEstimatedOrderTotalCents?: number;
  likelyLastLoadOfShift: boolean;
  partialLoad: boolean;
  ticketNumber?: string;
  minutesLeftOnShiftAfterLoad: number;
  pickupMinutesUpdatedAgo?: number;
  dropoffTrucksOnLocation?: number;
  deadheadMiles?: number;
  loadedMiles?: number;
  leaderboardPoints?: {
    points: number;
    pointsModifier: number;
    pointText: string;
    modifierText: string;
  };
  minutesToAccept: number;
  loadWeightRestriction?: number;
  disptachPitches: string[];
  skipDocumentUpload: boolean;
  approvedAt?: string;
  ticketAge?: number;
  pricingEvents: {
    title: string;
    subtitle: string;
    estPrice: string;
  }[];
  earnings?: {
    linehaulTotal: number;
    deadheadTotal: number;
    unloadingDetentionTotal: number;
    loadingDetentionTotal: number;
    detentionTotal: number;
    accessorialTotal: number;
    bonusTotal: number;
    fuelTotal: number;
    totalAmount: number;
    cut: number;
    excludedChargeTypes: string[];
  };
  ffRewardsOrderData?: {
    points: number;
    description: string;
    completeBy: string;
  };
  billingInfo?: {
    needsAdditionalInfo: string[];
    needsAdditionalUpload: string[];
    isDisputed: boolean;
    isAudited: boolean;
    isInvoiced: boolean;
    isReadyForInvoicing: boolean;
    paidAt: string;
    billingAction: string;
    carrierPaidBy5F: boolean;
    carrierSubmitted?: string;
    carrierFunded?: string;
    billingTeamApproved?: string;
  };
  urgentInfo: {
    urgent: boolean;
    urgentRejectEndsShift: boolean;
    urgentRejectDriverPauseMinutes: number;
    urgentRejectText: string;
    urgentUnassignText: string;
  };
  referenceNumber?: string;
}

export interface Unit {
  id: number;
  name: string;
  abbreviation: string;
}

export type CoveredLoadStopTimeType = 'scheduled' | 'actual' | 'estimated';
export type LohiLoadStatus =
  | 'pending'
  | 'in_progress'
  | 'completed'
  | 'cancelled'
  | 'not_assigned'
  | 'at_yard'
  | 'asset_available'
  | 'claimed'
  | 'unclaimed';
export type DrayageStatus =
  | 'in_storage'
  | 'at_delivery'
  | 'not_arrived_at_port'
  | 'no_port_arrival_date_set'
  | 'available_for_pre_pull'
  | 'ready_for_delivery'
  | 'ready_for_ingate'
  | 'enroute'
  | 'needs_documentation';
export type StopType =
  | 'pickup'
  | 'dropoff'
  | 'empty_asset_pickup'
  | 'empty_asset_dropoff'
  | 'loaded_asset_storage_dropoff'
  | 'loaded_asset_storage_pickup';

export enum LoadingType {
  live = 'live',
  drop = 'drop',
}

export enum SupportedLoadingType {
  live = 'live',
  drop = 'drop',
  liveDrop = 'live_drop',
}

export type CarrierContactType = 'admin' | 'driver' | 'internal_dispatcher' | 'owner' | '3rd_party_dispatcher';
export type CompetitionMetric =
  | 'totalDriversManaged'
  | 'totalRevenueCents'
  | 'averageRevenueCentsPerDriver'
  | 'averageRatePerMileCents'
  | 'averageChatResponseTime'
  | 'assetUtilization'
  | 'totalLoadsBooked'
  | 'averageRecommendationRanking'
  | 'revenuePerDayCents';

export interface LohiLoadStopApptRequest {
  id: string;
  requestedTimes: {
    id: number;
    time: string;
  }[];
}

export interface LohiLoadAppointmentCancellations {
  id: string;
  reason: string;
  cancelledWindowStartTime: string;
  cancelledWindowMinutes: number;
}

export interface LohiLoadStop {
  id: number;
  loadId: string;
  type: StopType;
  loadingType: LoadingType;
  attachEmptyTrailerPickup: boolean;
  hasFacilityInfoResultGenerated: boolean;
  sequence: number;
  lngLat: { x: number; y: number };
  title: string;
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  phone?: string;
  arrivalWindowStartsAt: string;
  originalArrivalWindowStartsAt: string;
  arrivalWindowMinutes: number;
  arrivalWindowStartsAtExtrema?: string;
  arrivalWindowEndsAtExtrema?: string;
  details?: string;
  directions?: string;
  commodity: string;
  quantity: number;
  unit: Unit;
  referenceNumber?: string;
  detentionFreeMinutes?: number;
  completedAt?: string;
  appointmentTrackingNumber?: string;
  eta: string;
  etaUpdatedAt: string;
  facilityId: string;
  facilityName: string;
  facilityCustomerReferenceNumber: string;
  facilityExternalId: string;
  facilityFCFS: boolean;
  facilityApptRequired: boolean;
  timeZone: { name: string; abbreviation: string };
  arrivedAt?: string;
  exitedAt?: string;
  expectedAt?: string;
  isFCFS: boolean;
  isCPG?: boolean;
  isDrayage?: boolean;
  appointmentRequest: LohiLoadStopApptRequest;
  appointmentCancellations: LohiLoadAppointmentCancellations[];
  productId: string | null;
  emptyTrailerPickupId: number | null;
  emptyTrailerPickupName: string | null;
  isNotCheckedin: boolean;
}

export interface LohiLoad {
  id: string;
  createdAt: string;
  updatedAt: string;
  status: LohiLoadStatus;
  companyId: string;
  companyName: string;
  companyMCNumber: string;
  companyDOTNumber: string;
  driverId: string;
  driverName: string;
  driverPhone: string;
  driverLastWaypointUpdateAt: string;
  driverLastWaypointLngLat: NullableXYPoint;
  shipper: string;
  shipperId?: string;
  broker?: string;
  brokerId?: string;
  brokerName?: string;
  brokerEmailId?: number;
  rateCents: number;
  rateCentsPerMile?: number;
  weightPounds?: number;
  distanceMiles?: number;
  tempFahrenheit?: number;
  details?: string;
  shippingComments?: string;
  cargoValueCents?: number;
  tripMileage: number;
  deadheadMiles: number;
  actualQuantity?: number;
  completedAt?: string;
  adjustedCompletedAt?: string;
  detentionRateCents: number;
  brokerEmail?: string;
  referenceNumber?: string;
  trailerDisplayName: string;
  brokerNotificationMinutes: number;
  durationMinutes: number;
  stops: LohiLoadStop[];
  // @deprecated being deprecated soon:
  trailerTypes: {
    id: string;
    name: string;
  }[];
  possibleTrailerTypes: {
    id: string;
    name: string;
  }[];
  trailerType?: TrailerType;
  files: LohiUploadedFile[];
  shipperLoadId?: string;
  shipperSource?: string;
  shipperRateCents?: number;
  rateOnFileCents?: number;
  acknowledgedAt?: string;
  acknowledgedBy?: string;
  acknowledgedByName?: string;
  creatorCustomerName?: string;
  creatorCustomerEmail?: string;
  creatorCustomerPhone?: string;
  createdByUserName?: string;
  creatorCustomerType?: 'agent' | 'shipper' | 'broker';
  customerSpecificDetails: Record<string, any>;
  accessorials: { id: number; name: string }[];
  truckNumber?: string; //todo make sure this is consistently supplied, it is not right now
  trailerNumber?: string; //todo make sure this is consistently supplied, it is not right now
  hasCarrierAssignmentRequest: boolean;
  isAwaitingRateConSignature: boolean;
  loadBillingApprovedAt: string;
  carrierPaidBy5F: boolean;
  carrierSubmittedForPayment?: string;
  billingTeamApproved?: string;
  readyToCreateNetsuiteInvoiceAt?: string;
  billingNotes?: string;
  carrierFunded?: string;
  isPaid: boolean;
  containerNumber?: string;
  containerNumber2?: string;
  sealNumber?: string;
  customerBrokerSubsidiaryId?: string;
  isHotPriorityLoad: boolean;
  isAutoDispatchable: boolean;
  isCPG: boolean;
  isDrayage: boolean;
  specialHandling: 'import' | 'export';
  enableDupeDrayageFields: boolean;
  storageStopRequired: boolean;
  isMatchback: boolean;
  drayageStatus: string;
  vesselNumber?: string;
  voyageNumber?: string;
  bookingNumber?: string;
  bookingNumber2?: string;
  possibleShippingLines?: string[];
  customerLoadNumber?: string;
  earliestPickupAt?: string;
  originalRtl?: string;
  latestPickupAt?: string;
  lastFreePickupAt?: string;
  shippingLine?: string;
  shippingLine2?: string;
  railBillingConfirmationNumber?: string;
  reservationConfirmationNumber?: string;
  useAlmChassis: boolean;
  chassisNumber?: string;
  coverageStrategyOverride: CoverageStrategy | null;
  charges: {
    id: number;
    chargeInCents: number;
    chargeType: string;
    carrierQuantity: number;
    customerQuantity: number;
    perUnitCentsCarrier: number;
    perUnitCentsCustomer: number;
    rateType: string;
    displayName: string;
    matchbackCarrierCents: number;
  }[];
  drayageCoverageStrategies: LoadCoverageStrategyDetails[];
  earliestReceiptTime?: string;
  documentationCutoffTime?: string;
  portCutoffTime?: string;
  rampCutoffTime?: string;
  drayageReadyToLoadAt: string;
  drayageReadyToLoadBy: string;
  drayageIsAutoScrapedReadyToLoad: boolean;
  loadRateConPaymentStatus: LoadRateConPaymentStatus;
  allowVPFAutodispatchLock: boolean;
  vpfAutodispatchLocked: boolean;
  poolId: number;
  isEmptyTrailerMove: boolean;
  specialRequirements: SpecialRequirement[];
  unableToUnFinalizeReason: string;
  routeGuideLaneID: number;
  isUFGv3Assigned: boolean;
}

export interface LoadCoverageStrategyDetails {
  strategy: CoverageStrategy;
  recommended: boolean;
  reason?: string;
  override?: boolean;
  overrideBy?: string;
  overrideAt?: string;
  estimatedPriceCents: null | number;
  storedPriceCents: null | number;
}

export interface ShipperLoadStatus {
  status: LoadStatus;
  panic: boolean;
  atAction?: string;
  at?: string;
  eta: string;
  atStop?: number;
}

export interface LoadChargePendingUpdate {
  id: number;
  chargeId: number;
  requestedCarrierQuantity: number;
  requestedPerUnitCentsCarrier: number;
  createdByName: string;
  createdAt: string;
  status: null | 'approved' | 'denied';
  decisionMadeByName: string;
  decisionSupportingText: string;
  notesFromCarrier: string;
}

export interface LoadCharge {
  id: number;
  loadID: string;
  chargeType: string;
  name: string;
  totalCents: number;
  shipperTotalCents?: number;
  carrierTotalCents?: number;
  chargeTypeNameId?: number;
  chargeTypeNameIdName?: string;
  requiresUpload?: boolean;
  contractRateType: string;
  carrierQuantity: number;
  customerQuantity: number;
  carrierCentsPerUnit: number;
  customerCentsPerUnit: number;
  pendingUpdate?: LoadChargePendingUpdate;
  isEDICustomerOnlyCharge: boolean;
  explanation: string[];
}

export interface PendingOtrLoadChargeUpload {
  id: number;
  fileName: string;
  createdAt: string;
  url: string;
}

export interface PendingOTRLoadCharge {
  id: number;
  loadId: string;
  chargeType: string;
  chargeTypeId: number;
  chargeTypeName: string;
  carrierTotalCents: number;
  carrierQuantity: number;
  carrierCentsPerUnit: number;
  status: null | 'approved' | 'denied';
  notesFromCarrier: string;
  requestedByName: string;
  decisionBy: string;
  decisionSupportingText: string;
  uploads: PendingOtrLoadChargeUpload[];
  requiresUpload: true;
}

export interface LoadChargeDetails {
  charges: LoadCharge[];
  pendingCharges: PendingOTRLoadCharge[];
  platformFeeShipper: number;
  platformFeePercent: number;
  shipperTotal: number;
  carrierTotal: number;
}

export interface LohiUploadedFile {
  id: number;
  name: string;
  destination: string;
  url: string;
  category?: string;
  archivedAt?: string;
  archivedBy?: string;
  uploadedAt: string;
  uploadedBy?: string;
  uploadedByName?: string;
  passedQa?: boolean;
  qaAt?: string;
  qaBy?: string;
  qaRejectedReason?: string;
}

export interface ReloadLoadOrLohiLoad {
  reload?: ProductLoad;
  lohi?: LohiLoad;
  shipperLoadStatus: ShipperLoadStatus;
  rateConSignatureInformation?: RateConSignatureInformation | null;
  lamTrailer?: {
    id: number;
    name: string;
    companyId: string;
    companyName: string;
  };
  allowLAMTrailerSelection: boolean;
  allowSendToBrokerage: boolean;
  allowRemoveFromBrokerage: boolean;
  queuedForCancellation: boolean;
  priorityDetail?: {
    isPriority: boolean;
    priorityType: boolean;
    timedEndsAt: string;
  };
}

export interface RateConSignatureInformation {
  rateConSignedAt: string;
  rateConSignedBy: string;
}

// booking a load interfaces
export interface BrokerEmail {
  brokerId: string;
  email: string;
  id: number;
  contact?: {
    brokerContactId: number;
    brokerEmailId: number;
    agentName: string;
    agentPhone: string;
  };
}

export interface Broker {
  name: string;
  id: string;
  emails: BrokerEmail[];
}

export interface Equipment {
  id: string;
  name: string;
}

export interface XYPoint {
  id?: string;
  x: number;
  y: number;
}

export interface NullableXYPoint extends XYPoint {
  valid: boolean;
}

export interface MappableStop {
  sequence?: number | string;
  lngLat: XYPoint;
  color?: string;
  cityState?: string;
}

export type MarketHeatType = 'hot' | 'cold' | 'neutral';

export interface NegotiationProfile {
  trailerType: TrailerType;
  from: string;
  originMarketHeat?: MarketHeatType;
  to: string;
  destinationMarketHeat?: MarketHeatType;
  offerRateCents: number;
  targetRateCents: number;
  targetRateCeilingCents: number;
  lowerBoundCents: number;
  lowerBoundCentsPerMile: number;
  middleBoundCents: number;
  middleBoundCentsPerMile: number;
  upperBoundCents: number;
  upperBoundCentsPerMile: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  negotiationIdeas: { Message1: string; Message2: string }[];
  matchbackRateCents: number;
}

export interface LoHiTruck {
  truckId: string;
  truckName: string;
  inUse: boolean;
  assignedTo: string;
  assignedToPhone: string;
  truckOwner: string;
  lastUsed: LastUsed;
  maintenanceEvents: MaintenanceEvent[];
  truckLessee: string;
  truckStatus: string;
}

export interface LoHiLoadEditableInfo {
  bolNumber: string;
  actualQuantity: number;
  truckEnabled: boolean;
  truckAsset: LoHiTruck;
  trailerEnabled: boolean;
  trailerAsset: LoHiTrailer;
  assets: LohiAsset[];
  unit: {
    name: string;
    abbreviation: string;
  };
  returnTrailerEnabled: boolean;
  returnTrailer: string | null;
  ticketNumberEnabled: string | null;
  ticketNumber: string | null;
}

export interface LoHiTrailer {
  trailerId: string;
  trailerName: string;
  inUse: boolean;
  trailerOwner: string;
  trailerLessee: string;
  maintenanceEvents: MaintenanceEvent[];
  trailerStatus: string;
  lastUsed: LastUsed;
}

export interface LastUsed {
  lastUpdated: string;
  orderId: number;
  uniqueOrderId: string;
  lastLocation: number[];
  userPhone: string;
  userName: string;
  userId: string;
  lastUsed: LastUsed;
}

export interface LohiAsset {
  id: string;
  name: string;
  actualLoadWeight: number;
}

export interface MaintenanceEvent {
  createdAt: string;
  description: string;
}

export interface TourEarningsProgressReports {
  currentReport: TourEarningsProgressReport;
  initialReport: TourEarningsProgressReport;
  changeHistory: TourEarningsChangeHistory[];
}

export interface TourEarningsProgressReport {
  previousEarningsCents: number;
  inProgressEarningsCents: number;
  futureEarningsCents: number;
  createdAt: string;
}

export interface TourEarningsChangeHistory {
  totalEarningsChangeCents: number;
  createdAt: string;
  trigger: string;
}

export interface TimePeriod {
  startTime: string;
  endTime: string;
}

export interface CompletedOrdersRequest {
  limit: number;
  offset: number;
  sort: CompletedLoadsSortables;
  order: 'ASC' | 'DESC';
  search?: string;
  missingPOD?: string;
  missingPOD24Hrs?: string;
  source?: string;
}

export interface SlimDriver {
  id: string;
  phoneNumber: string;
  name: string;
  lastDispatched: string;
  hasSession: boolean;
}

export interface SlimAdmin extends SlimDriver {
  email: string;
}

export type ControlsOf<T extends Record<string, any>> = {
  [K in keyof T]: T[K] extends Record<any, any> ? FormGroup<ControlsOf<T[K]>> : FormControl<T[K]>;
};

export interface DriverGeneralPreferences {
  frequencyHomeChoiceID: number | null;
  homeAddress: string | null;
  shippersExcluded: string[];
  brokersExcluded: string[];
  statesExcludedIds: number[];
  citiesExcludedIds: number[];
  teamDriver: boolean;
  costPerMileCents: number | null;
  homeLocation: XYPoint;
}

export interface DriverLoadPreferences {
  commoditiesExcludedIds: number[];
  hasHazmatEndorsement: boolean;
  hasTankerEndorsement: boolean;
  multistopChoice: number | null;
  dropTrailerChoice: number | null;
  driverTouchChoice: number | null;
}

export interface DriverEquipmentPreferences {
  trailerType: string | null;
  trailerAttributeIDs: number[];
  trailerMake: string | null;
  trailerModel: string | null;
  trailerYear: number | null;
  trailerNumber: string | null;
  trailerVIN: string | null;
  tractorMake: string | null;
  tractorModel: string | null;
  tractorYear: number | null;
  tractorNumber: string | null;
  tractorVIN: string | null;
  additionalEquipmentIds: number[];
}

export interface DriverOtherPreferences {
  additionalPreference: string | null;
}

export interface TourAssetUpload {
  id: number;
  url: string;
  category: string;
}

export interface DriverEquipmentUploads {
  trailerFront?: TourAssetUpload;
  trailerRear?: TourAssetUpload;
  trailerDriverSide?: TourAssetUpload;
  trailerPassengerSide?: TourAssetUpload;
  tractorFront?: TourAssetUpload;
  tractorRear?: TourAssetUpload;
  tractorDriverSide?: TourAssetUpload;
  tractorPassengerSide?: TourAssetUpload;
}

export interface DriverTractorTrailerPreferences {
  tractorMake: string;
  tractorModel: string;
  tractorYear: string;
  tractorName: string;
  trailerMake: string;
  trailerModel: string;
  trailerYear: string;
  trailerName: string;
  trailerAttributes: string[];
  tractorVIN?: string;
  trailerVIN?: string;
}

export interface TractorTrailerPhoto {
  id: number;
  url: string;
  category: string;
}

export interface DriverTractorTrailerPhotoUploads {
  tractorFront: TractorTrailerPhoto;
  tractorRear: TractorTrailerPhoto;
  tractorDriverSide: TractorTrailerPhoto;
  tractorPassengerSide: TractorTrailerPhoto;
  trailerFront: TractorTrailerPhoto;
  trailerRear: TractorTrailerPhoto;
  trailerDriverSide: TractorTrailerPhoto;
  trailerPassengerSide: TractorTrailerPhoto;
}

export interface Tag {
  id: number;
  name: string;
  color: TagColor;
}

//  {red,green,blue,yellow,orange,purple,pink,black,white}
export type TagColor = 'red' | 'green' | 'blue' | 'yellow' | 'orange' | 'purple' | 'pink' | 'black' | 'white';

export interface EquipmentType {
  name: string;
  value: string;
}

export interface ContractRateType {
  type: string;
  displayName: string;
}

export interface BrokerContactSlimDetails {
  brokerId: string;
  brokerContactId: number;
  brokerEmailId: number;
  email: string;
  brokerName: string;
  agentName: string;
  agentPhone: string;
  createdById: string;
  createdByName: string;
}

export type TimelineCommentStatus = 'available' | 'unavailable';
export type TimelineCommentUnavailableStatus =
  | 'driver'
  | 'equipment'
  | 'doesnt_want_to_work'
  | 'on_other_load'
  | 'unresponsive'
  | '10_hour_reset'
  | '34_hour_reset'
  | 'home_time';

export interface DriverScheduleTimelineComment {
  id: number;
  driverId: string;
  comment: string;
  startsAt: string;
  endsAt: string;
  status: TimelineCommentStatus;
  unavailableStatus: TimelineCommentUnavailableStatus;
  createdAt: string;
  createdBy: string;
  createdByName: string;
  updatedAt: string;
  updatedBy: string;
  updatedByName: string;
  canEditComment: boolean;
  commentReplies: DriverScheduleTimelineCommentReply[];
  remindAt: string | null;
}

export interface DriverScheduleTimelineCommentReply {
  id: number;
  parentCommentId: number;
  comment: string;
  createdAt: string;
  createdBy: string;
  createdByName: string;
  updatedAt: string;
  canEditComment: boolean;
}

export interface AverageCostForLoadedMile {
  cpm: number;
  cpmFixed: number;
  cpmVariable: number;
  miles: number;
}

export interface FutureTour {
  id: number;
  driverId: string;
  createdBy: string;
  originDisplay: string;
  destinationDisplay: string;
  startsAt: string;
  endsAt: string;
  originLnglat: LongLat;
  destinationLnglat: LongLat;
}

export interface QuickScoreCard {
  loadId: string;
  loadBookedOn: string;
  idleRating: QuickScoreCardRating;
  rateRating: QuickScoreCardRating;
  serviceRating: QuickScoreCardRating;
}

export type QuickScoreCardRating = 'not_ranked' | 'poor' | 'good' | 'great';

export const DRIVER_STATUS_KPIS = 'DRIVER_STATUS_KPIS';
export const DRIVER_DURATION_KPIS = 'DRIVER_DURATION_KPIS';
export const DRIVER_REVENUE_TRENDS = 'DRIVER_REVENUE_TRENDS';
export const DRIVER_RPM_KPIS = 'DRIVER_RPM_KPIS';

export type KPIDuration =
  | '1_weeks'
  | '2_weeks'
  | '1_months'
  | '1_quarters'
  | 'last_7_days'
  | 'last_14_days'
  | 'last_30_days';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const KPIDurations: KPIDuration[] = [
  '1_weeks',
  '2_weeks',
  '1_months',
  '1_quarters',
  'last_7_days',
  'last_14_days',
  'last_30_days',
];
export type KPIStatuses = 'assigned' | 'in_progress' | 'completed';

export interface ChartEntry {
  day: string;
  value: number;
  revenueWithImprovements?: number;
  marketRevenueCents?: number;
}

export interface DriverKPIs {
  revenueCentsCurrent: number;
  revenueCentsPrevious: number;
  revenuePercentChange: number;
  loadedRPMCentsCurrent: number;
  loadedRPMCentsPrevious: number;
  loadedRPMPercentChange: number;
  totalRPMCentsCurrent: number;
  totalRPMCentsPrevious: number;
  totalRPMPercentChange: number;
}

export interface DriverRecs {
  lostRevenueCents: number;
  lostRevenueCentsPrevious: number;
  periodStart: Date;
  periodEnd: Date;
  driverOnTourRecommendation: {
    showCard: boolean;
    lostRevenueCents: number;
    onTourCountLastWeek: number;
    onTourCount2WeeksAgo: number;
    daysOnTourLastWeek: number;
    daysOnTour2WeeksAgo: number;
    drivers: {
      driverId: string;
      driverName: string;
      daysOnTour: number;
    }[];
  };
  driverOnTimeRecommendation: {
    showCard: boolean;
    lostRevenueCents: number;
    drivingDaysLost: number;
    drivers: {
      driverId: string;
      driverName: string;
      timeLateMinutes: number;
      drivingDaysLost: number;
    }[];
  };
  driverUnavailableTimeRecommendation: {
    showCard: boolean;
    lostRevenueCents: number;
    drivingDaysLost: number;
    drivers: {
      driverId: string;
      driverName: string;
      minutesUnavailable: number;
      drivingDaysLost: number;
      events: {
        category: string;
        start: string;
        end: string;
      }[];
    }[];
  };
  driverDontCancelLoadsRecommendation: {
    showCard: boolean;
    lostRevenueCents: number;
    drivingDaysLost: number;
    drivers: {
      driverId: string;
      driverName: string;
      lostMinutes: number;
      drivingDaysLost: number;
    }[];
  };
  assetTypeRecommendation: {
    showCard: boolean;
    mainAssetType: TrailerType;
    betterAssetType: TrailerType;
    concentrationOfMainAsset: number;
    mainAssetTypeRatePerMileCents: number;
    betterAssetTypeRatePerMileCents: number;
  };
  followTourRecommendation: {
    showCard: boolean;
    deviatedDriverCount: number;
    drivers: {
      driverId: string;
      driverName: string;
      cancelledLoadCount: number;
    }[];
  };
  setupELDRecommendation: {
    showCard: boolean;
    driversInELDCount: number;
    driversNotInELDCount: number;
    driversNotInELD: {
      driverId: string;
      driverName: string;
    }[];
  };
  fillOutPTARecommendation: {
    showCard: boolean;
    delayedBookingCount: number;
    drivers: {
      driverId: string;
      driverName: string;
      daysNotFillingOutPTA: number;
    }[];
  };
  driverPreferencesRecommendation: {
    showCard: boolean;
    countDriversExcludedHotMarkets: number;
    countTotalHotMarketsExcluded: number;
    driverExclusions: {
      driverId: string;
      driverName: string;
      excludedMarkets: string[];
    }[];
  };
  bannedFromBrokersRecommendations: {
    showCard: boolean;
    totalLoadsPerWeek: number;
    brokers: {
      brokerName: string;
      loadCountPerWeek: number;
    }[];
  };
}

export type DriverRecTypes =
  | 'driverOnTourRecommendation'
  | 'driverOnTimeRecommendation'
  | 'driverUnavailableTimeRecommendation'
  | 'driverDontCancelLoadsRecommendation'
  | 'assetTypeRecommendation'
  | 'followTourRecommendation'
  | 'setupELDRecommendation'
  | 'fillOutPTARecommendation'
  | 'driverPreferencesRecommendation'
  | 'bannedFromBrokersRecommendations';

export type EmptyType = 'none' | 'now' | 'future';

export interface HOSBlock {
  startTime: string;
  endTime: string;
}

export interface LoadBlock {
  loadId: string;
  startTime: string;
  endTime: string;
  stops: LoadBlockStop[];
  status: LohiLoadStatus;
  brokerName: string;
}

export interface LoadBlockStop {
  loadId: string;
  arrivalWindowStartsAt: string;
  arrivalWindowMinutes: number;
  eta: string | null;
  etaUpdatedAt: string | null;
  type: StopType;
  arrivedAt: string | null;
  exitedAt: string | null;
  city: string | null;
  state: string | null;
  completedAt: string | null;
}

export interface DriverScheduleToDo {
  todoId: number;
  label: string;
  todoType: string;
  createdAt: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
}

export interface HoldingYardContact {
  name: string;
  phone: string;
  email: string;
  preferredContactMethod: string;
}

export interface HoldingYardOpenTime {
  dayOfWeek: string;
  timesOpen: TimeOpen[];
}

export interface TimeOpen {
  openingMinuteOfDay: number;
  minutesOpen: number;
}

export type DriverScheduleTimelineStatus =
  | 'declined_load'
  | 'check_call_late'
  | 'eta_late'
  | 'waypoint_outdated'
  | 'z_no_issues';

export type BucketName =
  | 'bucket_no_load_booker'
  | 'bucket_onboard_driver'
  | 'bucket_call_on_loads'
  | 'bucket_secure_load'
  | 'bucket_enter_rate_con'
  | 'bucket_assigned_load_in_past_24_hours'
  | 'bucket_no_load_needed'
  | 'bucket_reviewing_tour_options'
  | 'bucket_unknown';

export type ReadyToBookStatus =
  | 'pta_ready_to_book'
  | 'pta_not_ready_doesnt_know'
  | 'pta_not_ready_no_response'
  | 'pta_not_sent'
  | '';

export type BookingStatus = 'ready_to_book' | 'not_ready_doesnt_know' | 'tour_ending_soon';

export type ShippingLine =
  | 'ONE'
  | 'Yang Ming'
  | 'Hapag-Lloyd'
  | 'CMA'
  | 'Maersk'
  | 'Evergreen'
  | 'COSCO'
  | 'ZIM'
  | 'MSC'
  | 'Hamburg Sud'
  | 'HMM'
  | 'OOCL'
  | 'Crowley';

export const shippingLines: ShippingLine[] = [
  'ONE',
  'Yang Ming',
  'Hapag-Lloyd',
  'CMA',
  'Maersk',
  'Evergreen',
  'COSCO',
  'ZIM',
  'MSC',
  'Hamburg Sud',
  'HMM',
  'OOCL',
  'Crowley',
].sort() as ShippingLine[];

export interface Accessorial {
  id: number;
  name: string;
}

export interface CustomChargeType {
  id: number;
  name: string;
  type: string;
}

export interface PricingReport {
  willCover: boolean;
  costToCover: number;
}

export interface BasicUser {
  userId: string;
  userName: string;
  userPhone: string;
  userEmail?: string;
}

export interface CompanyAdmins {
  companyAdmins: BasicUser[];
}

export interface FacilityListItem {
  id: string;
  customerReferenceNumber: string | null;
  name: string;
  ownedByName: string;
  lnglat: XYPoint;
  isFavorite: boolean;
  loadsCompletedInPastTimePeriod: number;
  manuallyCompletedInTimePeriod: number;
}

export interface FacilityReceivingContact {
  id: number;
  name: string;
  phone?: string;
  email?: string;
  preferredContactMethod: 'phone' | 'email';
}

export type FacilityManagerContact = Omit<FacilitySchedulingUser, 'shouldSendBookingEmails'>;
export type FacilityManagerContactUpdate = Omit<FacilitySchedulingUser, 'shouldSendBookingEmails' | 'id'> & {
  id?: string;
};

export interface FacilitySchedulingUser {
  id: string;
  name: string;
  email: string;
  shouldSendBookingEmails: boolean;
  customerId: string;
}

export interface FacilityImageURL {
  id: number;
  imageURL: string;
  title: string;
}

export interface FacilityTime {
  dayOfWeek: string;
  timesOpen: {
    openingMinuteOfDay: number;
    minutesOpen: number;
  }[];
}

export interface Facility {
  lnglat: LongLat;
  id: string;
  name: string;
  radiusMiles: number;
  customerReferenceNumber: string | null;
  ownerAccountId: string;
  addressLine1?: string;
  addressLine2?: string;
  addressCity?: string;
  addressState?: string;
  addressZip?: string;
  requiresYardChecks: boolean;
  appointmentRequired: boolean;
  firstComeFirstServe: boolean;
  receivingContacts: FacilityReceivingContact[];
  openTimes: FacilityTime[];
  loadingTimes: FacilityTime[];
  schedulingUsers: FacilitySchedulingUser[];
  managerContacts: FacilityManagerContact[];
  images: FacilityImageURL[];
  notes?: string;
  loadTypes: SupportedLoadingType;
  region: 'East' | 'Delta' | 'West';
  trailerPickupInstructions?: string;
  needUSDA: boolean;
  excludeFromTrailerFeatures: boolean;
  requiresColtCheckin: boolean;
  shouldDivertAlways: boolean;
  dontShowTrailerIDOnPickup: boolean;
  excludeFromEap: boolean;
  autoOrderType: AutoOrderType;
  poRequired: boolean;
  neverBobtail: boolean;
  dayCabOnly: boolean;
  requiresLumperUpload: boolean;
  allowJIT: boolean;
  polygon: XYPoint[];
  dockGeofenceEnforced: boolean;
  dockPolygons?: XYPoint[][];
  detentionIneligibleCarrier: boolean;
  detentionIneligibleShipper: boolean;
}

export interface DockPolygon {
  id: number;
  facilityID: string;
  geofence: XYPoint[];
}

export interface DockInfo {
  dockID?: number | null;
  facilityID: string;
  externalDockId: string;
  description: string | null;
  inBoundEnabled: boolean;
  outBoundEnabled: boolean;
  appointmentWindowMins: number;
  trucksPerAppointmentSlot: number;
  isBookableByVorto: boolean;
}

export interface InternalFacilitySetting {
  id: string;
  description: string;
  selected: boolean;
}

export interface MissedAppointmentPenaltySetting extends InternalFacilitySetting {}

export interface WorkInEaseSetting extends InternalFacilitySetting {}

export interface SchedulingRulesSetting extends InternalFacilitySetting {}

export interface InternalFacilitySettings {
  missedAppointmentPenaltyOptions: MissedAppointmentPenaltySetting[];
  workInEaseOptions: WorkInEaseSetting[];
  schedulingRuleOptions: SchedulingRulesSetting[];
}

export interface CheckInInstructions {
  id?: number;
  checkInInstructions: string;
  icon: string;
  rank: number;
}

export interface SettlementStatementSummary {
  id: number;
  date: string;
  year: number;
  month: number;
  day: number;
  loadCount: number;
  revenueInPennies: number;
  adjustmentInPennies: number;
  deductionsInPennies: number;
  takeRateInPennies: number;
  payableInPennies: number;
  driversTotalInPennies: number;
  carriersTotalInPennies: number;
  paidAt: Date;
}

export interface SettlementStatementOrderDetailSiteDetail {
  siteTime: string;
  name: string;
  lngLat: [number, number];
}

export interface SettlementStatementOrderDetailLineItem {
  description: string;
  totalInPennies: number;
}

export interface SettlementStatementOrderDetail {
  id: string;
  propxTicketNumber: string | null;
  driverName: string;
  acceptTime: string;
  loadTotalInPennies: number;
  pickupLocation: SettlementStatementOrderDetailSiteDetail;
  dropoffLocation: SettlementStatementOrderDetailSiteDetail;
  trailerTimestamp: string;
  lineHaulMiles: number;
  lineItems: SettlementStatementOrderDetailLineItem[];
  bolNumber?: string;
  totalDeductions: number;
  brokerageName: string;
}

export interface SettlementStatementDeductionLineItem {
  description: string;
  totalInPennies: number;
  isOnOrder: boolean;
}

export interface SettlementStatementAdjustmentItem {
  id: number;
  totalInPennies: number;
  userFacingComment: string;
}

export interface DailyJournalEntry {
  displayName: string;
  driverName: string;
  billingInterval: string;
  year: number;
  month: number;
  day: number;
  paidInPennies: number;
}

export interface TruckJournalEntry {
  vin: string;
  valueInPennies: number;
  driverName: string;
  year: number;
  month: number;
  day: number;
  paidInPennies: number;
}

export interface SettlementStatementEscrowPayout {
  id: number;
  totalPaid: number;
  carrierFacingNotes: string;
}

export interface SettlementStatementFile {
  id: number;
  displayName: string;
  fileName: string;
  publicUrl: string;
}

export interface SettlementStatement {
  company: {
    address: string;
    dotNumber: string;
    mcNumber?: string;
    id: string;
    name: string;
  };
  statement: SettlementStatementDetails;
}

export interface SettlementStatementDirectDriverPayment {
  description: string;
  totalInPennies: number;
  userName: string;
}

export interface SettlementStatementFuelTransaction {
  id: number;
  time: string;
  totalInPennies: number;
}

export interface SettlementStatementDetails {
  id: number;
  year: number;
  month: number;
  day: number;
  paidAt: Date | string | null;
  totalCredits: number;
  totalOfAllLoads: number;
  totalPayable: number;
  totalDeductions: number;
  firstLoadDate: Date | string | null;
  lastLoadDate: Date | string | null;
  adjustment: SettlementStatementAdjustmentItem[];
  orders: SettlementStatementOrderDetail[];
  lineItems: SettlementStatementDeductionLineItem[];
  nonOrderLineItems: SettlementStatementDeductionLineItem[];
  escrowPayouts: SettlementStatementEscrowPayout[];
  dailyJournalEntries: DailyJournalEntry[];
  truckJournalEntries: TruckJournalEntry[];
  directDriverPaymentInfo: SettlementStatementDirectDriverPayment[];
  fuelTransactions: SettlementStatementFuelTransaction[];
  files: SettlementStatementFile[];
}

export interface StatementsOverview {
  averageByDateOverLast7Days: {
    averageTotalPerDay: number;
    averageTotalPerDayPerDriver: number;
    daysCount: number;
    randomDaysCount: number;
    totalPerDayPerDriverByRandomDaysCount: number;
  };
  escrowDetails: {
    driverBasedEscrow: number;
    escrowTotalOverride: number;
    lineItems: {
      name: string;
      count: number;
      totalInPennies: number;
    }[];
    maxEscrowInPennies: number;
    noTrailerEscrow: boolean;
  };
  depositCompletionPercentage: number;
  totalAssetDeposit: number;
  totalPaidDeposit: number;
  statements: SettlementStatementSummary[];
  totalPayableOver7Days: number;
  totalTrucksOver7Days: number;
  hasTruck: boolean;
}

export interface CompanyChargesDetails {
  insuredTrucks: InsuredTruck[];
  dailyBillingItems: CompanyDailyBillingItems[];
}

export interface InsuredTruck {
  vin: string;
  valueInPennies: number;
  driverName: string;
  archivedAt: string;
}

export interface CompanyDailyBillingItems {
  displayName: string;
  driverId: string;
  driverName: string;
  billingInterval: 'work_day' | 'every_day';
  costInPennies: number;
}

export interface Hitch {
  id: number;
  startDate: string;
  startTime: string;
  lastDate: string;
  startToday?: string;
  firstShiftStartMinute: number;
  firstShiftEndMinute: number;
  startTodayWindowEnd?: string;
  timezoneName: string;
  createdAt: string;
  poolId: number;
  poolName: string;
  hitchDates: {
    year: number;
    month: number;
    day: number;
  }[];
}

export interface HitchEarnings {
  totalRevenueCents: number;
  lineItems: {
    title: string;
    time: string;
    revenueCents: number;
    highlightInGreen: boolean;
  }[];
}

export interface HitchForecast {
  onTrackForDedicatedAssetBonus: boolean;
  dedicatedAssetBonus: {
    onTrack: boolean;
    awarded: boolean;
    loadId: string | null;
    paid: boolean;
  };
  totalEstimatedEarningsCents: number;
  pastEarnings: HitchEarnings;
  estimatedFutureEarnings: HitchEarnings;
}

export interface DriverWithHitch {
  id: string;
  name: string;
  phone: string;
  currentHitch?: Hitch;
  currentHitchDABStatus: 'full' | 'partial' | 'lost' | null;
  currentHitchForecast?: HitchForecast;
  futureHitches?: Hitch[];
}

export interface ShiftDABRemoval {
  shiftId: number;
  startTime: string;
  displayReason: string | null;
  overridden: boolean;
  overrider: string | null;
  overrideNotes: string | null;
  isStandby: boolean;
  isUpcomingShift: boolean;
  isOnShift: boolean;
}

export interface DABHitchListItem {
  driverId: string;
  driverName: string;
  driverPhone: string;
  hitchId: number;
  poolId: number;
  startDate: string;
  startDateDisplay: string;
  endDate: string;
  endDateDisplay: string;
  days: number;
  isActive: boolean;
  isEligibleForDab: boolean;
  meetsExpectation: boolean;
  partiallyMeetsExpectation: boolean;
  paid: boolean;
  inQueueForPayment: boolean;
  dabCents: number;
  paymentStatus: string | null;
  settlementStatementId: number | null;
  hitchDABRemovalReason: string | null;
  shiftDABRemovals: ShiftDABRemoval[];
  timezone: string;
}

export interface DABSums {
  paidSumCents: number;
  pendingSumCents: number;
}

export type CoverageStrategy = 'street_turn' | 'source_load' | 'transit_load';

export interface CoverageStrategyStop {
  strategy: CoverageStrategy;
  stops: LohiLoadStop[] | null;
  valid: boolean;
  invalidReason: string | null;
}

export type CarrierType = 'all' | 'CPG' | 'Tours' | 'Domestic' | 'Import/Export';

export type CheckCallStatus = 'not_sent' | 'waiting_for_response' | 'on_track' | 'delayed' | 'delayed_resolved';

export interface SlimLohiLoad {
  id: string;
  referenceNumber?: string;
  stopCount: number;
  stopAddresses: string[];
  trailerType?: TrailerType;
  createdAt: string;
  updatedAt: string;
  pickupStartsAt?: string;
  pickupTimezone?: string;
  pickupMinutes?: number;
  dropoffStartsAt?: string;
  dropoffMinutes?: number;
  dropoffTimezone?: number;
  shipperName: string;
  loadStatus?: {
    status: LoadStatus;
    panic: boolean;
    atAction?: string;
    at?: string;
    eta: string;
    atStop?: number;
  };
  checkCallStatus?: {
    checkStatus: CheckCallStatus;
    checkSentAt?: string;
    checkOnTime?: boolean;
    checkResolvedAt?: string;
  };
  postedRateCents?: number;
  loadboardReferenceId?: string;
  assignedDispatcherId?: string;
  assignedDispatcherName?: string;
  nextArrivalWindowStartsAt?: string;
  nextArrivalTimezone?: string;
  companyName?: string;
  completedAt: string;
  lastWaypointUpdatedAt?: string | null;
  hasBolFile: boolean;
  hasChargesWithMissingUploads: boolean;
  driverName?: string;
  unsignedRatecon: boolean;
  missingDocumentNames: string[];
}

export interface GeneralKPI {
  loadsPassing: string[];
  loadsFailing: string[];
  drivers: DriverKPI[];
}

export interface DriverKPI {
  driverId: string;
  driverName: string;
  loadsPassing: string[];
  loadsFailing: string[];
  driverKPI: number;
}

export interface CheckCallResponsivenessKPI {
  totalAnswered: number;
  totalCalls: number;
  loadsPassing: string[];
  loadsFailing: string[];
  drivers: CheckCallResponsivenessDriverKPI[];
}

export interface CheckCallResponsivenessDriverKPI {
  totalAnswered: number;
  totalCalls: number;
  driverId: string;
  driverName: string;
  loadsPassing: string[];
  loadsFailing: string[];
  driverKPI: number;
}

export type CarrierTouchpointType = 'phone' | 'email';

export interface CarrierTouchpoint {
  id: number;
  companyID: string;
  carrierTouchpointType: CarrierTouchpointType;
  loadID: string;
  notes: string;
  createdAt: string;
  createdBy: string;
  pinned: boolean;
}

export interface CarrierTouchpoints {
  carrierTouchpoints: CarrierTouchpoint[];
}

export interface CompanyCarrierTag {
  carrierTagName: string;
  carrierTagId: number;
  companyId: string;
}

export interface CarrierTag {
  id: number;
  name: string;
}

export type UserPayModel = 'percentage_of_gross' | 'full_gross' | 'none';

export type CarrierUserInfo = {
  appInstalled: boolean;
  appStatus: string;
  hasTWICCard: boolean;
  lastAppUse: string;
  lastCompletedLoad: string;
  lastOnHitch: string;
  lastOnHitchDate: string;
  hitchStartDate: string;
  hitchEndDate: string;
  lnglat: XYPoint;
  payModel: UserPayModel;
  payModelSort: number;
  payPercentage: number;
  tooltip: string;
  tractorId: number;
  tractorName: string;
  trailerId: number;
  trailerName: string;
  trailerType: string;
  truck: string;
  truckURL: string;
  userEmail: string | null;
  userID: string;
  userName: string;
  userPhone: string;
  userType: string[];
  waypointUpdatedAt: string;
  maximumWeightLimit: number;
  maximumQuantity: number;
  currentTier: TierType;
  lastPoolID: number;
  lastPoolName: string;
  canTakeTrailerHome: boolean;
  canBookHitch: boolean;
};

export interface DriverTierInfo {
  currentEarningsTier: string;
  currentEarningsTierDisplay: string;
  currentEarningsTierEarningsRate: number;
  earningsTierPenaltyDaysRemaining: number;
  nextEarningsTier: string;
  nextEarningsTierDisplay: string;
  nextEarningsTierEarningsRate: number;
  currentAscensionTier: string;
  currentAscensionTierDisplay: string;
  ascensionProgramWorkingDays: number;
  isCrankedModeActive: boolean;
  crankedModeBonusRate: number;
  crankedModeStartAt: any;
  crankedModeEndAt: any;
  unconsumedCrankCoinsCount: number;
  canUseToken: boolean;
  useTokenActionText: string;
  ascensionLevels: AscensionLevel[];
  recentEvents: RecentEvent[];
  currentEarningsTierDescription: string;
  tierLevels: TierLevel[];
}

export interface AscensionLevel {
  rankName: string;
  rankDisplayName: string;
  workingDaysRequired: number;
  remainingDaysRequired?: number;
  earnedAt?: string;
  coinsPerRank: number;
  isTopTier: boolean;
}

export interface RecentEvent {
  eventTime: string;
  eventTitle: string;
  eventDescription: string;
  loadId?: string;
  renderType: string;
  rankName?: string;
}

export interface TierLevel {
  earningsTier: string;
  earningsTierDisplay: string;
  earningsTierEarningsRate: number;
}

export interface DriverOTP {
  onTimePercentage?: number;
}

export interface DriverTimelineLoad {
  loadId: string;
  status: LohiLoadStatus;
  rateCents: number;
  loadTime: string;
  stops: DriverTimelineLoadStop[];
  approvedForInvoicing: boolean;
  tierEvents: DriverTimelineLoadTierEvent[];
}

export interface DriverTimelineLoadTierEvent {
  id: number;
  name: string;
  points: number;
  eventAt: string;
  loadID: string;
  stopId: number;
  isArchived: boolean;
  driverID: string;
  disputeReason: string | null;
  disputeResolvedAt: string | null;
  disputeStatus: DisputeStatus;
}

/* eslint-disable @typescript-eslint/naming-convention */
export enum DisputeStatusOld {
  free_pass_applied = 'free_pass_applied',
  grace_period_applied = 'grace_period_applied',
  dispute_accepted = 'dispute_accepted',
  dispute_denied = 'dispute_denied',
  dispute_pending = 'dispute_pending',
}

/* eslint-enable @typescript-eslint/naming-convention */

export interface DriverTimelineLoadStop {
  stopId: number;
  type: StopType;
  loadingType: LoadingType;
  lngLat: XYPoint;
  sequence: number;
  arrivalWindowStartsAt: string;
  arrivalWindowEndsAt: string;
  address: string;
  city: string;
  state: string;
  stateCode: string;
  zipCode: string;
  facilityName: string;
  timezone: string;
  completedAt: string | null;
  isETAOnTime: boolean;
}

export interface VPFEvent {
  eventTime: string;
  eventTitle: string;
  eventDescription: string;
  loadId: string | null;
  renderType: VPFEventRenderType;
  rankName?: string;
}

export interface VPFEventNew {
  time: string;
  title: string;
  description: string;
  renderType: VPFEventRenderType;
  tierType: TierType;
  ascensionLevelName: AscensionLevelName;
}

export type AscensionLevelName =
  | 'rank_0'
  | 'rank_1'
  | 'rank_2'
  | 'rank_3'
  | 'rank_4'
  | 'rank_5'
  | 'rank_6'
  | 'rank_7'
  | 'rank_8';

export type VPFEventRenderType =
  | 'tier_change'
  | 'ascension_change'
  | 'free_pass'
  | 'grace_period'
  | 'on_notice_extended'
  | 'outlined_banner'
  | 'solid_banner'
  | 'generic'
  | 'celebration';

export interface VPFCanActiveBonusCoin {
  showBonusCoin: boolean;
  canActivate: {
    CanCrank: boolean;
    ErrorMessage: string;
    ActionText: string;
  };
  isActive: boolean;
}

export interface DriverLocationResponse {
  trackedDrivers: TrackedDriver[];
  untrackedDrivers: UntrackedDriver[];
}

export interface TrackedDriver {
  id: string;
  name: string;
  lnglat: XYPoint;
  updatedAt: string;
  tractorNumber?: string;
  trailerNumber?: string;
  currentLoadId?: string;
}

export interface UntrackedDriver {
  id: string;
  name: string;
  status: string;
}

export interface OTPLoadDisputeItem {
  loadId: string;
  completedAt: string;
  wasLate: boolean;
  disputeId: number | null;
  disputeAppealReason: string | null;
  disputeStatus: DisputeStatusOld | null;
  disputeCreatedAt: string | null;
  decisionMade: boolean;
  decisionMadeAt: string | null;
  driverId: string;
  driverName: string;
  companyId: string;
  companyName: string;
  decidingUserId: string;
  decidingUserName: string | null;
  uploads: { url: string }[];
}

export interface LoadRateConPaymentStatus {
  invoiceApprovedByCarriersAt: Date;
  isInvoiceApproved: boolean;
  paymentApprovedBy5FAt: Date;
  isPaymentApproved: boolean;
  paymentSettledAt: Date;
  isPaymentSettled: boolean;
  rejectionEvents: {
    carrierNotes: string;
    eventTime: string;
  }[];
}

export interface SettlementEmail {
  id: number;
  email: string;
}

export interface SlimProduct {
  id: string;
  name: string;
  unitId: number;
  unitName: string;
  unitAbbreviation: string;
}

export enum LAMStatus {
  readyForUse = 'ready_for_use',
  maintenanceNeeded = 'maintenance_needed',
  inspectionNeeded = 'inspection_needed',
  notAvailable = 'not_available',
  inCustody = 'in_custody',
  reserved = 'reserved',
}

export interface LoadHistoryMap {
  details: LoadHistoryMapDetails;
  waypoints: LoadHistoryMapWaypoint[];
  stops: LoadHistoryMapStop[];
  carrierYard: LoadHistoryMapCarrierYard[];
  exceptions: LoadHistoryException[];
}

export interface LoadHistoryMapCarrierYard {
  type: string;
  name: string;
  arrivedAt: string;
  exitedAt: string;
  manuallyCompleted: boolean;
  timezone: string;
  boundingPolygon: XYPoint[];
}

export interface LoadHistoryMapDetails {
  id: string;
  status: string;
  assignedAt: string;
  startedAt: string;
  completedAt: string;
  completedByName: string;
  driverPhone: string;
  driverName: string;
  earliestReceiptDate: string;
  portCutoffDate: string;
  rampCutoffTime: string;
}

export interface LoadHistoryMapWaypoint {
  lngLat: LongLat;
  clientCreatedAt: string;
  accuracy: number;
  speed: number;
  createdAt: string;
}

export interface LoadHistoryMapStop {
  sequence: number;
  type: string;
  facilityId: string;
  name: string;
  arrivedAt: string;
  exitedAt: string;
  lngLat: LongLat;
  completedAt: string;
  manuallyCompleted: boolean;
  geofenceRadiusMeters: number;
  timezone: string;
  boundingPolygon: XYPoint[];
}

export interface LoadHistoryException {
  exceptionType: string;
  actionTaken: string;
  intercomTicketId: string;
  createdAt: string;
  geofencePauseEndsAt: string;
  driverNotes: string;
}

export interface EmptyInfo {
  emptyNow: boolean;
  emptyTime?: string;
  emptyAddress?: string;
  emptyDailyHOSMinutesLeft?: number;
  emptyWeeklyHOSMinutesLeft?: number;
  lastScheduleLoadEta: string | null;
  ptaLastUpdatedAt: string | null;
  ptaLastUpdatedByUserName: string | null;
}

export interface AvailableCarrierPool {
  id: number;
  title: string;
}

export interface SankeyRevData {
  targetCents: number;
  totalRevCents: number;
  missedRevCents: number;
  paidOutCents: number;
  pendingCents: number;
  dabCents: number;
  takeRateCents: number;
  loadCents: number;
  otherCents: number;
  takeRateLossCents: number;
  missedDabCents: number;
  lowLoadsCents: number;
}

export interface Stipulation {
  title: string;
  body: string;
}

export interface LohiLoadWithRateconChargesAndTerms {
  lohiLoad: LohiLoad;
  charges: LoadCharge[];
  totalCarrierCents: number;
  terms?: string;
  stipulations: Stipulation[];
  companyName?: string;
  companyMCNumber?: string;
  companyDOTNumber?: string;
  signedAt?: string;
  signedBy?: string;
  signedByName?: string;
  tenderedAt: string;
  tenderedBy: string;
  tenderedByEmail: string;
  tenderedByPhone: string;
  tenderedByPhoneExtension: string;
  driverLngLat?: LongLat;
  driverLngLatUpdatedAt?: string;
  driverHasActive5FTourSession?: boolean;
  earliestPickupAt: string;
  earliestReceiptTime: string;
  referenceNumber: string;
  referenceNumberAvailable: boolean;
  readOnlyRateConURL: string;
  loadRateConPaymentStatus: LoadRateConPaymentStatus;
  driverTierTakeRate?: number;
  loadSettled?: boolean;
}

export interface SteamshipLine {
  id: number;
  name: string;
  scac: string;
}

export interface RouteGuideLaneCarrier {
  id: number;
  routeGuideLaneID: number;
  carrierID: string;
  name: string;
  dotNumber: string;
  mcNumber: string;
  position: number | null;
  rateCents: number;
  updatedAt: Date;
  updatedBy: string;
  carrierRepresentativeId: string;
  carrierRepresentativeName: string;
  carrierPointOfContactID: string;
  carrierPointOfContactName: string;
  carrierPointOfContactPhone: string;
  carrierPointOfContactEmail: string;
  lastLoadCompletedAt: Date;
  loadsCompletedInLaneCount: number;
  loadsOnTimeFraction: number;
  trackAndTraceFraction: number;
  carrierResponsivenessFraction: number;
  loadCloseoutFraction: number;
  recommended: boolean;
  loadsPerWeekCapacity: number;
}

export interface AssetMarketRateResponse {
  title: string;
  currentRateInCents: number;
  dailyMarketRates: DailyMarketRate[];
}

export interface DailyMarketRate {
  date: string;
  rate: number;
  carrierRate: number;
}

export interface FacilityBannedDriver {
  facilityId: string;
  driverId: string;
  driverName: string;
  driverPhone: string;
  companyName: string | null;
  bannedAt: string;
  bannedBy: string;
  reason: string;
  comment: string | null;
}

export interface FacilityWhitelistedDriver {
  facilityId: string;
  driverId: string;
  driverName: string;
  driverPhone: string;
  companyName: string | null;
  whitelistedAt: string;
  whitelistedBy: string;
  comment: string | null;
}

export interface FacilityBanDriverReason {
  id: number;
  reason: string;
}

export interface AvailableDriver {
  id: string;
  name: string;
  companyName: string;
}

export interface DriverTierEventDisputeDetail {
  driverEventDispute: TierDriverEventDispute;
  driverEventDisputeItems: DriverTierEventDisputeItem[] | null;
}

export interface TierDriverEventDispute {
  id: number;
  driverEventID: number;
  motivation: string;
  isSeen: boolean;
  isHonored: boolean;
  resolvedAt: string;
  resolvedBy: string;
  status: DisputeStatus;
}

export interface DriverTierEventDisputeItem {
  isComment: boolean;
  isUpload: boolean;
  isImage: boolean;
  isOwner: boolean;
  comment: string;
  createdAt: string;
  createdBy: string;
  creatorName: string;
  fileName: string;
  fileURL: string;
}

export enum FacilityPriorityType {
  forever = 'forever',
  timed = 'timed',
  target = 'target',
}

export enum FacilityPriorityTargetType {
  loaded = 'loaded',
  empty = 'empty',
  any = 'any',
}

export interface NotReadyReason {
  name: string;
  markNotReady: boolean;
  requiresNote?: boolean;
  requiresTrailer?: boolean;
  expires?: boolean;
  preventAutoOverride?: boolean;
  willRemoveDAB?: boolean;
}

export interface AllFacilityListItem {
  tag: string;
  id: string;
  name: string;
  customerReferenceNumber: string;
  ownedById: string;
  ownedByName: string;
  lnglat: XYPoint;
  yardCheckAllowed: boolean;
  requiresColtCheckin: boolean;
  isNew: boolean;
  poolIDs: number[];
  poolNames: string[];
  poolScacs: string[];
  dataQualityPercent: number;
  loadsCompleted: number;
  missingOperatingHours: boolean;
  missingSchedulingContact: boolean;
  missingPhotos: boolean;
  missingCheckInInstructions: boolean;
  incorrectGeofence: boolean;
  stopCount: number;
  stopsWithArrivedAt: number;
  stopsWithArrivedAtPercent: number;
  stopsWithoutArrivedAt: number;
}

export interface ShiftConfirmation {
  shiftId: number;
  shiftTime: string;
  shiftTitle: string;
  title: string;
  loadDetails: {
    title: string;
    loadId: string;
    stops: {
      locationName: string;
      showLocationName: string;
      stopId: number;
      pickup: boolean;
      timeString: string;
    }[];
  }[];
  responseDeadlineTime: string;
}

export interface DriverSummary {
  id: string;
  name: string;
  phone: string;
  email: string;

  otpPercentage: number;
  appStatus: 'Invited' | 'Not In Use' | 'In Use';

  currentPoints: number;
  currentStreakCount: number;
  currentStreakMultiplier: number;
  currentTierName: string;
  currentTierType: TierType;
  currentTierTakeRate: number;
  currentTierPointsNeeded: number;
  nextTierName: string;
  nextTierPointsNeeded: number;
  tierGroupName: string;
  eventGroupName: string;
}

export interface FacilityInterestedParty {
  id: string;
  name: string;
}

export enum AutoOrderType {
  none = 'none',
  full = 'full',
  partial = 'partial',
}

export interface CanPickup {
  canPickup: boolean;
  cannotPickupReason: string;
}

export interface PoolWeights {
  onTimeWeight: number;
  appUsageWeight: number;
  loadCloseOutWeight: number;
  loadAcceptanceWeight: number;
  averageBookedLoadsWeight: number;
  completedLoadsWeights: number;
}

export interface UFGCarrierMetrics {
  onTimeDeliveryPercentage: number;
  driverAppUsagePercentage: number;
  loadCloseOutPercentage: number;
  loadAcceptancePercentage: number;
  averageCompletedLoads: number;
  averageAssignedLoads: number;
  score: number;
  rank: number;
}

export enum CarrierContractType {
  perMile = 'per_mile',
  mileageBracket = 'mileage_bracket',
}

export interface SlimCarrierContract {
  id: string;
  name: string;
  createdAt: string;
  type: CarrierContractType;
  startsAt: string;
  version: number;
  createdByName: string;
}

export interface CarrierContract {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  type: CarrierContractType;
  startsAt: string;
  cost: MileageBracket[];
  perMileCost: {
    cents: number;
  };
  deadhead: Deadhead;
  bracketsUpdatedAt: string;
}

export interface MileageBracket {
  lowerMileage: number;
  cents: number;
}

export interface Deadhead {
  lowerMiles: number;
  upperMiles: number;
  centsPerMile: number;
}

export interface PoolCarrierContracts {
  id: number;
  name: string;
  contracts: PoolCarrierContract[];
}

export interface PoolCarrierContract {
  id: number;
  poolId: number;
  poolName: string;
  contractId: string;
  contractName: string;
  contractType: CarrierContractType;
  customerId: string | null;
  customerName: string | null;
  trailerType: TrailerType;
  loadingType: SupportedLoadingType;
}
