import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CpgUfgService } from '../cpg-ufg.service';

@Component({
  selector: 'td-cpg-ufg-rankings',
  templateUrl: './cpg-ufg-rankings.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CpgUfgRankingsComponent {
  private ufgService = inject(CpgUfgService);
  public carrierRankings$ = this.ufgService.carrierRankings$;
}
