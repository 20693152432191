import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { shareReplayComponentConfig } from '../../constants';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'td-double-confirm',
  templateUrl: './double-confirm.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DoubleConfirmComponent {
  @Input() public confirmText: string = 'Confirm';
  @Input() public buttonColor: ThemePalette = 'accent';
  @Input() public buttonType: 'button' | 'flat' | 'stroked' | 'raised' = 'button';
  @Input() public confirmDelay: number = 0;
  @Input() public disabled = false;

  @Output() public confirm = new EventEmitter<boolean>();

  private clicked$$ = new BehaviorSubject(false);
  public clicked$: Observable<boolean> = this.clicked$$.pipe(shareReplay(shareReplayComponentConfig));
  private disabled$$ = new BehaviorSubject(false);
  public disabled$: Observable<boolean> = this.disabled$$.pipe(shareReplay(shareReplayComponentConfig));

  private delayTimer: any;

  constructor() {}

  @HostListener('mouseleave')
  public unclick() {
    this.clicked$$.next(false);
    if (this.delayTimer) {
      clearTimeout(this.delayTimer);
    }
  }

  @HostListener('click')
  public click() {
    if (this.clicked$$.value || this.disabled$$.value) {
      return;
    }
    this.clicked$$.next(true);
    this.disabled$$.next(false);
    if (this.confirmDelay > 0) {
      this.disabled$$.next(true);
      this.clearTimer();
      this.delayTimer = setTimeout(() => {
        this.disabled$$.next(false);
      }, this.confirmDelay);
    }
  }

  private clearTimer() {
    if (this.delayTimer) {
      clearTimeout(this.delayTimer);
    }
  }
}
