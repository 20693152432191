<mat-sidenav-container>
  <mat-sidenav
    #sidenav
    mode="over"
    class="w-full lg:w-3/4 {{
      (sidenavType$ | async) === 'carrier' && (newSideNav$ | async) ? 'lg:w-1/3' : ''
    }} transition-[width] duration-200 bg-white dark:bg-zinc-800 max-h-screen"
    [autoFocus]="false"
  >
    <td-side-nav (click)="sidenav.close()"></td-side-nav>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="flex flex-col h-[100dvh] print:h-auto print:break-inside-avoid print:contents">
      @if (isLoggedIn$ | async) {
      <div class="h-6 flex bg-white dark:bg-gray-900 drop-shadow z-10 print:hidden" style="min-height: 48px">
        <div class="w-8 flex items-center justify-center relative">
          <mat-icon class="cursor-pointer" (click)="sidenav.open()"> menu</mat-icon>
        </div>
        <div class="flex-1 flex items-center">
          <a [routerLink]="['/']">
            <img src="assets/logos/Light.svg" class="hidden dark:block h-4 w-full" alt="logo" />
            <img src="assets/logos/5F-Dark.svg" class="dark:hidden h-4 w-full" alt="logo" />
          </a>
        </div>
      </div>
      }
      <div class="flex-1 overflow-y-auto w-full print:block print:overflow-visible" (scroll)="onScroll($event)">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
<td-versioning></td-versioning>
