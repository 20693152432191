import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, take } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { NGXLogger } from 'ngx-logger';
import { environment } from '../../../environments/environment';
import { SimpleFinn } from '../utilities/simpleFinn';
import { SnackBarService } from './snackbar.service';

export type Permission =
  | 'driver'
  | 'admin'
  | 'internal'
  | 'asset-manager'
  | 'safety-manager'
  | 'poc'
  | 'chassis-manager'
  | '5f-tour-dispatcher'
  | 'broker-api'
  | 'tour-load-booker'
  | 'tour-carrier-manager'
  | 'sales'
  | 'tour-dispatcher-manager'
  | 'can-book-from-other-source'
  | 'tour-driver-manager'
  | 'can-view-carrier-kpis'
  | 'can-access-carrier-billing'
  | 'carrier-sourcing'
  | 'remove-holds'
  | 'cancel-completed-loads'
  | 'assign-load-owner'
  | 'otr-netsuite-actions'
  | 'qa-carrier-loads'
  | 'approve-load-charges'
  | 'otr-billing-team'
  | 'can-uncomplete-load'
  | 'cpg-payout-request-approver'
  | 'can-bulk-upload-settlement-items'
  | 'external-dispatcher'
  | 'can-move-hitches'
  | 'update-trailer-type'
  | 'single-day-hitch-edits'
  | 'can-set-edi-override-code';

export interface LoHiUserInfo {
  id: string;
  allowDriverDirections: boolean;
  cdl: string;
  cdlStateAbbreviation: string;
  cdlStateId: string;
  cdlStateName: string;
  companyCanInviteDrivers: boolean;
  driverName: string;
  phoneNumber: string;
  verified: boolean;
  verifiedTimestamp: string;
  companyName: string;
  companyId: string;
  companyOwnerName: string;
  companyOwnerPhone: string;
  permissions: Permission[];
  homebaseLng: number;
  homebaseLat: number;
  allowDriverBooking: boolean;
  allowDriverCreateTruck: boolean;
  allowDriverCreateTrailer: boolean;
  accountRelationship: 'saas' | 'broker' | 'carrier';
  hasDispatcher: boolean;
  hasELDIntegration: boolean;
  showEarningsPrediction: true;
  showOrderPricing: true;
  earningsProgramMember: boolean;
  isMarketplaceEnabled: boolean;
  isYMTEnabled: boolean;
  isLoHiPayMerchant: boolean;
  isLoHiPayConsumer: boolean;
  lohipayDriversAutoApprovePayment: boolean;
  createAssetWithMarketplace: boolean;
  isFiveFTourDispatcher: boolean;
  ffDriverCut: number; // 35% is 0.35
  isCompanyFiveFTourOnly: boolean;
  enableTours: boolean;
  email: string;
  slackMemberId: string;
  isOtrFranchisee: boolean;
  rateconToken: string | null;
  isDrayage: boolean;
  isCompanyCPG: boolean;
  isCompanyTours: boolean;
  isCompanyDomestic: boolean;
  isCompanyImportExport: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // @ts-ignore
  private userInfo$$ = new BehaviorSubject<LoHiUserInfo>(null);
  public userInfo$: Observable<LoHiUserInfo> = this.userInfo$$.pipe(shareReplay(1));
  private httpClientNoInterceptors: HttpClient;
  // @ts-ignore
  private isLoggedIn$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public carrierToken$ = this.userInfo$.pipe(
    map((v) => {
      return v?.rateconToken;
    }),
    shareReplay(1),
  );

  public isLoggedIn$: Observable<boolean> = this.isLoggedIn$$.pipe(
    filter((isLoggedIn) => isLoggedIn !== null),
    distinctUntilChanged(),
    shareReplay(1),
  );

  private customToken: SimpleFinn<string>;

  public get customToken$() {
    return this.customToken.get$();
  }

  constructor(
    httpBackend: HttpBackend,
    private logger: NGXLogger,
    private afAuth: AngularFireAuth,
    private httpClient: HttpClient,
    private snackbarService: SnackBarService,
  ) {
    this.httpClientNoInterceptors = new HttpClient(httpBackend);

    this.customToken = new SimpleFinn<string>(null, this.loadCustomToken);

    this.afAuth.authState.subscribe(async (user) => {
      if (user) {
        await this.loadUserInfo();
        const userInfo = await lastValueFrom(this.userInfo$.pipe(take(1)));
        if (!userInfo) {
          this.isLoggedIn$$.next(false);
          this.logout();
          return;
        }
        this.isLoggedIn$$.next(true);
      } else {
        this.isLoggedIn$$.next(false);
      }
    });
  }

  private loadCustomToken = (): Promise<string> => {
    try {
      return lastValueFrom(
        this.httpClient
          .get<{ customToken: string }>(`${environment.api}/v1/auth_token`)
          .pipe(map((v) => v?.customToken)),
      );
    } catch (e) {
      return null;
    }
  };

  public async loginWithPhone(phoneNumber: string, password: string): Promise<boolean> {
    try {
      const result = await lastValueFrom(
        this.httpClientNoInterceptors.post<{ customToken: string }>(
          `${environment.api}/v1/login/password`,
          { phoneNumber, password },
          {
            headers: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'Content-Type': 'application/json',
              'web-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
              'web-timezone-offset-minutes': `${new Date().getTimezoneOffset()}`,
            },
          },
        ),
      );
      await this.afAuth.signInWithCustomToken(result.customToken);
      return true;
    } catch (error) {
      this.logger.error(error);
      return false;
    }
  }

  public async logout(redirect = true) {
    await this.afAuth.signOut();
    if (redirect) {
      window.location.pathname = '/';
    }
  }

  public async loadUserInfo() {
    const token = await lastValueFrom(
      this.afAuth.idToken.pipe(
        filter((t) => !!t),
        take(1),
      ),
    );
    const userInfo = await lastValueFrom(
      this.httpClientNoInterceptors.get<LoHiUserInfo>(`${environment.api}/v1/user_info`, {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${token}`,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Content-Type': 'application/json',
          'web-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
          'web-timezone-offset-minutes': `${new Date().getTimezoneOffset()}`,
          'x-api-web-app': 'dispatcher',
        },
      }),
    );
    if (!userInfo) {
      await this.afAuth.signOut();
      return;
    }
    if (!hasDispatcherPermissions(userInfo) && !hasExternalCarrierPermissions(userInfo)) {
      this.snackbarService.showError('You do not have permission to access this portal');
      await this.afAuth.signOut();
      return;
    }
    this.userInfo$$.next(userInfo);
  }

  public async doPasswordReset(resetCode: string, newPassword: string): Promise<string> {
    try {
      const result = await lastValueFrom(
        this.httpClientNoInterceptors.post<{ message: string }>(
          `${environment.api}/v1/do_password_reset`,
          {
            resetCode,
            newPassword,
          },
          {
            headers: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'Content-Type': 'application/json',
            },
          },
        ),
      );
      return result.message;
    } catch (error) {
      this.logger.error(error);
      return null;
    }
  }

  public async getPasswordResetCode(phoneNumber: string): Promise<string> {
    try {
      const result = await lastValueFrom(
        await this.httpClientNoInterceptors.post<{ message: string }>(
          `${environment.api}/v1/password_reset_code`,
          {
            phoneNumber,
          },
          {
            headers: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'Content-Type': 'application/json',
            },
          },
        ),
      );
      return result.message;
    } catch (error) {
      this.logger.error(error);
      return null;
    }
  }

  public async loginWithCustomToken(token: string): Promise<boolean> {
    try {
      await this.afAuth.signInWithCustomToken(token);
      return true;
    } catch (error) {
      this.logger.error(error);
      return false;
    }
  }

  public async loginWithLoHiToken(token: string): Promise<boolean> {
    try {
      const result = await lastValueFrom(
        this.httpClientNoInterceptors.get<{ customToken: string }>(`${environment.api}/v1/login/lohi_token`, {
          headers: {
            'Content-Type': 'application/json',
            'web-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            'web-timezone-offset-minutes': `${new Date().getTimezoneOffset()}`,
          },
          params: {
            token,
          },
        }),
      );
      await this.afAuth.signInWithCustomToken(result.customToken);
      return true;
    } catch (error) {
      this.logger.error(error);
      return false;
    }
  }
}

export const isDriver = (userInfo: LoHiUserInfo): boolean => {
  return !!userInfo?.permissions?.includes('driver');
};

export const internalPermissions = ['internal'];
export const externalDispatcherPermissions = ['external-dispatcher'];

export const hasDispatcherPermissions = (userInfo: LoHiUserInfo): boolean => {
  for (const p of userInfo?.permissions || []) {
    for (const ap of internalPermissions) {
      for (const ep of externalDispatcherPermissions) {
        if (p === ap || p === ep) {
          return true;
        }
      }
    }
  }
  return false;
};

export const hasInternalPermission = (userInfo: LoHiUserInfo): boolean => {
  return (userInfo?.permissions || []).includes('internal');
};

export const externalCarrierPermissions = [
  'admin',
  'can-view-carrier-kpis',
  'can-access-carrier-billing',
  'tour-dispatcher-manager',
  'tour-driver-manager',
  'tour-load-booker',
];

export const hasExternalCarrierPermissions = (userInfo: LoHiUserInfo): boolean => {
  for (const p of userInfo?.permissions || []) {
    for (const ap of externalCarrierPermissions) {
      if (p === ap) {
        return true;
      }
    }
  }
  return false;
};
