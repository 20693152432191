<ng-container *ngIf="loadStarted$ | async; else notStarted">
  <div class="flex h-screen overflow-hidden" *ngIf="pickupTimezone$ | async as timezone">
    <div style="width: 600px" class="flex h-screen" id="mouseMove" (mousemove)="highlightWaypoint($event)">
      <div style="width: 200px" class="h-screen relative">
        <div
          class="h-2 absolute pointer-events-none flex w-1/12 text-sm bg-red-500 right-0"
          *ngFor="
            let staleWaypoint of staleWaypointRegions$ | async;
            trackBy: staleWaypointRegions$ | trackByKey: 'startPercent'
          "
          [style.top.%]="staleWaypoint.startPercent"
          [style.height.%]="staleWaypoint.heightPercent"
        ></div>
        <div
          class="absolute"
          *ngFor="let eventGroup of eventsGrouped$ | async; trackBy: eventsGrouped$ | trackByKey: 'locationPercent'"
          [style.top.%]="eventGroup.locationPercent"
          [style.right.px]="eventGroup.rightPx"
        >
          <ng-template #tooltipContent>
            <div
              *ngFor="let shiftEvent of eventGroup.shiftEvents; trackBy: eventGroup.shiftEvents | trackByKey: 'time'"
              class="flex items-center even:bg-gray-100 dark:even:bg-gray-900"
            >
              <div class="w-4 flex items-center justify-center">
                <mat-icon class="cursor-default w-4 {{ shiftEvent.iconClass }}" style="transform: scale(0.5)"
                  >{{ shiftEvent.iconName }}
                </mat-icon>
              </div>
              <div class="w-8 text-left">{{ shiftEvent.time | inTimezone: timezone:'h:mma' }}</div>
              <div class="flex-1 text-left break-words">{{ shiftEvent.message }}</div>
            </div>
          </ng-template>
          <div
            [tooltip]="tooltipContent"
            [options]="{ contentType: 'template' }"
            placement="right"
            class="cursor-default grid grid-cols-2 grid-rows-2"
          >
            <div
              class="flex items-center justify-center w-4"
              *ngFor="let icon of eventGroup.icons; trackBy: eventGroup.icons | trackByKey: 'name'"
            >
              <mat-icon class="{{ icon.className }}" style="transform: scale(0.7)">{{ icon.name }}</mat-icon>
            </div>
          </div>
        </div>
        <div
          class="h-2 absolute pointer-events-none flex w-full text-sm"
          *ngFor="let hourMarker of hourMarkers$ | async; trackBy: hourMarkers$ | trackByKey: 'locationPercent'"
          style="line-height: 16px"
          style.top="calc({{ hourMarker.locationPercent }}% - 8px)"
        >
          <div class="text-center text-gray-500" style="width: 175px">
            {{ hourMarker.time | inTimezone: timezone }}<br />
          </div>
          <div class="flex-1 flex flex-col">
            <div class="h-1 flex-1 border-b border-gray-300"></div>
            <div class="h-1"></div>
          </div>
        </div>
        <div
          class="h-8 absolute pointer-events-none flex w-full text-sm"
          *ngIf="activeWaypoint$ | async as activeWaypoint"
          style="line-height: 16px"
          style.top="calc({{ activeWaypoint.locationPercent }}% - 32px)"
        >
          <div class="text-center flex flex-col" style="width: 176px">
            <div class="h-2 bg-yellow-500" [class.bg-gray-500]="activeWaypoint.isFuture">
              Last Waypoint {{ activeWaypoint.waypoint.clientCreatedAt | inTimezone: timezone:'h:mma' }}
              <span *ngIf="!activeWaypoint.isFuture && activeWaypoint.staleWaypointRegion">🚨</span>
            </div>
            <div class="h-4 border-r border-yellow-500">
              <div class="w-full h-full bg-red-500" *ngIf="activeWaypoint.staleWaypointRegion">
                <div>App not used from</div>
                <div>
                  {{ activeWaypoint.staleWaypointRegion.startAt | inTimezone: timezone:'h:mma' }} -
                  {{ activeWaypoint.staleWaypointRegion.endAt | inTimezone: timezone:'h:mma' }}
                </div>
              </div>
            </div>
            <div class="h-2 bg-yellow-500" [class.bg-gray-500]="activeWaypoint.isFuture">
              Actual Time {{ activeWaypoint.actualTime | inTimezone: timezone:'h:mma' }}
            </div>
          </div>
          <div class="flex-1 flex flex-col">
            <div class="h-4 flex-1 border-b-2 border-yellow-500"></div>
            <div class="h-4"></div>
          </div>
        </div>
      </div>
      <div class="relative flex-1">
        <div
          *ngIf="loadBox$ | async as box"
          class="absolute border-2 border-blue-500 pl-1 pr-1 left-0 right-0 font-bold"
          [style.border-bottom-style]="box.endIsFake ? 'dashed' : 'solid'"
          [style.border-top-style]="box.startIsFake ? 'dashed' : 'solid'"
          [style.top.%]="box.startPercent"
          [style.height.%]="box.heightPercent"
        >
          <div class="relative -top-3">{{ box.name }}</div>
          <div class="absolute -bottom-3" *ngIf="!box.endIsFake">
            Completed at {{ box.data.completedAt || box.data.completedAt | inTimezone: timezone:'h:mma' }} by
            {{ box.data.completedByName | dashIfNothing }}
          </div>
        </div>
        <div
          *ngFor="let box of stopBoxes$ | async; trackBy: stopBoxes$ | trackByKey: 'name'"
          class="absolute border-2 flex flex-col pl-1 pr-1 overflow-hidden left-3 right-3 bg-white dark:bg-[#2c2c2c]"
          [ngClass]="box.data.type === 'at_yard' ? 'border-orange-600' : 'border-green-500'"
          [style.border-bottom-style]="box.endIsFake ? 'dashed' : 'solid'"
          [style.border-top-style]="box.startIsFake ? 'dashed' : 'solid'"
          [style.top.%]="box.startPercent"
          [style.height.%]="box.heightPercent"
        >
          <div class="font-bold" *ngIf="box.data.type !== 'at_yard'">{{ box.name }} ({{ box.data.name }})</div>
          <div class="font-bold" *ngIf="box.data.type === 'at_yard'">{{ box.name }}</div>
          <div *ngIf="!box.startIsFake">Arrived at {{ box.data.arrivedAt | inTimezone: timezone:'h:mma' }}</div>
          <div class="absolute bottom-0">
            <div class="text-red-500" *ngIf="box.data.manuallyCompleted">
              🙋Manually Completed
              <ng-container *ngIf="!box.endIsFake"
                >at {{ box.data.exitedAt || box.data.completedAt | inTimezone: timezone:'h:mma' }}
              </ng-container>
            </div>
            <div *ngIf="box.data.manuallyCompleted === false">
              🤖 Automatically Completed
              <ng-container *ngIf="!box.endIsFake"
                >at {{ box.data.exitedAt || box.data.completedAt | inTimezone: timezone:'h:mma' }}
              </ng-container>
            </div>
          </div>
        </div>
        <div
          *ngFor="let box of eventBoxes$ | async; trackBy: eventBoxes$ | trackByKey: 'name'"
          class="absolute border-2 border-yellow-500 flex flex-col pl-1 pr-1 left-3 right-3"
          [style.border-bottom-style]="box.endIsFake ? 'dashed' : 'solid'"
          [style.border-top-style]="box.startIsFake ? 'dashed' : 'solid'"
          [style.top.%]="box.startPercent"
          [style.height.%]="box.heightPercent"
        >
          <div class="font-bold">{{ box.name }}: {{ box.data.date | inTimezone: timezone:'h:mma' }}</div>
        </div>
        <div
          matTooltip="Geofence Pause"
          class="absolute flex w-2 left-[2px]"
          style="
            background-image: linear-gradient(
              45deg,
              #ffb101 25%,
              #000000 25%,
              #000000 50%,
              #ffb101 50%,
              #ffb101 75%,
              #000000 75%,
              #000000 100%
            );
            background-size: 56.57px 56.57px;
          "
          *ngFor="
            let geofencePause of geofencePauseRegions$ | async;
            trackBy: staleWaypointRegions$ | trackByKey: 'startPercent'
          "
          [style.top.%]="geofencePause.startPercent"
          [style.height.%]="geofencePause.heightPercent"
        ></div>
      </div>
    </div>
    <div class="flex flex-1 bg-blue-500 h-full">
      <td-load-timeline-map
        class="h-full w-full"
        [mapData]="loadHistoryMap$ | async"
        [activeWaypoint]="(activeWaypoint$ | async)?.waypoint"
        [showPolygon]="polygonsEnabled$ | async"
      ></td-load-timeline-map>
    </div>
  </div>
</ng-container>
<ng-template #notStarted>
  <div class="text-xl">Load Not Started Yet</div>
</ng-template>
