import { Component, OnInit } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import { Observable, switchMap } from 'rxjs';
import * as LogRocket from 'logrocket';
import { environment } from '../environments/environment';
import { filter, take } from 'rxjs/operators';
import { MessagingService } from './shared/services/messaging.service';
import { SidenavService } from './shared/services/sidenav.service';
import { FeatureFlagService } from './shared/services/feature-flag.service';
import { ScrollDetectionService } from './shared/services/scroll-detection.service';
import { IntercomService } from './shared/services/intercom.service';

@Component({
  selector: 'td-root',
  templateUrl: './app.component.html',
  styleUrls: [],
})
export class AppComponent implements OnInit {
  public title = 'tour-dispatcher-web';
  public isLoggedIn$: Observable<boolean>;
  public sidenavType$: Observable<string>;
  public newSideNav$: Observable<boolean>;

  constructor(
    private authService: AuthService,
    private messagingService: MessagingService,
    private sideNavService: SidenavService,
    private featureFlagService: FeatureFlagService,
    private scrollDetectionService: ScrollDetectionService,
    private intercom: IntercomService,
  ) {
    this.isLoggedIn$ = this.authService.isLoggedIn$;
    this.sidenavType$ = this.sideNavService.sidenavType$;
    this.newSideNav$ = this.featureFlagService.isFlagActive$('sideNavMar2023');
  }

  public ngOnInit() {
    this.enableLogRocket();
  }

  private enableLogRocket() {
    if (window.location.hostname === 'localhost') {
      return;
    }
    const logRocketOptions = {
      release: '0.0.0',
    };
    if (environment.production) {
      LogRocket.init('stkslu/5f-tours-dispatch-prod', logRocketOptions);
    } else {
      LogRocket.init('stkslu/5f-tours-dispatch-stage', logRocketOptions);
    }

    this.authService.isLoggedIn$
      .pipe(
        filter((t) => t),
        switchMap(() => this.authService.userInfo$),
        take(1),
      )
      .subscribe({
        next: (user) => {
          LogRocket.identify(user.id, {
            name: user.driverName,
            phone: user.phoneNumber,
            // Add your own custom user variables here, ie:
            account: user.companyName,
            isOtrFranchisee: user.isOtrFranchisee,
            permissions: user.permissions.join(' '),
            isCompanyCPG: user.isCompanyCPG,
            isCompanyDomestic: user.isCompanyDomestic,
            isCompanyImportExport: user.isCompanyImportExport,
          });
        },
      });
  }

  public onScroll($event) {
    this.scrollDetectionService.updateScrollPosition(
      $event.target.scrollTop,
      $event.srcElement.scrollHeight - $event.srcElement.offsetHeight,
    );
  }
}
