<div class="relative">
  <button
    #dropdownButton
    (click)="toggleDropdown()"
    class="font-bold bg-zinc-100 hover:bg-zinc-200 rounded-sm p-1 border border-transparent text-center text-sm text-zinc-900 transition-all shadow-md hover:shadow-lg"
  >
    <ng-content (onClick)="toggleDropdown()"></ng-content>
  </button>
  <div
    *ngIf="isDropdownOpen"
    class="absolute right-0 mt-2 bg-white border border-gray-200 rounded-sm shadow-lg z-50 flex flex-col"
  >
    <button
      #itemList
      *ngFor="let option of options; trackBy: trackByFn"
      (click)="selectOption(option.value)"
      class="block p-1 text-sm text-zinc-900 hover:bg-zinc-200 whitespace-nowrap"
    >
      {{ option.label }}
    </button>
  </div>
</div>
