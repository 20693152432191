import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesToTimeOfDay',
})
export class MinutesToTimeOfDayPipe implements PipeTransform {
  public transform(value: number, twelveHourClock = false): string {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;
    const hoursString = (twelveHourClock ? hours % 12 || 12 : hours).toString().padStart(2, '0');
    const minutesString = minutes.toString().padStart(2, '0');
    if (twelveHourClock) {
      return `${hoursString}:${minutesString} ${hours < 12 ? 'AM' : 'PM'}`;
    }
    return `${hoursString}:${minutesString}`;
  }
}
