import { inject, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { SnackBarService } from './snackbar.service';

export const authenticationInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const authService = inject(AuthService);
  const afAuth = inject(AngularFireAuth);
  const snackbar = inject(SnackBarService);

  return authService.isLoggedIn$.pipe(
    filter((isLoggedIn) => !!isLoggedIn),
    switchMap(() => afAuth.idToken),
    take(1),
    switchMap((token) => {
      const copy = req.clone({
        setHeaders: {
          /* eslint-disable @typescript-eslint/naming-convention */
          Authorization: `Bearer ${token}`,
          'web-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
          'web-timezone-offset-minutes': `${new Date().getTimezoneOffset()}`,
          'x-api-web-app': 'dispatcher',
          /* eslint-enable @typescript-eslint/naming-convention */
        },
      });
      return next(copy).pipe(
        catchError((err) => {
          if (localStorage.getItem('HIDE_ALL_ERRORS') !== 'true') {
            logError(err, snackbar);
          }
          return throwError(() => err);
        }),
      );
    }),
  );
};

const logError = (error: any, snackbar: SnackBarService) => {
  if (error instanceof HttpErrorResponse) {
    if (error.error?.isClientError) {
      snackbar.showError(error.error.messageToUser);
    } else {
      snackbar.showError('Unexpected Error Occurred Processing Request');
    }
  }
};
