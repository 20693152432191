<div [formGroup]="form" class="w-full h-full relative">
  <nav class="absolute z-50 top-2 left-1 row flex space-x-2">
    <div class="flex">
      <button
        (click)="changeVisibility('facility')"
        [ngClass]="{
                    'font-semibold rounded-sm rounded-r-none py-1 px-2 border border-transparent text-center text-sm text-zinc-900 transition-all shadow-md hover:shadow-lg': true,
                    'bg-zinc-100 hover:bg-zinc-400 ': layers.facility && !activeLayer,
                    'bg-zinc-400 hover:bg-zinc-100': !layers.facility && activeLayer !== 'facility',
                    'bg-orange-500': activeLayer === 'facility',
                }"
      >
        Facility
      </button>
      <button
        (click)="changeVisibility('docks')"
        [ngClass]="{
          'font-semibold rounded-sm rounded-l-none py-1 px-2 border border-transparent text-center text-sm text-zinc-900 transition-all shadow-md hover:shadow-lg': true,
          'bg-zinc-100 hover:bg-zinc-400 ': layers.docks && activeLayer === null,
          'bg-zinc-400 hover:bg-zinc-100': !layers.docks && activeLayer !== 'docks',
          'bg-orange-500': activeLayer === 'docks'
        }"
      >
        Docks
      </button>
    </div>
    <div class="flex space-x-1">
      <td-geofence-dropdown
        [dropdownType]="'create'"
        [options]="createOptions"
        (optionSelected)="triggerPolygon($event)"
      >
        Create
      </td-geofence-dropdown>
      <button
        *ngIf="editing"
        (click)="toggleEdit()"
        class="font-bold bg-zinc-400 hover:bg-zinc-100 rounded-sm p-1 border border-transparent text-center text-sm text-zinc-900 transition-all shadow-md hover:shadow-lg"
      >
        Editing
      </button>
      <td-geofence-dropdown
        *ngIf="!editing"
        [dropdownType]="'edit'"
        [options]="editOptions"
        (optionSelected)="triggerPolygon($event)"
      >
        Edit
      </td-geofence-dropdown>
      <td-geofence-dropdown
        *ngIf="(selectedPolygons | async)?.length > 0 && !hasSelectedFacility"
        [dropdownType]="'delete'"
        [options]="deleteOptions"
        (optionSelected)="remove($event)"
      >
        Delete
      </td-geofence-dropdown>
    </div>
  </nav>
  <input type="hidden" formControlName="polygon" [value]="facilityValue" />
  <input type="hidden" formControlName="dockPolygons" [value]="docksValue" />
  <div #map class="w-full h-full"></div>
</div>
