import { Injectable } from '@angular/core';
import { firstValueFrom, lastValueFrom, Observable, from, of, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, shareReplay, take } from 'rxjs/operators';
import { FACILITY_ID, GROUP_ID } from './facilities-consts';
import {
  AllFacilityListItem,
  AvailableDriver,
  CheckInInstructions,
  DockInfo,
  Facility,
  FacilityBanDriverReason,
  FacilityBannedDriver,
  FacilityImageURL,
  FacilityInterestedParty,
  FacilityListItem,
  FacilityManagerContact,
  FacilityManagerContactUpdate,
  FacilityReceivingContact,
  FacilitySchedulingUser,
  FacilityWhitelistedDriver,
  InternalFacilitySettings,
  XYPoint,
} from '../../../shared/global-types';
import { SimpleFinn } from '../../../shared/utilities/simpleFinn';
import { RouterFinn } from '../../../shared/utilities/routerFinn';
import { RouterStateService } from '../../../shared/services/router-state.service';
import { environment } from '../../../../environments/environment';

export interface Time {
  value: number;
  display: string;
}

export interface FacilityGroup {
  id: string;
  name: string;
  polygon: XYPoint[];
  facilities: Facility[];
}

@Injectable({
  providedIn: 'root',
})
export class FacilitiesService {
  private readonly baseUrl = `${environment.api}/v2/vpf/internal_or_external_dispatcher/facilities`;
  private static loadTimes = async (): Promise<Time[]> => {
    let startTime = 0;
    const interval = 30;
    const times = [];
    const AP = [' AM', ' PM', ' AM']; // AM-PM

    // loop to increment the time and push results in array
    for (let i = 0; startTime <= 24 * 60; i++) {
      const hh = Math.floor(startTime / 60); // getting hours of day in 0-24 format
      const mm = startTime % 60; // getting minutes of the hour in 0-55 format
      times[i] = {
        display:
          ((hh % 12 === 0 ? '12' : hh % 12) + '').slice(-2) + ':' + ('0' + mm).slice(-2) + AP[Math.floor(hh / 12)],
        value: hh * 60 + mm,
      }; // pushing data in array in [00:00 - 12:00 AM/PM format]
      startTime = startTime + interval;
    }
    return times;
  };

  private times = new SimpleFinn<Time[]>([], FacilitiesService.loadTimes);

  public get times$(): Observable<Time[]> {
    return this.times.get$();
  }

  private facilityListItems: SimpleFinn<FacilityListItem[]>;

  public get facilityListItems$(): Observable<FacilityListItem[]> {
    return this.facilityListItems.get$();
  }

  private facilityGroups: SimpleFinn<FacilityGroup[]>;
  public get facilityGroups$(): Observable<FacilityGroup[]> {
    return this.facilityGroups.get$();
  }

  public get facilityListItemsLoading$(): Observable<boolean> {
    return this.facilityListItems.networkActive$;
  }

  private facility: RouterFinn<Facility>;

  public get facility$(): Observable<Facility> {
    return this.facility.get$();
  }

  private facilityDocks: RouterFinn<DockInfo[]>;

  public get facilityDocks$(): Observable<DockInfo[]> {
    return this.facilityDocks.get$();
  }

  private facilitySettings: RouterFinn<InternalFacilitySettings>;

  public get facilitySettings$(): Observable<InternalFacilitySettings> {
    return this.facilitySettings.get$();
  }

  private facilityCheckInInstructions: RouterFinn<CheckInInstructions[]>;

  public get facilityCheckInInstructions$(): Observable<CheckInInstructions[]> {
    return this.facilityCheckInInstructions.get$();
  }

  private facilityBannedDrivers: RouterFinn<FacilityBannedDriver[]>;

  public get facilityBannedDrivers$(): Observable<FacilityBannedDriver[]> {
    return this.facilityBannedDrivers.get$();
  }

  private facilityWhitelistedDrivers: RouterFinn<FacilityWhitelistedDriver[]>;
  public get facilityWhitelistedDrivers$(): Observable<FacilityWhitelistedDriver[]> {
    return this.facilityWhitelistedDrivers.get$();
  }

  private facilityBannedDriverReasons: SimpleFinn<FacilityBanDriverReason[]>;

  public get facilityBannedDriverReasons$(): Observable<FacilityBanDriverReason[]> {
    return this.facilityBannedDriverReasons.get$();
  }

  private allFacilityItems: SimpleFinn<AllFacilityListItem[]>;

  public get allFacilityItems$(): Observable<AllFacilityListItem[]> {
    return this.allFacilityItems.get$();
  }

  public get allFacilityItemsLoading$(): Observable<boolean> {
    return this.allFacilityItems.networkActive$;
  }

  private facilityInterestedParties = new RouterFinn<FacilityInterestedParty[]>(
    [],
    this.routerState.listenForParamChange$(FACILITY_ID),
    (id) => this.loadInterestedParties(id),
    false,
  );

  public get facilityInterestedParties$(): Observable<FacilityInterestedParty[]> {
    return this.facilityInterestedParties.get$();
  }

  private facilityGroupInfo: RouterFinn<FacilityGroup | null>;

  public get facilityGroup$(): Observable<FacilityGroup | null> {
    return this.facilityGroupInfo.get$();
  }

  public facilityInterestedPartiesLoading$ = this.facilityInterestedParties.networkActive$;

  constructor(private http: HttpClient, private routerState: RouterStateService) {
    this.facility = new RouterFinn(null, this.routerState.listenForParamChange$(FACILITY_ID), this.loadFacilityByID);
    this.facilityDocks = new RouterFinn([], this.routerState.listenForParamChange$(FACILITY_ID), this.loadDocks, false);
    this.facilitySettings = new RouterFinn(
      null,
      this.routerState.listenForParamChange$(FACILITY_ID),
      this.loadFacilityInternalSetttings,
      false,
    );
    this.facilityCheckInInstructions = new RouterFinn(
      null,
      this.routerState.listenForParamChange$(FACILITY_ID),
      this.loadFacilityCheckInInstructions,
      false,
    );
    this.facilityBannedDrivers = new RouterFinn(
      [],
      this.routerState.listenForParamChange$(FACILITY_ID),
      this.loadBannedDrivers,
    );
    this.facilityListItems = new SimpleFinn<FacilityListItem[]>([], this.loadAllMyFacilities);
    this.facilityBannedDriverReasons = new SimpleFinn<FacilityBanDriverReason[]>([], this.loadBanReasons);
    this.allFacilityItems = new SimpleFinn<AllFacilityListItem[]>([], this.loadAllFacilities);
    this.facilityGroups = new SimpleFinn<FacilityGroup[]>([], this.loadFacilityGroups);

    this.facilityWhitelistedDrivers = new RouterFinn(
      [],
      this.routerState.listenForParamChange$(FACILITY_ID),
      this.loadWhitelistedDrivers,
    );
    this.facilityListItems = new SimpleFinn<FacilityListItem[]>([], this.loadAllMyFacilities);
    this.facilityBannedDriverReasons = new SimpleFinn<FacilityBanDriverReason[]>([], this.loadBanReasons);
    this.allFacilityItems = new SimpleFinn<AllFacilityListItem[]>([], this.loadAllFacilities);
    this.facilityGroupInfo = new RouterFinn<FacilityGroup | null>(
      null,
      this.routerState.listenForParamChange$(GROUP_ID),
      this.loadFacilityGroup$.bind(this),
    );
  }

  private loadAllMyFacilities = async (): Promise<FacilityListItem[]> => {
    try {
      return await lastValueFrom(this.loadFacilities());
    } catch (e) {
      return [];
    }
  };

  public loadFacilityGroups = async (): Promise<FacilityGroup[]> => {
    return await lastValueFrom(
      this.http
        .get<{ facilityGroups: FacilityGroup[] }>(this.baseUrl + '/groups')
        .pipe(map((v) => v?.facilityGroups ?? [])),
    );
  };

  public loadFacilities = (customerId?: string): Observable<FacilityListItem[]> => {
    return this.http
      .get<{ facilities: FacilityListItem[] }>(this.baseUrl, {
        params: {
          customerId: customerId ?? '',
        },
      })
      .pipe(map((v) => v?.facilities ?? []));
  };

  public loadAllFacilities = async (): Promise<AllFacilityListItem[]> => {
    return await lastValueFrom(
      this.http.get<{ facilities: AllFacilityListItem[] }>(this.baseUrl + '/all').pipe(map((v) => v?.facilities ?? [])),
    );
  };

  public createFacility = async (value: any): Promise<Facility> => {
    try {
      return await lastValueFrom(this.http.post<Facility>(this.baseUrl, value));
    } catch (e) {
      return null;
    } finally {
      this.facilityListItems.get$();
    }
  };

  private loadFacilityByID = (id: string): Promise<Facility> =>
    lastValueFrom(this.http.get<Facility>(`${this.baseUrl}/${id}`));

  private loadDocks = (id: string): Promise<DockInfo[]> =>
    lastValueFrom(
      this.http.get<{ docks: DockInfo[] }>(`${this.baseUrl}/${id}/docks`).pipe(map((value) => value?.docks || [])),
    );

  public async updateFacility(id: string, value: any): Promise<boolean> {
    try {
      await lastValueFrom(this.http.put(`${this.baseUrl}/${id}`, value));
      return true;
    } catch (e) {
      return null;
    } finally {
      this.facilityListItems.get$();
    }
  }

  public async archiveFacility(id: string): Promise<boolean> {
    try {
      await lastValueFrom(this.http.delete(`${this.baseUrl}/${id}`));
      return true;
    } catch (e) {
      return null;
    } finally {
      this.facilityListItems.get$();
    }
  }

  public async addSchedulingContact(
    name: string,
    email: string,
    shouldSendBookingEmails: boolean,
    customerId: string,
  ): Promise<boolean> {
    try {
      const facility = await lastValueFrom(this.facility$.pipe(take(1)));
      if (!facility.id) {
        return false;
      }
      await lastValueFrom(
        this.http.post<{ schedulingUsers: FacilitySchedulingUser[] }>(`${this.baseUrl}/${facility.id}/contact`, {
          name,
          email,
          shouldSendBookingEmails,
          customerId,
        }),
      );
      this.facility.refresh();
      return true;
    } catch (e) {
      return null;
    } finally {
      this.facilityListItems.get$();
    }
  }

  public async removeSchedulingContact(id: string): Promise<boolean> {
    try {
      const facility = await lastValueFrom(this.facility$.pipe(take(1)));
      if (!facility.id) {
        return false;
      }
      await lastValueFrom(
        this.http.delete<{ schedulingUsers: FacilitySchedulingUser[] }>(`${this.baseUrl}/${facility.id}/contact/${id}`),
      );
      this.facility.refresh();
      return true;
    } catch (e) {
      return null;
    } finally {
      this.facilityListItems.get$();
    }
  }

  public async addFacilityReceivingContact(contact: FacilityReceivingContact): Promise<boolean> {
    try {
      const facility = await lastValueFrom(this.facility$.pipe(take(1)));
      if (!facility.id) {
        return false;
      }
      await lastValueFrom(
        this.http.post<{ receivingContacts: FacilityReceivingContact[] }>(
          `${this.baseUrl}/${facility.id}/facility_contact`,
          {
            id: contact.id,
            name: contact.name,
            email: contact.email,
            phone: contact.phone,
            preferredContactMethod: contact.preferredContactMethod,
          },
        ),
      );
      this.facility.refresh();
      return true;
    } catch (e) {
      return null;
    }
  }

  public async updateFacilityReceivingContact(contact: FacilityReceivingContact): Promise<boolean> {
    try {
      const facility = await lastValueFrom(this.facility$.pipe(take(1)));
      if (!facility.id) {
        return false;
      }
      await lastValueFrom(
        this.http.post<{ receivingContacts: FacilityReceivingContact[] }>(
          `${this.baseUrl}/${facility.id}/facility_contact/${contact.id}`,
          {
            id: contact.id,
            name: contact.name,
            email: contact.email,
            phone: contact.phone,
            preferredContactMethod: contact.preferredContactMethod,
          },
        ),
      );
      this.facility.refresh();
      return true;
    } catch (e) {
      return null;
    }
  }

  public async removeFacilityReceivingContact(contactId: number): Promise<boolean> {
    try {
      const facility = await lastValueFrom(this.facility$.pipe(take(1)));
      if (!facility.id) {
        return false;
      }
      await lastValueFrom(
        this.http.delete<{ receivingContacts: FacilityReceivingContact[] }>(
          `${this.baseUrl}/${facility.id}/facility_contact/${contactId}`,
        ),
      );
      this.facility.refresh();
      return true;
    } catch (e) {
      return null;
    }
  }

  public async addFacilityManagerContact(contact: FacilityManagerContactUpdate): Promise<boolean> {
    try {
      const facility = await lastValueFrom(this.facility$.pipe(take(1)));
      if (!facility.id) {
        return false;
      }
      await lastValueFrom(
        this.http.post<{ receivingContacts: FacilityManagerContact[] }>(
          `${this.baseUrl}/${facility.id}/facility_manager_contact`,
          {
            id: contact.id,
            name: contact.name,
            email: contact.email,
            customerId: contact.customerId,
          },
        ),
      );
      this.facility.refresh();
      return true;
    } catch (e) {
      return null;
    }
  }

  public async removeFacilityManagerContact(contactId: string): Promise<boolean> {
    try {
      const facility = await lastValueFrom(this.facility$.pipe(take(1)));
      if (!facility.id) {
        return false;
      }
      await lastValueFrom(
        this.http.delete<{ receivingContacts: FacilityManagerContact[] }>(
          `${this.baseUrl}/${facility.id}/facility_manager_contact/${contactId}`,
        ),
      );
      this.facility.refresh();
      return true;
    } catch (e) {
      return null;
    }
  }

  public async addFacilityImageToCurrentFacility(name: string, file: File): Promise<boolean> {
    try {
      const facility = await lastValueFrom(this.facility$.pipe(take(1)));
      if (!facility.id) {
        return false;
      }
      const formData = new FormData();
      formData.append('file', file);
      formData.append('name', name);
      await lastValueFrom(
        this.http.post<{ images: FacilityImageURL[] }>(`${this.baseUrl}/${facility.id}/upload_image`, formData),
      );
      this.facility.refresh();
      return true;
    } catch (e) {
      return null;
    } finally {
      this.facilityListItems.get$();
    }
  }

  public async updateFacilityImageOnCurrentFacility(fileId: number, name: string): Promise<boolean> {
    try {
      const facility = await lastValueFrom(this.facility$.pipe(take(1)));
      if (!facility.id) {
        return false;
      }
      await lastValueFrom(
        this.http.put<{ images: FacilityImageURL[] }>(
          `${this.baseUrl}/${facility.id}/images/${fileId}/update_image_title`,
          { title: name },
        ),
      );
      this.facility.refresh();
      return true;
    } catch (e) {
      return null;
    } finally {
      this.facilityListItems.get$();
    }
  }

  public async deleteFacilityImageOnCurrentFacility(fileId: number): Promise<boolean> {
    try {
      const facility = await lastValueFrom(this.facility$.pipe(take(1)));
      if (!facility.id) {
        return false;
      }
      await lastValueFrom(
        this.http.delete<{ images: FacilityImageURL[] }>(`${this.baseUrl}/${facility.id}/images/${fileId}`),
      );
      this.facility.refresh();
      return true;
    } catch (e) {
      return null;
    } finally {
      this.facilityListItems.get$();
    }
  }

  public async addFacilityDock(value: DockInfo) {
    try {
      if (!value.facilityID) {
        return false;
      }
      await lastValueFrom(this.http.post(`${this.baseUrl}/${value.facilityID}/docks`, value));
      this.facilityDocks.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async editFacilityDock(value: DockInfo) {
    try {
      if (!value.facilityID || !value.dockID) {
        return false;
      }
      await lastValueFrom(this.http.put(`${this.baseUrl}/${value.facilityID}/docks/${value.dockID}`, value));
      this.facilityDocks.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async archiveFacilityDock(facilityId: string, dockId: number) {
    try {
      if (!facilityId || !dockId) {
        return false;
      }
      await lastValueFrom(this.http.delete(`${this.baseUrl}/${facilityId}/docks/${dockId}`));
      this.facilityDocks.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }

  private loadFacilityInternalSetttings = (id) =>
    lastValueFrom(this.http.get<InternalFacilitySettings>(`${this.baseUrl}/${id}/scheduling_settings`));

  private loadFacilityCheckInInstructions = (id) =>
    lastValueFrom(
      this.http
        .get<{ checkInInstructions: CheckInInstructions[] }>(`${this.baseUrl}/${id}/check_in_instructions`)
        .pipe(map((v) => v?.checkInInstructions ?? [])),
    );

  public async updateFacilitySettings(id: string, value: any) {
    try {
      await lastValueFrom(this.http.put(`${this.baseUrl}/${id}/scheduling_settings`, value));
      this.facilitySettings.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async saveCheckInInstructions(facilityId: string, newInstructions: CheckInInstructions[]) {
    try {
      await lastValueFrom(
        this.http.put(`${this.baseUrl}/${facilityId}/check_in_instructions`, {
          checkInInstructions: newInstructions,
        }),
      );
      this.facilityCheckInInstructions.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }

  private loadBanReasons = async () => {
    return await lastValueFrom(
      this.http
        .get<{
          reasons: FacilityBanDriverReason[];
        }>(`${this.baseUrl}/banned_driver_reasons`)
        .pipe(map((v) => v?.reasons ?? [])),
    );
  };

  private loadBannedDrivers = (id) =>
    lastValueFrom(
      this.http
        .get<{ bannedDrivers: FacilityBannedDriver[] }>(`${this.baseUrl}/${id}/banned_drivers`)
        .pipe(map((v) => v?.bannedDrivers ?? [])),
    );

  private loadWhitelistedDrivers = (id) =>
    lastValueFrom(
      this.http
        .get<{ whitelistedDrivers: FacilityWhitelistedDriver[] }>(`${this.baseUrl}/${id}/whitelisted_drivers`)
        .pipe(map((v) => v?.whitelistedDrivers ?? [])),
    );

  public searchForDrivers$(searchTerm: string) {
    return this.http
      .get<{ drivers: AvailableDriver[] }>(`${this.baseUrl}/driver_search`, {
        params: {
          searchTerm,
        },
      })
      .pipe(map((v) => v?.drivers ?? []));
  }

  public async banDriver(facilityId: string, driverId: string, reasonId: number, comment: string) {
    try {
      await lastValueFrom(
        this.http.put(`${this.baseUrl}/${facilityId}/banned_drivers`, {
          driverId,
          reasonId,
          comment,
        }),
      );
      this.facilityBannedDrivers.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async unbanDriver(facilityId: string, driverId: string) {
    try {
      await lastValueFrom(
        this.http.delete(`${this.baseUrl}/${facilityId}/banned_drivers`, {
          body: {
            driverId,
          },
        }),
      );
      this.facilityBannedDrivers.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async whitelistDriver(facilityId: string, driverId: string, comment: string) {
    try {
      await lastValueFrom(
        this.http.put(`${this.baseUrl}/${facilityId}/whitelisted_drivers`, {
          driverId,
          comment,
        }),
      );
      this.facilityWhitelistedDrivers.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }

  public async unwhitelistDriver(facilityId: string, driverId: string) {
    try {
      await lastValueFrom(
        this.http.delete(`${this.baseUrl}/${facilityId}/whitelisted_drivers`, {
          body: {
            driverId,
          },
        }),
      );
      this.facilityWhitelistedDrivers.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }

  private loadInterestedParties(id: string): Promise<FacilityInterestedParty[]> {
    return lastValueFrom(
      this.http
        .get<{ interestedParties: FacilityInterestedParty[] }>(`${this.baseUrl}/${id}/interested_parties`)
        .pipe(map((v) => v?.interestedParties ?? [])),
    );
  }

  public async upsertFacilityInterestedParties(customerIDs: string[]) {
    try {
      const facilityId = await firstValueFrom(this.routerState.listenForParamChange$(FACILITY_ID));
      await lastValueFrom(
        this.http.post<{ message: string }>(`${this.baseUrl}/${facilityId}/interested_parties`, {
          interestedParties: customerIDs,
        }),
      );
      this.facilityInterestedParties.refresh();
      return true;
    } catch (e) {
      return false;
    }
  }

  public getFacilityGroups = (): Observable<FacilityGroup[]> => {
    return this.http.get<{ groups: FacilityGroup[] }>(`${this.baseUrl}/groups`).pipe(map((v) => v?.groups ?? []));
  };

  public createFacilityGroup = async (faciltiyIDs: string[], name: string): Promise<FacilityGroup | null> => {
    try {
      const response = await lastValueFrom(
        this.http.post<{ message: string; group: FacilityGroup }>(`${this.baseUrl}/groups`, {
          facilities: faciltiyIDs,
          name: name,
        }),
      );
      return response.group;
    } catch (e) {
      return null;
    } finally {
      this.facilityGroups.get$();
    }
  };

  public async updateFacilityGroup(faciltiyIDs: string[], name: string) {
    try {
      const groupID = await firstValueFrom(this.routerState.listenForParamChange$(GROUP_ID));
      await lastValueFrom(
        this.http.put<{ message: string }>(`${this.baseUrl}/groups/${groupID}`, {
          facilities: faciltiyIDs,
          name: name,
        }),
      );
      return true;
    } catch (e) {
      return false;
    }
  }

  private loadFacilityGroup$(groupId: string): Observable<FacilityGroup | null> {
    return this.http
      .get<{ facilityGroup: FacilityGroup }>(`${this.baseUrl}/groups/${groupId}`)
      .pipe(map((response) => response?.facilityGroup ?? null));
  }
}
