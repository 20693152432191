import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SideNavComponent } from '../side-nav.component';

@Component({
  selector: 'td-side-nav-internal',
  templateUrl: './side-nav-internal.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavInternalComponent {
  constructor(private parent: SideNavComponent) {}
  public openLoadSearch() {
    this.parent.openCommandPallet();
  }
}
