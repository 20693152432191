<div class="flex flex-col h-full" *ngIf="sidenavType$ | async as sidenavType">
  <div class="flex flex-row justify-between items-center p-3">
    <img src="assets/icons/5F.svg" alt="5F" class="mr-1 w-4" />
    <span class="flex items-center cursor-pointer text-gray-600"
      >esc&nbsp;<mat-icon class="text-gray-600">close</mat-icon></span
    >
  </div>
  <div class="flex-1">
    <td-side-nav-carrier *ngIf="sidenavType === 'carrier'"></td-side-nav-carrier>
    <td-side-nav-internal *ngIf="sidenavType === 'internal'"></td-side-nav-internal>
  </div>
  <div class="w-full px-3 my-2" *tdInternal="null">
    <td-company-switcher-shared></td-company-switcher-shared>
  </div>
  <div class="p-3">
    <div class="flex items-center justify-center h-4 w-full lg:max-w-[30vw]" *tdInternalOrExternalDispatcher="null">
      <div
        class="w-3 h-3 flex items-center justify-center cursor-pointer"
        (click)="setSidenavType('carrier', $event)"
        matTooltip="Carrier Menu"
      >
        <div class="w-2 h-2 bg-blue-500 rounded-full" [class.bg-gray-600]="sidenavType !== 'carrier'"></div>
      </div>
      <div
        class="w-3 h-3 flex items-center justify-center cursor-pointer"
        (click)="setSidenavType('internal', $event)"
        matTooltip="Internal Menu"
      >
        <div class="w-2 h-2 bg-blue-500 rounded-full" [class.bg-gray-600]="sidenavType !== 'internal'"></div>
      </div>
    </div>
    <div
      class="flex items-end flex-row justify-between border-t border-gray-400 dark:border-gray-900 w-full"
      *ngIf="userInfo$ | async as userInfo"
    >
      <div class="flex flex-col">
        <div class="font-semibold">{{ userInfo.driverName }}</div>
        <div>{{ userInfo.companyName }}</div>
      </div>
      <div>
        <!-- can't use mat button here because of the margins -->
        <div class="text-red-500 cursor-pointer flex items-center" (click)="logout()">
          Sign Out
          <mat-icon>logout</mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>
